import React from 'react';
import './homereviews.css';
import {
  Autoplay,
  FreeMode,
  Keyboard,
  Mousewheel,
  Navigation,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import UseGeneral from '../../hooks/useGeneral';
const HomeReviews = ({ reviews, title }) => {
  const { language } = UseGeneral();
  const studiesswiper = {
    slidesPerView: 2,
    spaceBetween: 10,
    loop: true,
    autoplay: {
      delay: 3000,
    },
    // navigation: {
    //   nextEl: ".swiper-button-next",
    //   prevEl: ".swiper-button-prev",
    // },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    // pagination: {
    //   el: ".swiper-pagination",
    //   clickable: true,
    // },
    breakPoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      620: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 50,
      },
      1200: {
        slidesPerView: 5,
        spaceBetween: 50,
      },
      1500: {
        slidesPerView: 5,
        spaceBetween: 50,
      },
    },
  };
  return (
    <div className="home_reviews">
      <h5>{title}</h5>
      <Swiper
        className="rev_sweip"
        spaceBetween={20}
        slidesPerView={3}
        key={99}
        // navigation={true}
        modules={[FreeMode, Navigation, Mousewheel, Keyboard]}
        // pagination={{ clickable: true }}
        autoplay={{ delay: 5000 }}
        loop={true}
        dir={language != "en" ? "rtl" : "ltr"}
        {...studiesswiper}
      >
        {reviews &&
          reviews?.map((item, index) => (
            <SwiperSlide
              className="rev_slider"
              key={index}
              onClick={() => window?.open(item?.link, "_blanck")}
            >
              <div className="review">
                <img src={require('../../assets/quotation.png')} alt="" />
                <p>"{language == "ar" ? item?.comment : item?.comment_en}"</p>
                <div className="name">
                  <h6>{language == "ar" ? item?.name : item?.name_en}</h6>
                </div>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
};

export default HomeReviews;

import React, { useEffect, useState } from "react";
import { Axios } from "../../Axios";
import { BASE_URL } from "../../Axios/base_url";
import toast from "react-hot-toast";
import UseGeneral from "../../hooks/useGeneral";
const PayProducts = () => {
  const { language } = UseGeneral();
  const [cartTotalPrice, setCartTotalPrice] = useState(0);
  const [pageLoading, setPageLoading] = useState(false);
  const [cartData, setCartsData] = useState([]);
  let localCart=localStorage.getItem('local_els_cart');
  let cartArr=localCart&&JSON.parse(localCart);
  const getUserData = () => {
    setPageLoading(true);
    Axios({
      url: BASE_URL + "cart/all_carts_for_user",
      method: "GET",
    })
      .then((res) => {
        
        if (res.status == "success") {
          if (Array.isArray(res.result.carts)) {
            setCartsData(res.result?.carts);
          }
        }
      })
      .finally(() => {
        setPageLoading(false);
      });
  };
  const getMeData = () => {
    Axios({
      url: BASE_URL + "user/me",
      method: "POST",
    })
      .then((res) => {
        
        if (res.status == "success") {
          setCartTotalPrice(
            res.result.cart_total_price * 1 - res.result.money_minus * 1
          );
        }
      })
      .finally(() => {
        setPageLoading(false);
      })
      .catch((e) => {
        
      });
  };
  useEffect(() => {
    getUserData();
    getMeData();
  }, []);
  return (
    <div className="pay-products-card-container">
      <div className="top">
        <h4>{language == "ar" ? "تفاصيل طلبك" : "Your Order Details"}</h4>
        {language == "ar" ? (
          <p>
            <b>إجمالي المنتجات</b> ({cartArr.length})
          </p>
        ) : (
          <p>
            <b>Products</b> ({cartArr.length})
          </p>
        )}
      </div>
      <div className="products">
        {cartArr.map((item) => (
          <>
            <div className="row">
              <div className="right">
                <div className="img-container">
                  <img
                    src={
                      item.image
                    }
                    alt=""
                  />
                </div>
                <div className="details">
                  <strong>
                    {language == "ar"
                      ? item?.title_ar
                      : item?.title_en}
                  </strong>
                  <div className="price">
                    <span>
                      {item?.price_for == "weight"
                        ? language == "ar"
                          ? "الوزن"
                          : "Weight"
                        : language == "ar"
                        ? "الكميه"
                        : "quantity"}
                      :
                    </span>

                    {item?.weight && item?.weight != null ? (
                      <>
                        <span>{item?.weight} KG</span>
                        <div>
                          <span>
                            {language == "ar" ? (
                              <>
                                <span>الكميه</span>
                                <span>{item?.quantity}</span>
                              </>
                            ) : (
                              <>
                                <span>quantity:</span>
                                <span>{item?.quantity}</span>
                              </>
                            )}
                          </span>
                        </div>
                      </>
                    ) : (
                      <span>{item?.quantity * 1}</span>
                    )}
                    <p>
                      {item?.price}
                      {language == "ar" ? "د.ك" : "KWD"}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="divider"></div>
          </>
        ))}
      </div>
    </div>
  );
};

export default PayProducts;

import React, { useEffect, useState } from "react";
import { Axios } from "../../../Axios";
import { BASE_URL } from "../../../Axios/base_url";
import toast from "react-hot-toast";
import UseGeneral from "../../../hooks/useGeneral";
import { useNavigate } from "react-router-dom";
const CartDrop = ({ setShowCart }) => {
  const { language } = UseGeneral();
  const navigate = useNavigate();
  const [cartTotalPrice, setCartTotalPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [pageLoading, setPageLoading] = useState(false);
  const [cartData, setCartsData] = useState([]);
  const getUserData = () => {
    setPageLoading(true);
    Axios({
      url: BASE_URL + "cart/all_carts_for_user",
      method: "GET",
    })
      .then((res) => {
        if (res.status == "success") {
          if (Array.isArray(res.result.carts)) {
            setCartsData(res.result?.carts);
            setTotalPrice(res.result?.total_price);
          }
        }
      })
      .finally(() => {
        setPageLoading(false);
      });
  };
  const getMeData = () => {
    Axios({
      url: BASE_URL + "user/me",
      method: "POST",
    })
      .then((res) => {
        if (res.message == "Sission Ended Login Again") {
          // toast.error(res.message);
        }
        if (res.status == "success") {
          setCartTotalPrice(
            res.result.cart_total_price * 1 - res.result.money_minus * 1
          );
        }
      })
      .finally(() => {
        setPageLoading(false);
      })
      .catch((e) => {

      });
  };
  let localCart = localStorage.getItem('local_els_cart');
  let cartArr = localCart && JSON.parse(localCart);
  // let totalPrice=
  // 
  let cartDataTotalPrice = 0;
  if (cartArr && cartArr.length) {
    for (let k = 0; k < cartArr.length; k++) {
      cartDataTotalPrice += cartArr[k].price * 1 * cartArr[k].quantity;
      if (
        cartArr[k].product_optionsarr &&
        cartArr[k].product_optionsarr.length > 0
      ) {
        let options = [...cartArr[k].product_optionsarr];
        for (let y = 0; y < options.length; y++) {
          // 
          cartDataTotalPrice += options[y].price * 1 + options[y].quantity * 1;
        }
      }
    }
  }
  useEffect(() => {
    getUserData();
    getMeData();
  }, []);
  const [clientHeight, setClicentHeight] = useState(0);
  return (
    <div
      style={{
        height: '100%',
        // top: clientHeight + 10,
      }}
      className="pay-products-card-container make_res_prods"
    >
      <div className="rowDiv">
        <div className="top">
          <h4>{language == "ar" ? "عربة التسوق" : "Cart"}</h4>
          {language == "ar" ? (
            <p>لديك {cartArr.length} منتجات</p>
          ) : (
            <p>You Have {cartArr.length} Products</p>
          )}
        </div>
        <span
          onClick={() => {
            navigate("/Cart");
            setClicentHeight(
              (prev) => document.querySelector("header")?.clientHeight
            );
            setShowCart(false);
          }}
          style={{ color: "red" }}
        >
          {language == "ar" ? "عرض العربة كاملة" : "View All Cart"}
        </span>
      </div>
      <div className="products">
        {cartArr?.map((item) => (
          <>
            <div className="row">
              <div className="right">
                <div className="img-container">
                  <img src={item?.image} alt="" />
                </div>
                <div className="details">
                  <strong>
                    {language == "ar" ? item?.title_ar : item?.title_en}
                  </strong>
                  <div className="price">
                    <p
                      style={{
                        color: "#9D071A",
                        fontWeight: "700",
                        fontSize: "18px",
                      }}
                    >
                      {(item?.price).toFixed(3)}
                      {language == 'ar' ? 'د.ك' : 'KWD'}
                    </p>
                    <span>
                      {item?.price_for == "weight"
                        ? language == "ar"
                          ? "الوزن"
                          : "Weight"
                        : language == "ar"
                          ? "الكميه"
                          : "quantity"}
                      : {item?.quantity * 1}
                    </span>
                    {item?.weight && item?.weight != null ? (
                      <>
                        <span>{item?.weight} KG</span>
                        <div>
                          <span>
                            {language == 'ar' ? (
                              <>
                                <span>الكميه : <span>{item?.quantity}</span></span>

                              </>
                            ) : (
                              <>
                                <span>quantity: <span>{item?.quantity}</span></span>

                              </>
                            )}
                          </span>
                        </div>
                      </>
                    ) : (
                      null
                      // <span>{item?.quantity * 1}</span>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="divider"></div>
          </>
        ))}
      </div>
      <div className="allTotalPrice">
        <p className="total_price">
          <span>
            {language == "ar" ? "إجمالي المنتجات : " : "Total Price : "}
          </span>
          <span style={{ color: "red" }}>
            {" "}
            {cartDataTotalPrice ? cartDataTotalPrice?.toFixed(3) : 0}{" "}
            {language == "ar" ? "د.ك" : "KWD"}
          </span>
        </p>
      </div>
    </div>
  );
};

export default CartDrop;

import React, { useEffect, useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import CopyRight from "../../components/footer/copyRight";
import UseGeneral from "../../hooks/useGeneral";
import "./style.css";

import { Link } from "react-router-dom";
import { Axios } from "../../Axios";
import { BASE_URL } from "../../Axios/base_url";
import { footerData } from "../../data/footer";

const Footer = () => {
  const navigate = useNavigate();
  const { language } = UseGeneral();
  const [contactOpen, setContactOpen] = useState(false);
  const [branchesOpen, setBranchesOpen] = useState(false);
  const [deliveryPolicyOpen, setDeliveryPoliceOpen] = useState(false);
  const [returnPolicyOpen, setReturnPolicyOpen] = useState(false);
  const [privacyPolicyOpen, setPrivacyPolicyOpen] = useState(false);
  const [termsConditionsOpen, setTermsConditinosOpen] = useState(false);
  const [payImages, setPayImages] = useState([]);
  const [socials, setSocials] = useState([]);
  const [downloads, setDownLoads] = useState([]);
  const [topCategories, setTopCategories] = useState([]);
  const [siteInfo, setSiteInfo] = useState({});

  useEffect(() => {
    getSiteInfo();
  }, []);

  const getSiteInfo = () => {
    try {
      const infos = JSON.parse(localStorage.getItem("siteInfo"));
      setSiteInfo((prev) => infos);
      setSocials((prev) => infos?.socails || []);
      setTopCategories((prev) => infos?.topCategories || []);
      setDownLoads((prev) => infos?.downloads || []);
      setPayImages((prev) => infos?.payimgs || []);
    } catch (e) {

    }
    Axios({
      url: BASE_URL + `site_info/get_all_for_user`,
      method: "GET",
    })
      .then((res) => {
        if (res?.status === "success") {
          const siteInfoData = res?.result;
          localStorage.setItem("siteInfo", JSON.stringify(res?.result));
          setSiteInfo((prev) => siteInfoData);
          setSocials((prev) => siteInfoData?.socails || []);
          setTopCategories((prev) => siteInfoData?.topCategories || []);
          setDownLoads((prev) => siteInfoData?.downloads || []);
          setPayImages((prev) => siteInfoData?.payimgs || []);
        }
      })
      .catch((error) => {
        console.error("Error fetching site info:", error);
      });
  };

  const handleOpenPopUp = (name) => {
    if (name === "contact") {
      setContactOpen(true);
    } else if (name === "branches") {
      setBranchesOpen(true);
    } else if (name === "returnPolicy") {
      setReturnPolicyOpen(true);
    } else if (name === "deliveryPolicy") {
      setDeliveryPoliceOpen(true);
    } else if (name === "termsConditions") {
      setTermsConditinosOpen(true);
    } else if (name === "privacyPolicy") {
      setPrivacyPolicyOpen(true);
    }
  };

  return (
    <footer>
      <div className="footer">
        <div className="topFooter">
          <div className="rowDiv">
            {siteInfo && Object?.keys(siteInfo)?.length > 0 && (
              <div className="foot_logo_details footerComponent">
                <div className="logo">
                  { }
                  <img src={siteInfo?.footer_logo} alt="" />
                </div>
                <p>
                  {language == "ar"
                    ? siteInfo?.footer_site_des_ar
                    : siteInfo?.footer_site_des_en}
                </p>
              </div>
            )}

            {footerData?.map((item, index) => {
              if (index !== 1) {
                return (
                  <div className="footerComponent">
                    <label htmlFor={"index"}>{item?.title[language]}</label>
                    <input id={"index"} name={"index"} type="checkbox" style={{ display: "none" }} />
                    <ul>
                      {item?.items?.map((item) => {
                        return (
                          <li>
                            {item.link !== "#" ? (
                              <Link to={item?.link}>
                                {item?.label[language]}
                              </Link>
                            ) : (
                              <Link onClick={() => handleOpenPopUp(item.name)}>
                                {item?.label[language]}
                              </Link>
                            )}
                          </li>
                        );
                      })}
                    </ul>
                    <div className="footerComponentFooter">
                      {index === 0
                        ? null
                        : index === 1
                          ? downloads?.map((item) => {
                            return <img alt="" src={item.image} />;
                          })
                          : socials?.map((item) => {
                            return (
                              <img
                                style={{ width: "33px" }}
                                alt=""
                                src={item.icon}
                                role="button"
                                onClick={() =>
                                  window.open(item?.account, "_blank")
                                }
                              />
                            );
                          })}
                    </div>
                  </div>
                );
              } else {
                if (
                  siteInfo?.topCategories &&
                  siteInfo?.topCategories.length > 0
                ) {
                  return <></>;
                }
                return null;
              }
            })}
            <div className="footerComponent">
              <label htmlFor="cates" style={{ cursor: "pointer", }}
                onClick={() => navigate("/Categories")}>{language == "ar" ? "أقسامنا" : "Our Categories"}</label>
              <input id={"cates"} name={"cates"} type="checkbox" style={{ display: "none" }} />
              <ul>
                {topCategories?.map((item) => (
                  <li
                    key={item.id}
                    onClick={() => {
                      navigate({
                        pathname: "/Product",
                        search: createSearchParams({
                          q: item.id,
                        }).toString(),
                      });
                    }}
                  >
                    <span>
                      {language === "ar" ? item.title_ar : item.title_en}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
            <div className="footerComponent">
              <label htmlFor="contact">
                {language == "ar" ? "معلومات التواصل" : "Contact Information"}
              </label>
           
              <input id={"contact"} name={"contact"} type="checkbox" style={{ display: "none" }} />
              <ul>
                <li>
                  {language == "ar" ? "البريد الالكتروني:" : "email:"}{" "}
                  <span
                    onClick={() =>
                      window.open("mailto:" + siteInfo?.email, "_blanck")
                    }
                  >
                    {" "}
                    {siteInfo?.email}
                  </span>
                </li>
                <li>
                  {language == "ar" ? "المعرض مباشرة :" : "Store Directly:"}{" "}
                  <span
                    onClick={() =>
                      window.open("tel:" + siteInfo?.mobile, "_blanck")
                    }
                  >
                    {" "}
                    {siteInfo?.mobile}
                  </span>
                </li>
                <li>
                  {language == "ar"
                    ? "الأونلاين و الواتساب :"
                    : "Online And What's App:"}{" "}
                  <span
                    onClick={() =>
                      window.open("https://wa.me/" + siteInfo?.whats_number, "_blanck")
                    }
                  >
                    {siteInfo?.whats_number}
                  </span>
                </li>
              </ul>
              <div className="footerComponentFooter">
                {socials?.map((item) => {
                  return (
                    <img
                      style={{ width: "33px" }}
                      alt=""
                      src={item.icon}
                      role="button"
                      onClick={() => window.open(item?.account, "_blank")}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <CopyRight
          copyRight={
            language == "ar" ? siteInfo?.copy_right_ar : siteInfo?.copy_right_en
          }
        />
      </div>
    </footer>
  );
};

export default Footer;

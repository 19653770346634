import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from 'react-router-dom';

const PopularProduct = ({ item, language }) => {
  const [image, setImage] = useState(item?.images[0] && item?.images[0].url);
  const navigate = useNavigate();
  const handleMouseOver = () => {
    if (item?.images[1]) {
      setImage(item.images[1].url);
    }
  };

  const handleMouseLeave = () => {
    setImage(item?.images[0] && item?.images[0].url);
  };

  return (
    <div className="populerSections" style={{ cursor: "pointer" }} onClick={() => navigate("/ProductDetails?id=" + item?.id)}>
      <span className='lazy_popular_image' onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
        <LazyLoadImage alt={image} effect="blur" src={image} />
      </span>
      <h2
        style={{
          color: "#9D071A",
          fontWeight: "bold",
          translate: "0 70px",
        }}
      >
        {language == "ar" ? item?.title_ar : item.title_en}
      </h2>
    </div>
  );
};

export default PopularProduct;

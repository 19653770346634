import React, { useEffect } from 'react';
import CategoryLatout from '../../layouts/categories';
import "./style.css";
import UseGeneral from '../../hooks/useGeneral';
const Categories = () => {
  const {language} = UseGeneral()
  useEffect(() => {
    document.title =
      language == "ar" ? "فن النحت | الفئات  " : "Sculpture ART | Categories";
  }, []);
  return (
    <div className="categoriesLPage">
      <CategoryLatout />
    </div>
  );
};

export default Categories;

import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import ReactImageMagnify from "react-image-magnify";
import { Axios } from "../../Axios";
import { BASE_URL } from "../../Axios/base_url";
import UseCartFavourite from "../../hooks/useCartFavourite";

const ProductDetailsImage = ({
  images,
  item,
  id,
  color,
  getItmData,
  image,
  title,
  price,
}) => {
  const {
    isItemInCart,
    addItemToCart,
    isItemInFavourite,
    removeFromFavourite,
    cart,
    addToFavourite,
    removeItemFromCart,
  } = UseCartFavourite();
  const [sliderImages] = useState(
    images?.map((item, index) => ({ id: index, src: item }))
  );
  const localData = localStorage.getItem('green_trees');
  const userId = localData && JSON.parse(localData);
  const [changeFavLoading, setChangeFavLoading] = useState(false);
  const [src, setSrc] = useState(images[0]);
  const changeFavStatus = (id) => {
    setChangeFavLoading(true);
    const data_send = {
      user_favorit_id: userId,
      product_favorit_id: id,
    };

    Axios({
      url: BASE_URL + `favorits/change_product_in_fav`,
      method: "post",
      data: data_send,
    })
      .then((res) => {
        if (res.status == "success") {
          toast.success(res.message);
          getItmData();
        }
      })
      .finally(() => {
        setChangeFavLoading(false);
      });
  };
  // useEffect(() => {
  //   if (sliderImages && sliderImages.length && Array.isArray(sliderImages)) {
  //     setSrc(sliderImages[Math.floor(sliderImages?.length / 2)].src);
  //   }
  // }, [sliderImages]);
  return (
    <div className="productDetailsSlider">
      {/* <div className="productBtn favouriteByn">
        {

            changeFavLoading?
            (
              <Spinner/>
            )
            :
            (
              item.fav==0 ? (
                <span onClick={() => changeFavStatus(item.id)}>
                  {" "}
                  <img
                    style={{  width:'500px',height:'60px'}}
                    src="https://res.cloudinary.com/duovxefh6/image/upload/v1707728121/Component_7_ihxuhv.png"
                    alt=""
                  />
                </span>
              ) : (
                <span
                  onClick={() => changeFavStatus(item?.id )}
                >
                  <img
                    width={20}
                    height={20}
                    className="remove-favourite"
                    src="https://res.cloudinary.com/duovxefh6/image/upload/v1707737155/like_vje2go.png"
                    alt=""
                  />
                </span>
              )
            )

        }
      </div> */}

      <div className="main_image">
        {/* 
<Magnifier src={ src && src.url.includes('.')
        ? src.url
        : require("../../assets/images/no_image.png")} width={700} zoomFactor={2.5} />; */}
        <div className="xxx">
          {src && src.url.includes('.') ? (
            <ReactImageMagnify
              {...{
                pressMoveThreshold: 1,
                enlargedImagePosition: "beside",
                smallImage: {
                  alt: "Wristwatch by Ted Baker London",
                  isFluidWidth: true,
                  src: src && src.url.includes('.') ? src.url : null,
                },
                largeImage: {
                  src: src && src.url.includes('.') ? src.url : null,
                  width: 640,
                  height: 480,
                },
                enlargedImagePortalId: "myPortal",
              }}
            />
          ) : null}
        </div>
        <div id="myPortal"></div>
      </div>
      <div className="srcChanger">
        {images && images?.length && Array.isArray(images)
          ? images?.map((item) => {
              return (
                <span
                  className={
                    src?.id == item?.id
                      ? "sliderChanger active"
                      : "sliderChanger"
                  }
                  onClick={() => {
                    setSrc(item);
                  }}
                >
                  <img src={item?.url} alt="" width={"100"} height={"100"} />
                </span>
              );
            })
          : null}
      </div>
    </div>
  );
};

export default ProductDetailsImage;

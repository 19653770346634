import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { FaCartArrowDown, FaHeart } from "react-icons/fa";
import { IoMdClose } from "react-icons/io";
import { MdOutlineChangeCircle } from "react-icons/md";
import { ThreeDots } from "react-loader-spinner";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { CiHeart } from "react-icons/ci";
import { Loader } from "rsuite";
import { Axios } from "../../Axios";
import { BASE_URL } from "../../Axios/base_url";
import { addToCart, decreaseBTN, removeFromCart } from "../../assets/svgIcons";
import UseCartFavourite from "../../hooks/useCartFavourite";
import UseGeneral from "../../hooks/useGeneral";
import Options from "../options/options";
import PopUp from "../popup";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchCartData } from "../../store/cartReducer";
import {
  addItem,
  addWithWeight,
  decrement2,
  decrementQuantity,
  incrementQuantity,
  updateQuantity,
} from "../../store/offlineCartReducer/OfflineCartReducer";
import Skeleton from "react-loading-skeleton";
import { AsyncImage } from "loadable-image";
import { LazyLoadImage } from "react-lazy-load-image-component";
const Product = ({
  item,
  id,
  color,
  image,
  handleGetWeights,
  homestyle,
  setShowChangeWeight2,
  setOpenQuanModel2,
  setWeights2,
  setOpenWeight2,
  title,
  setOptions2,
  setOpen2,
  price,
  isDetailed,
  getData,
  discount,
  discountRatio,
  makeset,
  setItem,
}) => {
  const offlineCart = useSelector((state) => state.offlineCart);
  // console.log(offlineCart)
  const localData = localStorage.getItem("green_trees");
  const userId = localData && JSON.parse(localData);
  const {
    getUserData,
    isItemInFavourite,
    addToFavourite,
    removeFromFavourite,
  } = UseCartFavourite();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const { language } = UseGeneral();
  const [quantity, setQuantity] = useState(1);
  const location = useLocation();
  const navigate = useNavigate();
  const [openQuanModel, setOpenQuanModel] = useState("");
  const [weights, setWeights] = useState([]);
  const [showChangeWeight, setShowChangeWeight] = useState(false);
  // const [weights,setWeights]=useState([]);
  const [itemForOpts, setItemForOpts] = useState({});
  const [weight, setWeight] = useState("");
  const [editLoading, setEditLoading] = useState(false);
  const [weightsLoading, setWeightsLoading] = useState(false);
  const [pushPrice, setPushPrice] = useState(price);
  const [openWeight, setOpenWeight] = useState(false);
  const [cartLoading, setCartLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [totalPrice, setTotalPrice] = useState(price);
  const [choosedOption, setChoosedOptions] = useState([]);
  const [searchParams] = useSearchParams();
  const [delLoading, setDelLoading] = useState(false);
  const addToFav = () => {
    setLoading(true);
    Axios({
      url: BASE_URL + `favorits/change_product_in_fav`,
      method: "post",
      data: {
        user_favorit_id: userId,
        product_favorit_id: id,
        // has_option:0
      },
    })
      .then((res) => {
        // console.log(res)
        if (res.status == "success") {
          toast.success(res.message);
          getData();
          // getItmData()
        } else if (res.status == "faild") {
          toast.error(res.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "Something Error");
        }
        // console.log(res)
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const changeWeight = () => {
    setDelLoading(true);
    const data_send = {
      cart_id: item.cart_id,
      id: item.id,
      weight: weight.weight,
      price: weight.price,
    };
    console.log(data_send);
    // return
    Axios({
      url: BASE_URL + "cart/update_weight",
      method: "POST",
      data: data_send,
    })
      .then((res) => {
        console.log(res, "eweew");
        if (res.status == "success") {
          getData();
          dispatch(fetchCartData());
          setShowChangeWeight(false);
        }
      })
      .finally(() => {
        setDelLoading(false);
      });
  };
  const handleCartQuan = (id, new_count, type) => {
    console.log(id);
    setDelLoading(true);
    if (type == "minus") {
      console.log(type);
      dispatch(decrementQuantity({ itemIdToDecrement: item.id }));
      // dispatch(decrement2({id:item.id}));
      // window.location.reload()
      // getData()
    } else {
      dispatch(incrementQuantity({ itemIdToIncrement: item.id, item, language }));
      // getData()
      //here
      // window.location.reload()
    }
    setDelLoading(false);
    return;
    Axios({
      url: BASE_URL + `cart/change_count/${id}?new_count=${new_count}`,
      method: "POST",
      data: {
        type: type,
      },
    })
      .then((res) => {
        if (type == "minus") {
          console.log(type);
          dispatch(decrementQuantity({ itemIdToDecrement: item.id }));
          dispatch(decrement2({ id: item.id }));
          getData();
        } else {
          dispatch(incrementQuantity({ itemIdToIncrement: item.id }));
          getData();
        }
        // console.log(res);
        // if (res.status == "success") {
        //   toast.success(res.message);
        //   dispatch(fetchCartData());
        //   getData();
        // } else if (res.status == "faild") {
        //   toast.error(res.message);
        //   console.log(item.id,"  ",+new_count)
        //   dispatch(updateQuantity({id:item.id,quantity:+new_count}));
        //   getData()
        // } else {
        //   console.log(item.id,"  ",+new_count)
        //   dispatch(updateQuantity({id:item.id,quantity:+new_count}));
        //   getData()
        //   toast.error(language == "ar" ? "حدث خطأ ما" : "Something Error");
        // }
        // console.log(res)
      })
      .finally(() => {
        setLoading(false);
        setDelLoading(false);
      });
  };
  const delFromCart = (id) => {
    setDelLoading(true);
    Axios({
      url: BASE_URL + `cart/del_cart/${id}`,
      method: "get",
    })
      .then((res) => {
        console.log(res);
        if (res.status == "success") {
          toast.success(res.message);
          dispatch(fetchCartData());
          getData();

          // getItmData()
        } else if (res.status == "faild") {
          toast.error(res.message);
        } else {
          toast.error(language == "ar" ? "حدث خطأ ما" : "Something Error");
        }
        // console.log(res)
      })
      .finally(() => {
        setLoading(false);
        setDelLoading(false);
      });
  };
  const handleChangeQuan = (quan, id, type) => {
    let newOptions = [...options];
    let originalOpts = [...options];
    for (let i = 0; i < newOptions.length; i++) {
      if (newOptions[i].id == id) {
        newOptions[i]["quantity"] = quan;
      }
      setOptions(newOptions);
    }
    for (let i = 0; i < originalOpts.length; i++) {
      if (originalOpts[i].id == id) {
        if (type == "plus") {
          setTotalPrice(totalPrice * 1 + originalOpts[i].price * 1);
        } else {
          setTotalPrice(totalPrice * 1 - originalOpts[i].price * 1);
        }
        // if(quan>originalOpts[i].quantity){
        //   setTotalPrice(totalPrice*1-originalOpts[i].price*1);
        // }
        // else {
        //   setTotalPrice(totalPrice*1+originalOpts[i].price*1);
        // }
      }
    }
  };

  const handleInCart = () => {
    // console.log(weight);
    // return
    setCartLoading(true);
    let pushedItem = {
      ...item,
      id: item.id,
      quantity: 1,
      title_ar: item.title_ar,
      title_en: item.title_en,
      image,
      discount: item.discount,
      price: item.price,
      price_for: item.price_for,
    };
    // return
    dispatch(addItem(pushedItem));
    // getData()
    console.log("rere");
    setCartLoading(false);
    return;
    const data_send = {
      product_id: id,
      product_count: quantity,
      has_option: 0,

      // product_options:0,
    };
    Axios({
      url: BASE_URL + `cart/add_to_cart`,
      method: "post",
      data: data_send,
    })
      .then((res) => {
        if (res.status == "success") {
          toast.success(res.message);
          getData();
          dispatch(fetchCartData());
          setOpen(false);
          setOpenQuanModel(false);
          setOpenWeight(false);
          // getItmData()
        } else {
          let pushedItem = {
            id: item.id,
            quantity: 1,
            title_ar: item.title_ar,
            title_en: item.title_en,
            image,
            discount: item.discount,
            price: item.price,
            price_for: item.price_for,
          };
          // return
          dispatch(addItem(pushedItem));
          getData();
        }
      })
      .finally(() => {
        setLoading(false);
        setCartLoading(false);
      });
  };

  const addToCartWithOptions = () => {
    let optionList = [...options];
    setCartLoading(true);
    let product_options = "";
    for (let i = 0; i < optionList.length; i++) {
      if (optionList[i].quantity != 0) {
        if (product_options.length == 0) {
          product_options +=
            optionList[i].id + "*green*" + optionList[i]["quantity"];
        } else {
          product_options +=
            "**green**" +
            optionList[i].id +
            "*green*" +
            optionList[i]["quantity"];
        }
      }
    }
    let weightsArr = [...weights];
    let weightstxt = "";
    var checkEmpty = 0;
    let localCart = localStorage.getItem("local_els_cart");
    let CartData = localCart && JSON.parse(localCart);
    let pushedCart = [...CartData];
    for (let i = 0; i < weightsArr.length; i++) {
      if (weightsArr[i].product_count > 0) {
        if (weightstxt == "") {
          weightstxt +=
            weightsArr[i].weight +
            "*green*" +
            weightsArr[i].price +
            "*green*" +
            weightsArr[i].product_count;
        } else {
          weightstxt +=
            "**green**" +
            weightsArr[i].weight +
            "*green*" +
            weightsArr[i].price +
            "*green*" +
            weightsArr[i].product_count;
        }
      }
      console.log(weightsArr[i].product_count);
      if (weightsArr[i].product_count * 1 > 0) {
        checkEmpty += 1;
        console.log(checkEmpty);
      }
      console.log(checkEmpty);
      if (checkEmpty == 0 && i == weightsArr.length - 1) {
        toast.error(
          language == "ar"
            ? "تأكد من إختيار وزن واحد على الأقل"
            : "Check Select at Least One Weight"
        );
        setCartLoading(false);
        return;
      }
    }
    for (let i = 0; i < weightsArr.length; i++) {
      if (weightsArr[i].product_count * 1 > 0) {
        let obj = {
          ...item,
          id: item.id,
          quantity: weightsArr[i].product_count,
          title_ar: item.title_ar,
          title_en: item.title_en,
          image,
          weight: weightsArr[i].weight,
          discount: item.discount,
          price: weightsArr[i].price,
          price_for: item.price_for,
          product_options: product_options,
          has_option: product_options != "" ? 1 : 0,
          total_price:
            weightsArr[i].price * 1 * weightsArr[i].product_count * 1,
        };
        console.log(obj);
        // return
        // dispatch(addItem(obj))
        pushedCart.push(obj);
      }
    }
    // console.log(pushedCart)
    // setCartLoading
    // return
    dispatch(addWithWeight({ pushedCart: pushedCart }));
    //
    setCartLoading(false);
    toast.success(
      language == "ar" ? "تمت الإضافه بنجاح" : "Success To Add To Cart"
    );
    // getData();
    setOpen(false);
    dispatch(fetchCartData());
    setOpen(false);
    setOpenQuanModel(false);
    setOpenWeight(false);
    setWeights([]);
    // getUserData();
    setShowChangeWeight(false);
    return;
    const data_send = {
      has_option: 0,
      product_id: item.id,
      product_count: quantity,
      weights: weightstxt,
      has_weights: weights.length > 0 ? 1 : 0,
    };

    if (product_options.length > 0) {
      data_send["has_option"] = 1;
      data_send["product_options"] = product_options;
    } else {
      data_send["has_option"] = 0;
    }

    Axios({
      url: BASE_URL + `cart/add_to_cart`,
      method: "post",
      data: data_send,
    })
      .then((res) => {
        if (res.status == "success") {
          toast.success(res.message);
          getData();
          setOpen(false);
          dispatch(fetchCartData());
          setOpen(false);
          setOpenQuanModel(false);
          setOpenWeight(false);
          setWeights([]);
          // getUserData();
          setShowChangeWeight(false);
          // getUserData();
          // getItmData()
        } else {
          toast.error(language == "ar" ? "سجل الدخول أولا" : "Log in firsly");
        }
      })
      .finally(() => {
        setCartLoading(false);
        setLoading(false);
      });
  };
  const handleGetWeightsHere = (id) => {
    console.log(id);
    setWeightsLoading(true);
    setShowChangeWeight(true);
    Axios({
      url: BASE_URL + `cart/get_cart_weights/${id}`,
      method: "get",
    })
      .then((res) => {
        console.log(res);
        if (res.status == "success") {
          // dispatch(fetchCartData());
          let myResult = [...res.result];
          setWeights(
            myResult.map((item) => {
              return { ...item, loading: false };
            })
          );
          console.log(id);
        } else {
          toast.error(language == "ar" ? "سجل الدخول أولا" : "Log in firsly");
        }
      })
      .finally(() => {
        setWeightsLoading(false);
      });
  };
  useEffect(() => {
    setTotalPrice(
      choosedOption && choosedOption?.length
        ? parseFloat(price) +
            choosedOption?.reduce((a, b) => a + parseFloat(b?.totalPrice), 0)
        : parseFloat(price)
    );
  }, [choosedOption]);
  useEffect(() => {
    if (!open) {
      setChoosedOptions([]);
    }
  }, [open]);

  const handleDelWeight = (id) => {
    console.log(id);
    setWeights(
      weights.map((item) => {
        return { ...item, loading: true };
      })
    );
    Axios({
      url: BASE_URL + `cart/del_weight/${id}`,
      method: "GET",
    })
      .then((res) => {
        console.log(res);
        if (res.status == "success") {
          setWeights([]);
          dispatch(fetchCartData());
          handleGetWeightsHere(item.cart_id);
          setShowChangeWeight(false);
        } else {
          toast.error(language == "ar" ? "سجل الدخول أولا" : "Log in firsly");
        }
      })
      .finally(() => {
        setWeights(
          weights.map((item) => {
            return { ...item, loading: true };
          })
        );
      });
  };
  const editWeights = () => {
    setEditLoading(true);
    let weightsArr = [...weights];
    console.log(weights);
    let weightstxt = "";
    for (let i = 0; i < weightsArr.length; i++) {
      if (weightsArr[i].product_count > 0) {
        if (weightstxt == "") {
          weightstxt +=
            weightsArr[i].weight +
            "*green*" +
            (weightsArr[i].product_price || weightsArr[i].price) +
            "*green*" +
            weightsArr[i].product_count +
            "*green*" +
            weightsArr[i].cart_prod_id;
        } else {
          weightstxt +=
            "**green**" +
            weightsArr[i].weight +
            "*green*" +
            (weightsArr[i].product_price || weightsArr[i].price) +
            "*green*" +
            weightsArr[i].product_count +
            "*green*" +
            weightsArr[i].cart_prod_id;
        }
      }
    }
    let localCart = localStorage.getItem("local_els_cart");
    let cartData = localCart && JSON.parse(localCart);

    let FilteredCart = cartData.filter((it) => it.id != item.id);
    console.log(FilteredCart);
    // console.log(cartData)
    // setEditLoading(false)
    // return
    let pushedCart = [...FilteredCart];
    for (let i = 0; i < weightsArr.length; i++) {
      // console.log(weightsArr[i])
      // return
      let newobj = {
        id: item.id,
        quantity: weightsArr[i].quantity,
        title_ar: item.title_ar,
        title_en: item.title_en,
        image,
        weight: weightsArr[i].weight,
        discount: item.discount,
        price: weightsArr[i].price,
        price_for: item.price_for,
        total_price: weightsArr[i].price * 1 * weightsArr[i].quantity * 1,
      };
      if (weightsArr[i].quantity * 1 > 0) {
        // dispatch(addItem(newobj))
        // console.log(newobj);
        pushedCart.push(newobj);
      }
    }
    console.log(pushedCart);
    dispatch(addWithWeight({ pushedCart: pushedCart }));
    // localStorage.setItem('local_els_cart',JSON.stringify(pushedCart))
    // getData()
    toast.success(language == "ar" ? "تم التعديل" : "Success To Edit");
    // window.location.reload()
    setEditLoading(false);
    setShowChangeWeight(false);
    return;
    const data_send = {
      cart_id: item.cart_id,
      weights: weightstxt,
    };

    // return ;
    Axios({
      url: BASE_URL + `cart/edit_weights`,
      method: "POST",
      data: data_send,
    })
      .then((res) => {
        if (res.status == "success") {
          setWeights([]);
          dispatch(fetchCartData());
          setShowChangeWeight(false);
        } else {
          toast.error(language == "ar" ? "سجل الدخول أولا" : "Log in firsly");
        }
      })
      .finally(() => {
        setEditLoading(false);
      });
  };
  return (
    <>
      <div
        className={
          homestyle
            ? "category product columnDiv inc_height"
            : "category product columnDiv"
        }
        style={{ background: "#F6F4F4" }}
      >
        {item.discount_ratio && (
          <div className="discount_Per">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="17"
              height="16"
              viewBox="0 0 17 16"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.951 9.59323C16.4512 9.09301 16.7334 8.41388 16.7334 7.70538V0.890076C16.7334 0.398754 16.3346 0 15.8433 0H9.02804C8.31954 0 7.6404 0.281264 7.13929 0.782376C6.12905 1.79261 3.84242 4.07922 2.03646 5.88518C1.20157 6.72007 0.733398 7.85136 0.733398 9.03249C0.733398 10.2127 1.20157 11.344 2.03646 12.1789C2.84643 12.9889 3.74366 13.8861 4.55452 14.696C5.38852 15.5309 6.52067 16 7.70091 16C8.88115 16 10.0133 15.5309 10.8482 14.696L15.951 9.59323ZM12.3088 3.5603C12.8001 3.5603 13.1989 3.95906 13.1989 4.45038C13.1989 4.9417 12.8001 5.34045 12.3088 5.34045C11.8175 5.34045 11.4188 4.9417 11.4188 4.45038C11.4188 3.95906 11.8175 3.5603 12.3088 3.5603Z"
                fill="rgb(172 122 106)"
              />
            </svg>{" "}
            {language == "ar" ? "وفر" : "Save"} {item.discount_ratio}%{" "}
          </div>
        )}

        {/* <div className="productBtn favouriteByn">
          {loading ? (
            <Spinner />
          ) : item.fav == 0 ? (
            <span
              className="add_to_fav"
              onClick={() => {
                addToFav();
              }}
            >
              {" "}
              <img
                src="https://res.cloudinary.com/duovxefh6/image/upload/v1707728121/Component_7_ihxuhv.png"
                alt=""
              />
            </span>
          ) : (
            <span
              className="remo_from_fav"
              onClick={() => {
                addToFav();
              }}
            >
              <img
                width={20}
                height={20}
                className="remove-favourite"
                src="https://res.cloudinary.com/duovxefh6/image/upload/v1707737155/like_vje2go.png"
                alt=""
              />
            </span>
          )}
        </div> */}
        {item?.avilabel_stock > 0 ? null : <img id="outofstock" src="https://res.cloudinary.com/duovxefh6/image/upload/v1719817779/out-of-stock_LE_auto_x2-removebg-preview_jwexhv.png" alt="" />}
        <div
          className={homestyle ? "make make_mar" : "make aa"}
          style={{ cursor: "pointer" }}
          onClick={() =>
            navigate({
              pathname: "/ProductDetails",
              search: createSearchParams({
                id: id,
              }).toString(),
            })
          }
        >
          {/* <AsyncImage
            src={
              image && image
                ? image
                : require('../../assets/images/no_image.png')
            }
          /> */}
          <LazyLoadImage alt={image} effect="blur" src={image} />
          {/* <AsyncImage
            src={image}
            style={{ width: "240px", height: "auto" }}
            loader={<div style={{ background: '#888' }} />}
            error={<div style={{ background: '#eee' }} />}
          /> */}
          {/* <img
            src={
              image && image
                ? image
                : require('../../assets/images/no_image.png')
            }
            alt=""
          /> */}
        </div>
        <h4
          onClick={() =>
            navigate({
              pathname: "/ProductDetails",
              search: createSearchParams({
                id: id,
              }).toString(),
            })
          }
        >
          {title?.length > 70 ? title?.substring(0, 70) + "..." : title}
        </h4>
        {/* <h4>{title?.length > 10 ? title?.su + "..." : title}</h4> */}
        {item.discount > 0 ? (
          <div className="discount_container">
            <p className="before_discount">
              {item.old_price?.toFixed(3)} {language == "ar" ? "د.ك" : "KWD"}
            </p>
            <p className="after_discount">
              {(item.price * 1)?.toFixed(3)}{" "}
              <p>{language == "ar" ? "د.ك" : "KWD"}</p>
            </p>
          </div>
        ) : (
          <div className="price_container">
            {price?.toFixed(3)} {language == "ar" ? "د.ك" : "KWD"}
          </div>
        )}
        {/* <div>
            {
              item.in_cart==0?(
                <button>{language=='ar'?'إضافه إلى العربة':'Add To Cart'}</button>
              ):(
                <div>

                </div>
              )
            }
          </div> */}
        
        <div className="cart_favs">
          {typeof offlineCart.items.find((it) => it.id == id) == "undefined" ? (
            cartLoading ? (
              <Spinner />
            ) : (
              <div className="cart_fav_change">
                <button
                  onClick={() => {
                    if (cartLoading) {
                      return;
                    }

                    if (makeset) {
                      setItem(item);
                      if (
                        item?.productweights &&
                        item?.productweights?.length > 0
                      ) {
                        let pushedWeights = [];
                        let itemWeights = [...item?.productweights];
                        console.log(item);
                        console.log(itemWeights, "itemWeights");
                        let localCart = localStorage.getItem("local_els_cart");
                        let CartData = localCart && JSON.parse(localCart);
                        for (let i = 0; i < itemWeights.length; i++) {
                          let obj = {
                            ...itemWeights[i],
                          };
                          for (let k = 0; k < CartData.length; k++) {
                            console.log("itemWeights[k]", itemWeights[k]);
                            if (
                              item.id == CartData[k].id &&
                              itemWeights[i].weight == CartData[k].weight
                            ) {
                              console.log("yes");
                              obj["product_count"] = CartData[k].quantity;
                              obj["quantity"] = CartData[k].quantity;
                            }
                            if (
                              item.id == CartData[k].id &&
                              CartData[k].quantity == undefined
                            ) {
                              obj["product_count"] = 0;
                              obj["quantity"] = 0;
                            }
                            // else {
                            // }
                          }
                          pushedWeights.push(obj);
                          console.log(obj);
                        }
                        // setWeights2(pushedWeights)
                        setWeights2(
                          item.productweights.map((item) => {
                            return { ...item, product_count: 0 };
                          })
                        );
                        // console.log(pushedWeights)
                        setOpenWeight2(true);
                      }
                      if (item?.options && item?.options.length > 0) {
                        setOpen2(true);
                        setOptions2(
                          item?.options.map((item) => {
                            return { ...item, quantity: 0 };
                          })
                        );
                      }
                      if (
                        item?.productweights.length == 0 &&
                        item?.options.length == 0
                      ) {
                        if (item?.avilabel_stock > 0) {
                          handleInCart();
                        } else {
                          toast.error(
                            language == "ar"
                              ? "لا يمكن شراء المنتج - لقد نفذ من المخزن مؤقتا"
                              : "Product Temporarily Out Of Stock."
                          );
                        }
                      }
                    } else {
                      if (item?.options && item?.options.length > 0) {
                        setOpen(true);
                        setOptions(
                          item?.options.map((item) => {
                            return { ...item, quantity: 0 };
                          })
                        );
                      }
                      if (
                        item?.productweights &&
                        item?.productweights?.length > 0
                      ) {
                        setWeights(
                          item.productweights.map((item) => {
                            return { ...item, product_count: 0 };
                          })
                        );
                        setOpenWeight(true);
                      }
                     
                        if (item?.avilabel_stock > 0) {
                          handleInCart();
                        } else {
                          toast.error(
                            language == "ar"
                              ? "لا يمكن شراء المنتج - لقد نفذ من المخزن مؤقتا"
                              : "Product Temporarily Out Of Stock."
                          );
                        }
                      
                    }
                    return;
                  }}
                  className="add_to_cart_btn"
                >
                  {language == "ar" ? "إضافه إلى العربة" : "Add To Cart"}
                </button>
              </div>
            )
          ) : (
            <>
              {delLoading ? (
                <Loader />
              ) : item.price_for == "weight" ? (
                <div className="d-flex">
                  <p
                    onClick={() => {
                      navigate("/cart");
                    }}
                    className="btn btn-success"
                  >
                    <span>
                      <FaCartArrowDown />
                    </span>
                  </p>
                  <p
                    onClick={() => {
                      setShowChangeWeight(true);
                      let pushedWeights = [];
                      let itemWeights = [...item?.productweights];
                      let localCart = localStorage.getItem("local_els_cart");
                      let CartData = localCart && JSON.parse(localCart);
                      for (let i = 0; i < itemWeights.length; i++) {
                        let obj = {
                          ...itemWeights[i],
                        };
                        let findInCart = CartData.find(
                          (it) =>
                            it.id == id && it.weight == itemWeights[i].weight
                        );
                        if (findInCart != undefined || findInCart != null) {
                          obj["product_count"] = findInCart.quantity;
                          obj["quantity"] = findInCart.quantity;
                        } else {
                          obj["product_count"] = 0;
                          obj["quantity"] = 0;
                        }

                        // for(let k=0;k<CartData.length;k++){
                        // console.log('itemWeights[k]',itemWeights[k])
                        //   if(item.id==CartData[k].id&&itemWeights[i].weight==CartData[k].weight){
                        //     console.log(CartData[k])
                        //     console.log('yes')
                        //     obj['product_count']=CartData[k].quantity
                        //     obj['quantity']=CartData[k].quantity
                        //   }
                        //   // console.log("CartData[k].quantity",CartData[k].quantity)
                        //   else if(item.id==CartData[k].id&&itemWeights[i].weight!=CartData[k].weight){
                        //     obj['product_count']=0
                        //     obj['quantity']=0
                        //   }
                        //   // else {
                        //   // }
                        // }
                        pushedWeights.push(obj);
                      }
                      if (makeset) {
                        setWeights(pushedWeights);
                        // handleGetWeights(item.cart_id);
                      } else {
                        setWeights(pushedWeights);
                        console.log(
                          pushedWeights,
                          "pushedWeightspushedWeightspushedWeights"
                        );
                        // handleGetWeightsHere(item.cart_id);
                      }
                    }}
                    className="btn btn-primary"
                  >
                    <MdOutlineChangeCircle />
                  </p>
                </div>
              ) : (
                <div className="change_count_dec_inc">
                  <span
                    // onClick={()=>{
                    //   navigate('/Cart')
                    // }}
                    onClick={() => {
                      handleCartQuan(
                        item.cart_prod_id,
                        item.quantity + 1,
                        "plus"
                      );
                    }}
                    style={{ width: "20px", fontSize: "19px" }}
                  >
                    {addToCart}
                  </span>
                  <span style={{ textAlign: "center" }}>
                    {offlineCart.items &&
                      offlineCart.items.length &&
                      offlineCart?.items.find((it) => it.id == id).quantity *
                        1}{" "}
                  </span>
                  <span
                    onClick={() => {
                      if (item.quantity > 1) {
                        handleCartQuan(
                          item.cart_prod_id,
                          item.quantity - 1,
                          "minus"
                        );
                      } else {
                        dispatch(decrement2({ id: item.id }));
                        // getData();
                        return;
                        delFromCart(item.cart_prod_id);
                        // getUserData();
                        // console.log(item);
                      }
                    }}
                    // onClick={()=>{
                    //   navigate('/Cart')
                    // }}
                  >
                    {decreaseBTN}
                  </span>
                </div>
              )}
            </>
          )}
          <>
            {loading ? (
              <Spinner />
            ) : !isItemInFavourite(item?.id) ? (
              <div
                onClick={() => {
                  addToFavourite(item);
                }}
                className="not_added_to_fav fav_div"
              >
                <CiHeart />
              </div>
            ) : (
              <div
                onClick={() => {
                  removeFromFavourite(item?.id);
                }}
                className="added_to_fav fav_div"
              >
                <FaHeart />
              </div>
            )}
          </>
        </div>
      </div>

      <PopUp
        open={open || openWeight || openQuanModel}
        title={language == "ar" ? "الإضافات" : "Options"}
        setOpen={() => {
          setOpen(false);
          setOpenQuanModel(false);
          setOpenWeight(false);
          setShowChangeWeight(false);
        }}
        children={
          <>
            {" "}
            {options && options?.length ? (
              <>
                <h4>{language == "ar" ? "الاضافات" : "Additions"}</h4>

                <Options
                  item={item}
                  handleChangeQuan={handleChangeQuan}
                  options={options}
                  language={language}
                  setChoosedOptions={setChoosedOptions}
                />
              </>
            ) : null}
            {weights && weights?.length ? (
              <div className="weights">
                <h4>{language == "ar" ? "الأوزان" : "Weights"}</h4>
                {weights.map((weightItem, weightIndex) => {
                  return (
                    <div className="price_wei_input_parent">
                      <p>
                        {weightItem.price}
                        {language == "ar" ? "د.ك" : "KWD"}
                      </p>
                      <input
                        onChange={(e) => {
                          // console.log(weightItem)
                          setWeights(
                            weights.map((it) => {
                              return {
                                ...it,
                                product_count:
                                  it.id == weightItem.id
                                    ? e.target.value
                                    : it.product_count,
                                quantity:
                                  it.id == weightItem.id
                                    ? e.target.value
                                    : it.product_count,
                                checked:
                                  it.id == weightItem.id && e.target.value == ""
                                    ? false
                                    : it.checked,
                              };
                            })
                          );
                        }}
                        type="text"
                        placeholder={language == "ar" ? "الكميه" : "Count"}
                        className="count_price_weight_input"
                      />
                      <div
                        onClick={() => {
                          setWeight(weightItem);
                          // console.log(weightItem)
                          // console.log(weightItem)
                          setTotalPrice(weightItem.price);
                          setPushPrice(weightItem.price);
                          setWeights(
                            weights.map((item) => {
                              return {
                                ...item,
                                checked:
                                  item.id == weightItem.id
                                    ? true
                                    : item.checked,
                                // product_count:weightItem.id==item.id&&weightItem.product_count==''?1:weightItem.product_count
                              };
                            })
                          );
                        }}
                      >
                        <div className="parent">
                          <div
                            onClick={() => {
                              setWeight(weightItem);

                              setTotalPrice(weightItem.price);
                              setWeights(
                                weights.map((item) => {
                                  return {
                                    ...item,
                                    checked:
                                      item.id == weightItem.id
                                        ? true
                                        : item.checked,
                                    // product_count:weightItem.id==item.id&&weightItem.product_count==''?1:weightItem.product_count
                                  };
                                })
                              );
                            }}
                            className={
                              weightItem.product_count > 0
                                ? "children active"
                                : "children"
                            }
                          ></div>
                        </div>
                        <p>{weightItem.weight} KG</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}
            {/* {weights && weights.length == 0 && (
              <>
                <h4>{language == "ar" ? "الكمية" : "َQuantity"}</h4>
                <div className="by_quan">
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    name={"count"}
                    value={quantity}
                    onChange={(e) => {
                      setQuantity(e.target.value);
                    }}
                  />
                </div>
              </>
            )} */}
            <div className="modalBtnAddToCart">
              {/* <span className="new-price">
                {language == "ar" ? "السعر الكلي" : "Total Price"} :{" "}
                {language == "ar" ? "د.ك" : "KWD"} {totalPrice}
              </span> */}
              {cartLoading ? (
                <Spinner />
              ) : (
                <div
                  className="btn btn-success"
                  onClick={() => {
                    // console.log('with')
                    // return
                    if (item?.avilabel_stock > 0) {
                      addToCartWithOptions();



                    } else {
                      toast.error(
                        language == "ar"
                          ? "لا يمكن شراء المنتج - لقد نفذ من المخزن مؤقتا"
                          : "Product Temporarily Out Of Stock."
                      );
                    }
                  }}
                >
                  {language == "ar" ? "إضافة إلى العربة" : "Add To Cart"}
                </div>
              )}
            </div>
          </>
        }
      />
      {/* <PopUp
        open={openWeight}
        title={language == "ar" ? "الأوزان المتاحه" : "Weights"}
        setOpen={setOpenWeight}
        children={
          <>
            {" "}
            <div>
              <button
                onClick={() => {
                  if (open) {
                    setOpenWeight(false);
                  } else {
                    handleInCart();
                  }
                }}
                className="conf_btn"
              >
                {language == "ar" ? "تأكيد" : "Confirm"}
              </button>
            </div>
          </>
        }
      /> */}
      {/* <PopUp
        open={openQuanModel}
        title={language == "ar" ? "الكميه" : "Quantity"}
        setOpen={setOpenQuanModel}
        children={
          <>
            <div>
              {cartLoading ? (
                <Spinner />
              ) : (
                <button
                  onClick={() => {
                    if (open) {
                      setOpenQuanModel(false);
                    } else {
                      handleInCart();
                    }
                  }}
                  className="conf_btn"
                >
                  {language == "ar" ? "تأكيد" : "Confirm"}
                </button>
              )}
            </div>
          </>
        }
      /> */}
      <PopUp
        open={showChangeWeight}
        title={language == "ar" ? "الأوزان المتاحه" : "Weights"}
        setOpen={setShowChangeWeight}
        children={
          <>
            {" "}
            <div className="weights"></div>
            {weightsLoading ? (
              <ThreeDots color="red" />
            ) : weights && weights?.length ? (
              <div className="weights">
                <h4>{language == "ar" ? "الأوزان" : "Weights"}</h4>
                {weights.map((weightItem, weightIndex) => {
                  return (
                    <div className="price_wei_input_parent">
                      <p>{weightItem.price}EGP</p>
                      <input
                        value={weightItem.product_count}
                        onChange={(e) => {
                          console.log(weights);
                          setWeights(
                            weights.map((it) => {
                              return {
                                ...it,
                                product_count:
                                  it.id == weightItem.id
                                    ? e.target.value
                                    : it.product_count,
                                checked:
                                  it.id == weightItem.id && e.target.value == ""
                                    ? false
                                    : it.checked,
                              };
                            })
                          );
                        }}
                        className="count_price_weight_input"
                        type="text"
                        placeholder={language == "ar" ? "الكميه" : "Count"}
                      />
                      {weightItem.in_cart == 1 ? (
                        weightItem.loading ? (
                          <Spinner />
                        ) : (
                          <IoMdClose
                            style={{
                              color: "red",
                              fontSize: "32px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleDelWeight(weightItem.cart_prod_id);
                            }}
                          />
                        )
                      ) : null}
                      <div
                      // onClick={() => {
                      //   setWeight(weightItem);
                      //   setTotalPrice(weightItem.price);
                      //   setWeights2(
                      //     weights2.map((item) => {
                      //       return {
                      //         ...item,
                      //         checked:
                      //           item.id == weightItem.id ? true : false,
                      //           product_count:0,
                      //       };
                      //     })
                      //   );
                      // }}
                      >
                        <div className="parent">
                          <div
                            // onClick={() => {
                            //   setWeight(weightItem);
                            //   // console.log(weightItem)
                            //   setTotalPrice(weightItem.price);
                            //   setWeights2(
                            //     weights2.map((item) => {
                            //       return {
                            //         ...item,
                            //         checked:
                            //           item.id == weightItem.id ? true : false,
                            //       };
                            //     })
                            //   );
                            // }}
                            className={
                              weightItem.product_count > 0
                                ? "children active"
                                : "children"
                            }
                          ></div>
                        </div>
                        <p>{weightItem.weight} KG</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}
            <div>
              {editLoading ? (
                <Spinner />
              ) : (
                <button
                  onClick={() => {
                    editWeights();
                  }}
                  className="conf_btn"
                >
                  {language == "ar" ? "تعديل" : "Edit"}
                </button>
              )}
            </div>
          </>
        }
      />
      <PopUp
        open={showChangeWeight}
        title={language == "ar" ? "الأوزان المتاحه" : "Weights"}
        setOpen={setShowChangeWeight}
        children={
          <>
            {" "}
            <div className="weights"></div>
            {weightsLoading ? (
              <Skeleton count={3} />
            ) : weights && weights?.length ? (
              <div className="weights">
                <h4>{language == "ar" ? "الأوزان" : "Weights"}</h4>
                {weights.map((weightItem, weightIndex) => {
                  return (
                    <div className="price_wei_input_parent">
                      <p>
                        {weightItem.price}
                        {language == "ar" ? "د.ك" : "KWD"}
                      </p>
                      <div className="change_count_div">
                        <span
                          onClick={() => {
                            if (weightItem.quantity == 0) {
                              return;
                            }
                            let newWeights = [...weights];
                            console.log(newWeights);
                            setWeights(
                              newWeights.map((itWei) => {
                                return {
                                  ...itWei,
                                  product_count:
                                    itWei.id == weightItem.id
                                      ? itWei.product_count * 1 - 1
                                      : itWei.product_count,
                                  quantity:
                                    itWei.id == weightItem.id
                                      ? itWei.quantity * 1 - 1
                                      : itWei.quantity,
                                };
                                // return {...itWei,product_count:itWei.id==weightItem.id?itWei:}
                              })
                            );
                            // setWeights(weights.map((itemWei)=>{
                            //   return {...itemWei,product_count:itemWei.id==weightItem.id?weightItem.product_count*1-1:weightItem.product_count}
                            //   // return {...itemWei,product_count:itemWei.id==weightItem.id?itemWei.product_count*1-1:itemWei.product_count*1}
                            // }))
                          }}
                        >
                          -
                        </span>
                        <input
                          disabled={true}
                          value={weightItem.quantity}
                          onChange={(e) => {
                            setWeights(
                              weights.map((it) => {
                                return {
                                  ...it,
                                  product_count:
                                    it.id == weightItem.id
                                      ? e.target.value
                                      : it.product_count,
                                  checked:
                                    it.id == weightItem.id &&
                                    e.target.value == ""
                                      ? false
                                      : it.checked,
                                };
                              })
                            );
                          }}
                          className="count_price_weight_input"
                          type="text"
                          placeholder={language == "ar" ? "الكميه" : "Count"}
                        />
                        <span
                          onClick={() => {
                            let newWeights = [...weights];
                            console.log(newWeights);
                            setWeights(
                              newWeights.map((itWei) => {
                                return {
                                  ...itWei,
                                  product_count:
                                    itWei.id == weightItem.id
                                      ? itWei.product_count * 1 + 1
                                      : itWei.product_count,
                                  quantity:
                                    itWei.id == weightItem.id
                                      ? itWei.quantity * 1 + 1
                                      : itWei.quantity,
                                };
                                // return {...itWei,product_count:itWei.id==weightItem.id?itWei:}
                              })
                            );
                            // setWeights(weights.map((itemWei)=>{
                            //   return {...itemWei,product_count:itemWei.id==weightItem.id?weightItem.product_count*1+1:weightItem.product_count}
                            //   // return {...itemWei,product_count:itemWei.id==weightItem.id?itemWei.product_count*1+1:itemWei.product_count*1}
                            // }))
                          }}
                        >
                          +
                        </span>
                      </div>
                      {weightItem.in_cart == 1 ? (
                        weightItem.loading ? (
                          <Spinner />
                        ) : (
                          <IoMdClose
                            style={{
                              color: "red",
                              fontSize: "32px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleDelWeight(weightItem.cart_prod_id);
                            }}
                          />
                        )
                      ) : null}
                      <div
                      // onClick={() => {
                      //   setWeight(weightItem);
                      //   setTotalPrice(weightItem.price);
                      //   setWeights2(
                      //     weights2.map((item) => {
                      //       return {
                      //         ...item,
                      //         checked:
                      //           item.id == weightItem.id ? true : false,
                      //           product_count:0,
                      //       };
                      //     })
                      //   );
                      // }}
                      >
                        <div className="parent">
                          <div
                            // onClick={() => {
                            //   setWeight(weightItem);
                            //   // console.log(weightItem)
                            //   setTotalPrice(weightItem.price);
                            //   setWeights2(
                            //     weights2.map((item) => {
                            //       return {
                            //         ...item,
                            //         checked:
                            //           item.id == weightItem.id ? true : false,
                            //       };
                            //     })
                            //   );
                            // }}
                            className={
                              weightItem.product_count > 0
                                ? "children active"
                                : "children"
                            }
                          ></div>
                        </div>
                        <p>{weightItem.weight} KG</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}
            <div>
              {editLoading ? (
                <Spinner />
              ) : (
                <button
                  onClick={() => {
                    editWeights();
                  }}
                  className="conf_btn"
                >
                  {language == "ar" ? "تعديل" : "Edit"}
                </button>
              )}
            </div>
          </>
        }
      />
    </>
  );
};

export default Product;

export const footerData = [
  {
    title: { ar: "معلومات عنا", en: "About Us" },
    images: [
      "https://res.cloudinary.com/duovxefh6/image/upload/v1707723345/Visa_lssjsn.svg",
      "https://res.cloudinary.com/duovxefh6/image/upload/v1707723344/Group_8_va36vq.svg",
      "https://res.cloudinary.com/duovxefh6/image/upload/v1707723344/Group_9_mqivlq.svg",
      "https://res.cloudinary.com/duovxefh6/image/upload/v1707723344/Color_qxf3us.svg",
    ],
    items: [
      {
        label: { ar: "من نحن", en: "Who Us" },
        link: "/about",
        image: "who_us.jpg",
      },
      {
        label: { ar: "موقعنا", en: "Our Location" },
        link: "/map_page",
        image: "branches.jpg",
        name: "branches",
      },
      {
        label: { ar: "سياسة الخصوصية", en: "Policy" },
        link: "/privacy_policy",
        image: "policy.jpg",
        name: "privacyPolicy",
      },
      {
        label: { ar: "الشروط و الأحكام", en: "Conditions" },
        link: "/conditions",
        image: "conditions.jpg",
        name: "termsConditions",
      },
    ],
  },
  {
    title: { ar: "الدعم", en: "Support" },
    images: [
      "https://res.cloudinary.com/duovxefh6/image/upload/v1707723422/Group_etgguo.svg",
      "https://res.cloudinary.com/duovxefh6/image/upload/v1707723421/Badges_fa5gmy.svg",
    ],
    items: [
      {
        label: { ar: "الأسئلة الشائعة", en: "FAQ" },
        link: "/questions",
        image: "faq.jpg",
      },
      {
        label: { ar: "سياسة التوصيل", en: "Contact Policy" },
        link: "#",
        image: "contact_policy.jpg",
        name: "deliveryPolicy",
      },
      {
        label: { ar: "سياسة الأسترجاع", en: "Returns Policy" },
        link: "/ret_poli",
        image: "returns_policy.jpg",
        name: "returnPolicy",
      },
      {
        label: { ar: "الشحن والتوصيل", en: "Shipping and Delivery" },
        link: "/ship_del",
        image: "returns_policy.jpg",
        name: "returnPolicy",
        type:'shipe'
      },
    ],
  },
  // {
  //   title: { ar: "المزيد", en: "More" },
  //   images: [
  //     "https://res.cloudinary.com/duovxefh6/image/upload/v1707723518/youtube_jiz5np.png",
  //     "https://res.cloudinary.com/duovxefh6/image/upload/v1707723517/twitter_jc2dly.svg",
  //     "https://res.cloudinary.com/duovxefh6/image/upload/v1707723517/tik_tok_vlyo5i.png",
  //     "https://res.cloudinary.com/duovxefh6/image/upload/v1707723515/Facebook_phh45u.svg",
  //     "https://res.cloudinary.com/duovxefh6/image/upload/v1707723515/instagram_tqcqxf.svg",
  //   ],
  //   items: [
  //     {
  //       label: { ar: "الشروط و الأحكام", en: "Conditions" },
  //       link: "#",
  //       image: "conditions.jpg",
  //       name: "termsConditions",
  //     },
  //     {
  //       label: { ar: "سياسة الخصوصية", en: "Policy" },
  //       link: "#",
  //       image: "policy.jpg",
  //       name: "privacyPolicy",
  //     },

  //     // {
  //     //   label: { ar: "النشرة الإخبارية", en: "News" },
  //     //   link: "#",
  //     //   image: "news.jpg",
  //     // },
  //   ],
  // },
];

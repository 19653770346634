import React, { useEffect, useState } from "react";
import IconWithText from "../../iconWithText";
import { location, menu } from "../../../assets/svgIcons";
import LeftMiddleHeader from "./leftHeader";
import { Axios } from "../../../Axios";
import { BASE_URL } from "../../../Axios/base_url";
import UseGeneral from "../../../hooks/useGeneral";
import HeaderIcons from "../headerIcons";

const MiddleHeader = ({ setShowSearchModal, menuOpen, setMenuOpen }) => {
  const { handleChangeit, openMenuList } = UseGeneral();
  const [mainLocation, setMainLocation] = useState({});
  const [locations, setLocations] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAllUserLocations = () => {
    setLoading(true);
    Axios({
      url: BASE_URL + `userlocation/get_user_locations`,
      method: "GET",
    })
      .then((res) => {
        if (res?.status == "success") {
          setLocations(res?.result);
          const theMain = res.result.find((item) => item?.status == "1");
          setMainLocation(theMain);
          setLoading(false);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getAllUserLocations();
  }, []);
  return (
    <div className="rowDiv">
      <div className="xxxxxx rowDiv">
        <span role="button" onClick={() => setMenuOpen(!menuOpen)}> {menu}
        </span>
        {mainLocation?.region ? (
          <IconWithText loctrue={true} icon={location} text={`${mainLocation?.region}`} />
        ) : (
          " "
        )}
        <LeftMiddleHeader setShowSearchModal={setShowSearchModal} menuOpen={menuOpen} setMenuOpen={setMenuOpen}/>
      </div>
    </div>
  );
};

export default MiddleHeader;

import React from "react";
import { Link } from "react-router-dom";
import {
  Autoplay,
  EffectCube,
  FreeMode,
  Keyboard,
  Mousewheel,
  Navigation,
  Pagination,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import UseGeneral from "../../../hooks/useGeneral";
import "./style.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { narrowLeft, narrowRight } from "../../../assets/svgIcons";
import PopularProduct from "./PopularProduct";
const PopulerProducts = ({ sections }) => {
  const { language } = UseGeneral();
  const studiesswiper = {
    slidesPerView: 1,
    spaceBetween: 10,
    loop: true,
    autoplay: {
      delay: 3000,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    breakPoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      620: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 1,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 1,
        spaceBetween: 50,
      },
      1200: {
        slidesPerView: 1,
        spaceBetween: 50,
      },
    },
  };
  return (
    <div className="populerity">
      <div className="rowDiv bannerDiv topBanner PopulerProducts">
        {sections
          ?.filter((item) => item?.section_type == "box2")
          ?.map((item, index) => {
            if (item?.products && item?.products?.length)
              return (
                <>
                  <div
                    className="rowDiv tapTest"
                    style={{ alignItems: "center" }}
                  >
                    <span>
                      {language == "ar" ? item?.name_ar : item?.name_en}
                    </span>
                    <Link
                      to={"/Product?q=" + item?.id}
                      className="rowDiv linkTitle centerTitle"
                      style={{ fontWeight: "bold" }}
                    >
                      {language == "ar" ? "عرض الكل" : "View All"}
                    </Link>
                  </div>
                  <div className="rowDiv HomeProducts HomePopulerProducts ">
                    <Swiper
                      spaceBetween={30}
                      slidesPerView={1}
                      // navigation={true}

                      // modules={[FreeMode, Autoplay,Navigation]}
                      // pagination={{ clickable: true }}
                      // autoplay={{ delay: 5000 }}
                      loop={true}
                      dir={language != "en" ? "rtl" : "ltr"}
                      key={language != "en"}
                      breakPoints={{
                        // when window width is >= 320px
                        320: {
                          slidesPerView: 1,
                          spaceBetween: 10,
                        },
                        // when window width is >= 480px
                        480: {
                          slidesPerView: 1,
                          spaceBetween: 20,
                        },
                        // when window width is >= 640px
                        640: {
                          slidesPerView: 1,
                          spaceBetween: 30,
                        },
                        // when window width is >= 768px
                        768: {
                          slidesPerView: 1,
                          spaceBetween: 40,
                        },
                      }}
                      {...studiesswiper}
                      modules={[FreeMode, Navigation, Mousewheel, Keyboard]}
                    >
                      {item?.products?.map((item, index) => {
                          return (
                            <SwiperSlide key={index}>
                              <PopularProduct item={item} language={language} />
                            </SwiperSlide>
                          );
                      })}
                      <div
                        className={
                          language == 'ar'
                            ? "swiper-button-next right"
                            : "swiper-button-next right en"
                        }
                      >
                        {language != "ar" ? (
                          <img
                            src={require('../../../assets/arrow.png')}
                            alt=""
                          />
                        ) : (
                          <img
                            src={require('../../../assets/arrow.png')}
                            alt=""
                          />
                        )}
                      </div>
                      <div
                        className={
                          language == 'ar'
                            ? "swiper-button-prev left"
                            : "swiper-button-prev left en"
                        }
                      >
                        {language != "ar" ? (
                          <img
                            src={require('../../../assets/Arrow2.png')}
                            alt=""
                          />
                        ) : (
                          <img
                            src={require('../../../assets/Arrow2.png')}
                            alt=""
                          />
                        )}
                        {/* <img src={require('./../../../assets/Ellipse 1.png')} alt="" /> */}
                      </div>
                    </Swiper>
                  </div>
                </>
              );
          })}
      </div>
    </div>
  );
};

export default PopulerProducts;

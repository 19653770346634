import React, { useEffect, useState } from "react";
import "./style.css";

const Filters = ({
  selectedCategory,
  filtersData,
  setFilter,
  filterChoosed,
  language,
  setSelectedCategory,
  setSelectedSubCategory,
  setProductsLoading,
}) => {
  //
  //
  const [subCategories, setSubCategories] = useState(filtersData);
  useEffect(() => {
    setSubCategories((prev) => filtersData);
  }, [filtersData]);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSecondSubCategory, setSelectedSecondSubCategory] =
    useState(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSelect = (filterId, title) => {
    setSelectedSecondSubCategory(title);
    setSelectedSubCategory(filterId);
    setIsOpen(false);
  };
  return (
    <div className="rowDiv filters">
      {subCategories?.map((filter, index) => (
        <span
          className={filter.id == filterChoosed?.id ? "active" : ""}
          key={index}
          onClick={() => {
            handleSelect(
              filter.id,
              language === 'ar'
                ? filter.title_ar
                : filter.title_en == '--'
                ? filter.title_ar
                : filter.title_en
            );
          }}
        >
          {language === 'ar'
            ? filter.title_ar
            : filter.title_en == '--'
            ? filter.title_ar
            : filter.title_en}
        </span>
      ))}
      {/* <Swiper
        style={{ padding: "10px 0px" }}
        spaceBetween={20}
        slidesPerView={3}
        // navigation={true}
        modules={[FreeMode, Navigation, Mousewheel, Keyboard]}
        // pagination={{ clickable: true }}
        autoplay={{ delay: 5000 }}
        loop={true}
        dir={language != "en" ? "rtl" : "ltr"}
        key={language != "en"}
        {...studiesswiper}
      > */}
      {/* <div className="custom-dropdown">
        <div className="dropdown-header" onClick={toggleDropdown}>
          {selectedSecondSubCategory ? (
            <span>{selectedSecondSubCategory}</span>
          ) : (
            <span>Select Subcategory</span>
          )}
        </div>
        <ul className={!isOpen ? "dropdown-list" : "dropdown-list active"}>
          {subCategories?.map((filter, index) => (
            <li
              className={filter.id == filterChoosed?.id ? "active" : ""}
              key={index}
              onClick={() =>
                handleSelect(
                  filter.id,
                  language === 'ar' ? filter.title_ar : filter.title_en
                )
              }
            >
              {language === 'ar' ? filter.title_ar : filter.title_en}
            </li>
          ))}
        </ul>
      </div> */}
      {/* <div
        className={
          language == "ar" ? "swiper-button-next rot" : "swiper-button-next"
        }
      >
        {language != "ar" ? narrowRight : narrowLeft}
      </div>
      <div
        className={
          language == "ar" ? "swiper-button-prev rot" : "swiper-button-prev"
        }
      >
        {language == "ar" ? narrowRight : narrowLeft}
      </div> */}

      {/* {brands?.map((item, index) => (
                <SwiperSlide
                  key={index}
                  onClick={() => window?.open(item?.link, "_blanck")}
                >
                  <img
                    src={
                      item?.url
                    }
                    alt=""
                  />
                </SwiperSlide>
              ))} */}
      {/* </Swiper> */}

      {/* {subCategories && subCategories?.length && Array.isArray(subCategories)
        ? subCategories?.map((filter, index) => {
            return (
              <button
                onClick={() => {
                  setSelectedSubCategory(filter.id);
                }}
                className={
                  filterChoosed.id == filter?.id
                    ? "btn filterBtn filtered"
                    : " filterBtn btn"
                }
              >
                {language == "ar" ? filter?.title_ar : filter?.title_en}
              </button>
            );
          })
        : null} */}
      {/* <Dropdown >
      <Dropdown.Toggle className={
          selectedCategory == 'ALL'
          ? "btn filterBtn filtered"
          : " filterBtn btn"
      } style={{display:'flex',alignItems:'center',gap:'4px',cursor:'pointer',border:'none'}} variant="" id="dropdown-basic">
      {
                language=='ar'?
                <div className="d-flex">
                  <img src={require('../../assets/Vector.png')} alt="" />
                  <span className="category">
                    كل المنتجات
                  </span>
                </div>
                :
                <>
                  <img src={require('../../assets/Vector.png')} alt="" />
                  <span className="category">
                    All Products
                  </span>
                </>
              }
      </Dropdown.Toggle>

      <Dropdown.Menu style={{height:'50vh',overflowY:'auto',width:'400px'}}>
      {subCategories && subCategories?.length
          ? subCategories?.map((item,index) => {
              if(true){
                return (
                    <Dropdown.Item onClick={()=>{
                      setSelectedCategory(item.id);
                    }}  style={{display:'flex',gap:'10px',fontSize:'16px',marginBottom:'15px',alignItems:'center'}} >
                      <img style={{ width:'40px' }} src={item.image} alt="" />
                      <p style={{ margin:'0px',fontSize:'14px' }}>{language?.toLowerCase() == "ar"
                    ? item?.title_ar
                    : item?.title_en}</p>
                    </Dropdown.Item>
                );
              }
              return null
            })
          : null}
      </Dropdown.Menu>
          </Dropdown> */}
    </div>
  );
};

export default Filters;

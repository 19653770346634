import React from "react";
import "./style.css";
import UseGeneral from "../../hooks/useGeneral";

const IconWithText = ({
  icon,
  text,
  reverse,
  pointer,
  onClick,
  className,
  loctrue,
  mobile
}) => {
  // Define classes to apply to the container div
  const containerClasses = pointer ? "pointer" : ""; // Add pointer class if pointer prop is true
  const combinedClasses = `iconWithText ${className} ${containerClasses}`; // Combine classes
  const { language } = UseGeneral();
  return (
    <div className={combinedClasses} onClick={onClick}>
      {!reverse ? (
        <div className="iconWithText">
          <span style={{ color: "white" }}>{icon}</span>
          {!mobile ? <span className="iconWithTextTitle">
            {loctrue && (
              <span>{language == "ar" ? "التوصيل إلى" : "Delivary To"}: </span>
            )}
            {text}
          </span> : null}
        </div>
      ) : (
        <div className="iconWithText">
          <span className="iconWithTextTitle">{text}</span>
          <span>{icon}</span>
        </div>
      )}
    </div>
  );
};

export default IconWithText;

import React, { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { Axios } from "../../Axios";
import { BASE_URL } from "../../Axios/base_url";
import Checkoutcartdetails from "../../components/checkoutcartdetails/checkoutcartdetails";
import ProductInCart from "../../components/product/ProductInCart";
import UseCartFavourite from "../../hooks/useCartFavourite";
import UseGeneral from "../../hooks/useGeneral";
import "./style.css";
import { useSelector } from "react-redux";
const CartLayout = () => {
  const { language } = UseGeneral();
  const { cart, totalPrice, totalQuantity } = UseCartFavourite();
  const [cartsData, setCartsData] = useState([]);
  const [cartTotalPrice, setCartTotalPrice] = useState(0);
  const [pageLoading, setPageLoading] = useState(false);
  const [siteInfoData, setSiteInfoData] = useState(false);
  const offlineCart = useSelector((state) => state.offlineCart);
  const getSideInfoData = () => {
    Axios({
      url: BASE_URL + "site_info/get_all_for_user",
      method: "GET",
    })
      .then((res) => {
        
        if (res.status == "success") {
          
          setSiteInfoData(res?.result);
        }
      })
      .finally(() => {});
  };
  let localCart = localStorage.getItem('local_els_cart');
  let cartArr = localCart && JSON.parse(localCart);
  let cartDataTotalPrice = 0;
  if (cartArr && cartArr.length) {
    for (let k = 0; k < cartArr.length; k++) {
      cartDataTotalPrice += cartArr[k].price * 1 * cartArr[k].quantity;
      if (
        cartArr[k].product_optionsarr &&
        cartArr[k].product_optionsarr.length > 0
      ) {
        let options = [...cartArr[k].product_optionsarr];
        for (let y = 0; y < options.length; y++) {
          // 
          cartDataTotalPrice += options[y].price * 1 + options[y].quantity * 1;
        }
      }
    }
  }

  const getUserData = () => {
    setPageLoading(true);
    Axios({
      url: BASE_URL + "cart/all_carts_for_user",
      method: "GET",
    })
      .then((res) => {
        if (res.message == "Sission Ended Login Again") {
          // toast.error(res.message);
        }
        if (res.status == "success") {
          // let allCartData=[...res.result];
          setCartsData(res.result.carts);
        }
      })
      .finally(() => {
        setPageLoading(false);
      });
  };
  const getMeData = () => {
    Axios({
      url: BASE_URL + "user/me",
      method: "POST",
    })
      .then((res) => {
        if (res.message == "Sission Ended Login Again") {
          // toast.error(res.message);
        }
        if (res.status == "success") {
          setCartTotalPrice(
            res.result.cart_total_price * 1 - res.result.money_minus * 1
          );
        }
      })
      .finally(() => {
        setPageLoading(false);
      })
      .catch((e) => {
        
      });
  };
  useEffect(() => {
    getUserData();
    getMeData();
    getSideInfoData();
  }, []);
  return (
    <div className="rowDiv CartProducts">
      {false ? (
        <Spinner />
      ) : (
        <div className="rowSpaceBetween cart_row_span">
          <div className="columnDiv cart_one">
            {cartArr && cartArr?.length ? (
              cartArr?.map((item) => {
                return (
                  <ProductInCart
                    updateData={getUserData}
                    item={item}
                    id={item?.id}
                    image={item?.image}
                    title={language == "ar" ? item?.title_ar : item?.title_en}
                    price={item?.price}
                    quantity={
                      (offlineCart.items &&
                        offlineCart.items.length > 0 &&
                        offlineCart.items.find((it) => it.id == item.id)
                          ?.quantity * 1) ||
                      0
                    }
                  />
                );
              })
            ) : (
              <>
                <h4>
                  {language == "ar"
                    ? "لا يوجد منتجات في العربة"
                    : "No Products In Cart"}
                </h4>
              </>
            )}
          </div>
          {cartArr && cartArr?.length ? (
            <Checkoutcartdetails
              siteInfoData={siteInfoData}
              getUserData={getUserData}
              cart={cart}
              total_check={cartDataTotalPrice}
              language={language}
              totalPrice={cartDataTotalPrice}
              totalQuantity={cartArr.length || 0}
            />
          ) : null}
        </div>
      )}
    </div>
  );
};

export default CartLayout;

import React from "react";
import UseGeneral from "../../hooks/useGeneral";

const CopyRight = ({ copyRight }) => {
  const { language } = UseGeneral();
  return (
    copyRight &&
    copyRight?.length && (
      <p
        className="bottomFooter"
        dangerouslySetInnerHTML={{ __html: copyRight }}
      ></p>
    )
  );
};

export default CopyRight;

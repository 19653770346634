import React from "react";
import PayCard from "./PayCard";

const Left = ({ handleConfirm, addLoading, userOrderInfo, payType, setAddLoading }) => {
  return (
    <div className="left-container">
      <PayCard
        addLoading={addLoading}
        handleConfirm={handleConfirm}
        userOrderInfo={userOrderInfo}
        payType={payType}
        setAddLoading={setAddLoading}
      />
    </div>
  );
};

export default Left;

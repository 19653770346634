import React, { useState } from "react";
import "./style.css";
import { createSearchParams, useNavigate } from "react-router-dom";
const Category = ({ color, image, title, id }) => {
  const navigate = useNavigate();
  const [hovered, setHovered] = useState(false);
  const handleMouseOver = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };
  return (
    <div
      className="category bg_cat columnDiv make_res_cat categoreyBox"
      style={{ cursor: "pointer" }}
      onClick={() => {
        navigate({
          pathname: "/Product",
          search: createSearchParams({ q: id }).toString(),
        });
        // navigate('/Product',{state:{id}});
      }}
    >
      {image && image?.length ? (
        <div
          className={hovered ? "img hovered" : "img"}
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="196"
            height="187"
            viewBox="0 0 166 137"
            fill="none"
          >
            <g opacity={0} className="gmode">
              <path
                className="border-path"
                d="M165.5 71.55C165.5 109.382 95.8125 137 61.9014 137C27.9904 137 0.5 106.332 0.5 68.5C0.5 30.6685 27.9904 0 61.9014 0C95.8125 0 165.5 33.7185 165.5 71.55Z"
                fill="none"
                stroke="#000"
                strokeWidth="3"
                strokeDasharray="5,5"
              ></path>
            </g>

            <g transform="translate(10, 6)" width="166" height="137">
              <g transform="scale(.9)">
                <path
                  d="M165.5 71.55C165.5 109.382 95.8125 137 61.9014 137C27.9904 137 0.5 106.332 0.5 68.5C0.5 30.6685 27.9904 0 61.9014 0C95.8125 0 165.5 33.7185 165.5 71.55Z"
                  fill="#EF0A27"
                ></path>
              </g>
            </g>
          </svg>

          <img
            src={
              image.includes(".")
                ? image
                : require("../../assets/images/no_image.png")
            }
          />
        </div>
      ) : null}
      <h5>{title}</h5>
    </div>
  );
};

export default Category;

import React from 'react'
import './CancelPayProducts.css'
import UseGeneral from '../../hooks/useGeneral'
const CancelPayProducts = ({products}) => {
  const {language}=UseGeneral()
  
  return (
    <div className='can_pay_prods'>
      <p>{language=='ar'?'المنتجات':'Products'}</p>
      <span>{language=='ar'?'لديك':'You Have'} {products?.length} {language=='ar'?'منتجات':'Products'}</span>
      <div className="can_products">
        {
          products&&products.length>0&&products.map((item,index)=>{
            return (
              <div className="can_prod">
                <div className="right">
                  <img src={item?.product?.images&&item?.product?.images.length&&item?.product?.images[0].url} alt="" />
                  <div className="det">
                    <p>{language=='ar'?item.product.title_ar:item.product.title_en}</p>
                    <p>{language=='ar'?'الكمية ':'Quantitu '} {item.product_count}</p>
                  </div>
                </div>
                <div className="left">
                  <p>{item?.product_price} {language=='ar'?'د.ك':'KWD'}</p>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}

export default CancelPayProducts

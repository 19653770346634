import React, { useEffect, useState } from "react";
import { homeCategories } from "../../data/homeCategories";
import Category from "../../components/category/category";
import UseGeneral from "../../hooks/useGeneral";
import Breadcrumb from "../../components/BreadCumbsLinks";
import { Axios } from "../../Axios";
import { BASE_URL } from "../../Axios/base_url";
import toast from "react-hot-toast";
import { Loader } from "rsuite";

const CategoryLatout = () => {
  const { language } = UseGeneral();
  const [pageLoading, setPageLoading] = useState(false);
  const [favs, setFavs] = useState([]);
  const breadCrumbsLinks = [
    { name: language != "ar" ? "Home" : "الصفحة الرئيسية", path: "/" },
    {
      name: language != "ar" ? "Categories" : "الفئات",
      path: "/Categories",
      active: true,
    },
  ];

  const getUserFavs = () => {
    setPageLoading(true);
    Axios({
      url: BASE_URL + "categories/get_all",
      method: "GET",
    })
      .then((res) => {


        if (res.status == "success") {
          setFavs(res.result);
        }
      })
      .finally(() => {
        setPageLoading(false);
      });
  };
  useEffect(() => {
    getUserFavs();
  }, []);
  return (
    <>
      <div className="rowDiv">
        <Breadcrumb links={breadCrumbsLinks} />
      </div>
      <div className="rowDiv">
        {pageLoading ? (
          <iframe
            width={350}
            height={400}
            style={{ margin: "auto" }}
            src="https://lottie.host/embed/0f5cbd90-b98b-4ef9-a1fe-6853af66f359/mzKPtDndKi.json"
          ></iframe>
        ) : (
          favs?.map((item) => {
            if (item?.
              main_image
            )
              return (
                <Category
                  id={item?.id}
                  image={item?.
                    main_image
                  }
                  title={language == "ar" ? item?.title_ar : item?.title_en}
                  color={item?.color}
                />
              );
          })
        )}
      </div>
    </>
  );
};

export default CategoryLatout;

import React, { useEffect, useState } from 'react';
import "./style.css";
import UseGeneral from '../../hooks/useGeneral';
import UseCartFavourite from '../../hooks/useCartFavourite';
import Product from '../../components/product';
import { Axios } from '../../Axios';
import { BASE_URL } from '../../Axios/base_url';
import toast from 'react-hot-toast';
import { ThreeCircles, ThreeDots } from 'react-loader-spinner';
import { Spinner } from 'react-bootstrap';
import PopUp from '../../components/popup';
import Options from '../../components/options/options';
import { fetchCartData } from '../../store/cartReducer';
import { useDispatch, useSelector } from 'react-redux';
import { IoMdClose } from 'react-icons/io';
import ContentLoader from 'react-content-loader';
import Skeleton from 'react-loading-skeleton';
import { addItem, addWithWeight } from '../../store/offlineCartReducer/OfflineCartReducer';
const Favourites = () => {
  const offlineCart = useSelector((state) => state.offlineCart);
  

  const dispatch = useDispatch();
  const { language } = UseGeneral();
  const { favourite } = UseCartFavourite();
  const [pageLoading, setPageLoading] = useState(false);
  const [weightsLoading, setWeightsLoading] = useState(false);
  const [openWeight2, setOpenWeight2] = useState(false);
  const [totalPrice, setTotalPrice] = useState("");
  const [openQuanModel2, setOpenQuanModel2] = useState(false);
  const [options, setOptions] = useState([]);
  const [cartLoading, setCartLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [editLoading, setEditLoading] = useState(false);
  const [changeWeights2, setShowChangeWeight2] = useState(false);
  const [item, setItem] = useState({});
  const [choosedOption, setChoosedOptions] = useState([]);
  const [weights2, setWeights2] = useState([]);
  const [cartId, setCartId] = useState("");
  const [favs, setFavs] = useState([]);
  const [quantity, setQuantity] = useState(1);
  const handleGetWeights = (id) => {
    
    setWeightsLoading(true);
    setShowChangeWeight2(true);
    Axios({
      url: BASE_URL + `cart/get_cart_weights/${id}`,
      method: "get",
    })
      .then((res) => {
        
        if (res.status == "success") {
          let myResult = [...res.result];
          setWeights2(
            myResult.map((item) => {
              return { ...item, loading: false };
            })
          );
          setCartId(id);
          
        } else {
          toast.error(language == "ar" ? "سجل الدخول أولا" : "Log in firsly");
        }
      })
      .finally(() => {
        setWeightsLoading(false);
      });
  };
  const getUserFavs = () => {
    setPageLoading(true);
    Axios({
      url: BASE_URL + "favorits/get_all",
      method: "GET",
    })
      .then((res) => {
        // 
        // 
        let pushedArr=[];
        if (res.status == 'success') {
          let products=[...res.result];
          for(let k=0;k<products.length;k++){
            for(let j=0;j<offlineCart?.items.length;j++){
              if(products[k].product.id==offlineCart?.items[j]?.id){
                products[k].product.in_cart=1;
                products[k].product.quantity=offlineCart?.items[j].quantity
              }
            }
            pushedArr.push(products[k])
            // 
          }
          setFavs(res.result);
        }
      })
      .finally(() => {
        setPageLoading(false);
      });
  };
  const handleChangeQuan = (quan, id, type) => {
    // 
    // 
    let newOptions = [...options];
    let originalOpts = [...options];
    for (let i = 0; i < newOptions.length; i++) {
      if (newOptions[i].id == id) {
        newOptions[i]["quantity"] = quan;
      }
      setOptions(newOptions);
    }
    for (let i = 0; i < originalOpts.length; i++) {
      if (originalOpts[i].id == id) {
        
        if (type == "plus") {
          setTotalPrice(totalPrice * 1 + originalOpts[i].price * 1);
        } else {
          setTotalPrice(totalPrice * 1 - originalOpts[i].price * 1);
        }
        // if(quan>originalOpts[i].quantity){
        //   setTotalPrice(totalPrice*1-originalOpts[i].price*1);
        // }
        // else {
        //   setTotalPrice(totalPrice*1+originalOpts[i].price*1);
        // }
      }
    }
    // setOptions
  };
  const editWeights = () => {
    setEditLoading(true);
    let weightsArr = [...weights2];
    
    let weightstxt = "";
    for (let i = 0; i < weightsArr.length; i++) {
      if (weightsArr[i].product_count > 0) {
        if (weightstxt == "") {
          weightstxt +=
            weightsArr[i].weight +
            "*green*" +
            (weightsArr[i].product_price || weightsArr[i].price) +
            "*green*" +
            weightsArr[i].product_count +
            "*green*" +
            weightsArr[i].cart_prod_id;
        } else {
          weightstxt +=
            "**green**" +
            weightsArr[i].weight +
            "*green*" +
            (weightsArr[i].product_price || weightsArr[i].price) +
            "*green*" +
            weightsArr[i].product_count +
            "*green*" +
            weightsArr[i].cart_prod_id;
        }
      }
    }
    const data_send = {
      cart_id: cartId,
      weights: weightstxt,
    };
    
    Axios({
      url: BASE_URL + `cart/edit_weights`,
      method: "POST",
      data: data_send,
    })
      .then((res) => {
        
        if (res.status == "success") {
          setWeights2([]);
          toast.success(res.message);
          dispatch(fetchCartData());
          setShowChangeWeight2(false);
        } else {
          toast.error(language == "ar" ? "سجل الدخول أولا" : "Log in firsly");
        }
      })
      .finally(() => {
        setEditLoading(false);
      });
  };
  const addToCartWithOptions = () => {
    // 
    // return
    let optionList = [...options];
    // setCartLoading(true);
    let product_options = "";
    for (let i = 0; i < optionList.length; i++) {
      if (optionList[i].quantity != 0) {
        if (product_options.length == 0) {
          product_options +=
            optionList[i].id + "*green*" + optionList[i]["quantity"];
        } else {
          product_options +=
            "**green**" +
            optionList[i].id +
            "*green*" +
            optionList[i]["quantity"];
        }
      }
    }
    let weightsArr = [...weights2];
    
    // 
    // setCartLoading(false)
    // return
    let weightstxt = "";
    for (let i = 0; i < weightsArr.length; i++) {

      if (weightsArr[i].product_count*1 > 0) {
        if (weightstxt == "") {
          weightstxt +=
            weightsArr[i].weight +
            "*green*" +
            weightsArr[i].price +
            "*green*" +
            weightsArr[i].product_count;
        } else {
          weightstxt +=
            "**green**" +
            weightsArr[i].weight +
            "*green*" +
            weightsArr[i].price +
            "*green*" +
            weightsArr[i].product_count;
        }
      }
    }
    let localCart=localStorage.getItem('local_els_cart');
    let CartData=localCart&&JSON.parse(localCart);
    let pushedCart=[...CartData]

    for (let i=0;i<weightsArr.length;i++){
      
      if(weightsArr[i].quantity*1>0){
        
        let pushedItem={
          ...item,
          id:item.id,
          quantity:weightsArr[i].quantity*1,
          product_count:weightsArr[i].quantity*1,
          title_ar:item.title_ar,
          title_en:item.title_en,
          image:item?.images[0]&&item?.images[0].url,
          discount:item.discount,
          price:item.price,
          weight:weightsArr[i].weight,
          total_price:weightsArr[i].price*1*weightsArr[i].quantity*1,
          price_for:item.price_for,
        }
        // return
        pushedCart.push(pushedItem)
        
      }

      // getUserFavs()
      // window.location.reload()
      // return
      // getUserFavs()
    }
    
    dispatch(addWithWeight({pushedCart:pushedCart}))
    setWeights2([]);
    setOpen(false);
    setOpenWeight2(false);
    setOpenQuanModel2(false);
    setShowChangeWeight2(false);setOpen(false);
    setOpenWeight2(false);
    setOpenQuanModel2(false);
    setShowChangeWeight2(false);
    return
    const data_send = {
      // product_options,
      has_option: 0,
      product_id: item.id,
      product_count: quantity,
      // weight: weight.weight,
      weights: weightstxt,
      has_weights: weights2.length > 0 ? 1 : 0,
    };

    
    if (product_options.length > 0) {
      data_send["has_option"] = 1;
      data_send["product_options"] = product_options;
    } else {
      data_send["has_option"] = 0;
    }
    Axios({
      url: BASE_URL + `cart/add_to_cart`,
      method: "post",
      data: data_send,
    })
      .then((res) => {
        if (res.status == "success") {
          toast.success(res.message);
          getUserFavs();
          setOpen(false);
          setWeights2([]);
          dispatch(fetchCartData());
          // getItmData()
          // getUserData();
        } else {
          toast.error(language == "ar" ? "سجل الدخول أولا" : "Log in firsly");
        }
      })
      .finally(() => {
        setCartLoading(false);
      });
  };
  const handleDelWeight = (id) => {
    
    setWeights2(
      weights2.map((item) => {
        return { ...item, loading: true };
      })
    );
    Axios({
      url: BASE_URL + `cart/del_weight/${id}`,
      method: "GET",
    })
      .then((res) => {
        
        if (res.status == "success") {
          setWeights2([]);
          handleGetWeights(cartId);
          dispatch(fetchCartData());
          setShowChangeWeight2(false);
        } else {
          toast.error(language == "ar" ? "سجل الدخول أولا" : "Log in firsly");
        }
      })
      .finally(() => {
        setWeights2(
          weights2.map((item) => {
            return { ...item, loading: true };
          })
        );
      });
  };
  useEffect(() => {
    getUserFavs();
  }, []);
  return (
    <div className="rowDiv HomeProducts gridSystem">
      {false ? (
         <iframe
         width={350}
         height={400}
         style={{ margin: "auto" }}
         src="https://lottie.host/embed/0f5cbd90-b98b-4ef9-a1fe-6853af66f359/mzKPtDndKi.json"
       ></iframe>
      ) : favourite && favourite?.length ? (
        favourite?.map((item) => {
          return (
            <Product
              makeset={true}
              handleGetWeights={handleGetWeights}
              setItem={setItem}
              setOpenQuanModel2={setOpenQuanModel2}
              setOpenWeight2={setOpenWeight2}
              setOpen2={setOpen}
              setShowChangeWeight2={setShowChangeWeight2}
              getData={getUserFavs}
              setWeights2={setWeights2}
              setOptions2={setOptions}
              // makeset={true}
              // getData={getUserFavs}
              item={item}
              id={item?.id}
              image={item?.images[0]?.url}
              title={
                language == 'ar' ? item.title_ar : item.title_en
              }
              price={item?.price}
            />
          );
        })
      ) : (
        <h4>
          {language == "ar"
            ? "لا يوجد منتجات في المفضلة"
            : "No Products In Favourite"}
        </h4>
      )}
        <PopUp
        open={open || openWeight2 || openQuanModel2}
        title={language == "ar" ? "الإضافات" : "Options"}
        setOpen={() => {
          setOpen(false);
          setOpenWeight2(false);
          setOpenQuanModel2(false);
        }}
        children={
          <>
            {" "}
            {options && options?.length ? (
              <div className="options_div">
                <h4>{language == "ar" ? "الاضافات" : "Additions"}</h4>
                <Options
                  item={item}
                  handleChangeQuan={handleChangeQuan}
                  options={options}
                  language={language}
                  setChoosedOptions={setChoosedOptions}
                />
              </div>
            ) : null}
            {weights2 && weights2?.length ? (
              <div className="weights">
                <h4>{language == "ar" ? "الأوزان" : "Weights"}</h4>
                {weights2.map((weightItem, weightIndex) => {
                  return (
                    <div className="price_wei_input_parent">
                      <p>
                        {weightItem.price}
                        {language == 'ar' ? 'د.ك' : 'KWD'}
                      </p>
                      <input
                        onChange={(e) => {
                          
                          
                          setWeights2(
                            weights2.map((it) => {
                              return {
                                ...it,
                                product_count:
                                  it.id == weightItem.id
                                    ? e.target.value
                                    : it.product_count,
                                checked:
                                  it.id == weightItem.id && e.target.value == ""
                                    ? false
                                    : it.checked,
                                    quantity:  it.id == weightItem.id?e.target.value:it.quantity
                              };
                            })
                          );
                        }}
                        className="count_price_weight_input"
                        type="text"
                        value={weightItem.quantity}
                        placeholder={language == "ar" ? "الكميه" : "Count"}
                      />
                      <div
                      // onClick={() => {
                      //   setWeight(weightItem);
                      //   setTotalPrice(weightItem.price);
                      //   setWeights2(
                      //     weights2.map((item) => {
                      //       return {
                      //         ...item,
                      //         checked:
                      //           item.id == weightItem.id ? true : false,
                      //           product_count:0,
                      //       };
                      //     })
                      //   );
                      // }}
                      >
                        <div className="parent">
                          <div
                            // onClick={() => {
                            //   setWeight(weightItem);
                            //   // 
                            //   setTotalPrice(weightItem.price);
                            //   setWeights2(
                            //     weights2.map((item) => {
                            //       return {
                            //         ...item,
                            //         checked:
                            //           item.id == weightItem.id ? true : false,
                            //       };
                            //     })
                            //   );
                            // }}
                            className={
                              weightItem.product_count > 0
                                ? "children active"
                                : "children"
                            }
                          ></div>
                        </div>
                        <p>{weightItem.weight} KG</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}
            {weights2 && weights2.length == 0 && (
              <>
                <h4>{language == "ar" ? "الكمية" : "َQuantity"}</h4>
                <div className="by_quan">
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    name={"count"}
                    value={quantity}
                    onChange={(e) => {
                      setQuantity(e.target.value);
                    }}
                  />
                </div>
              </>
            )}
            <div className="modalBtnAddToCart">
              <span className="new-price">
                {language == "ar" ? "السعر الكلي" : "Total Price"} :{" "}
                {language == "ar" ? "د.ك" : "KWD"} {item.price}
              </span>
              {cartLoading ? (
                <Spinner />
              ) : (
                <button
                  style={{ backgroundColor: "#553a30" }}
                  className="btn btn-success"
                  onClick={() => {
                    if (
                      item?.productweights?.length == 0 &&
                      item?.options.length == 0
                    ) {
                      if (item?.avilabel_stock > 0) {
                        addToCartWithOptions();

                      } else {
                        toast.error(
                          language == "ar"
                            ? "لا يمكن شراء المنتج - لقد نفذ من المخزن مؤقتا"
                            : "Product Temporarily Out Of Stock."
                        );
                      }
                    }
                    // handleInCart();
                  }}
                >
                  {language == "ar" ? "إضافة إلى العربة" : "Add To Cart"}
                </button>
              )}
            </div>
          </>
        }
      />
      <PopUp
        open={changeWeights2}
        title={language == "ar" ? "الإضافات" : "Options"}
        setOpen={() => {
          setOpen(false);
          setOpenWeight2(false);
          setOpenQuanModel2(false);
          setShowChangeWeight2(false);
        }}
        children={
          <>
            {" "}
            {options && options?.length ? (
              <div className="options_div">
                <h4>{language == "ar" ? "الاضافات" : "Additions"}</h4>
                <Options
                  item={item}
                  handleChangeQuan={handleChangeQuan}
                  options={options}
                  language={language}
                  setChoosedOptions={setChoosedOptions}
                />
              </div>
            ) : null}
            {weightsLoading ? (
              <Skeleton count={5} />
            ) : weights2 && weights2?.length ? (
              <div className="weights">
                <h4>{language == "ar" ? "الأوزان" : "Weights"}</h4>
                {weights2.map((weightItem, weightIndex) => {
                  return (
                    <div className="price_wei_input_parent">
                      <p>
                        {weightItem.price}
                        {language == 'ar' ? 'د.ك' : 'KWD'}
                      </p>
                      <input
                        value={weightItem.product_count}
                        onChange={(e) => {
                          
                          setWeights2(
                            weights2.map((it) => {
                              return {
                                ...it,
                                product_count:
                                  it.id == weightItem.id
                                    ? e.target.value
                                    : it.product_count,
                                checked:
                                  it.id == weightItem.id && e.target.value == ""
                                    ? false
                                    : it.checked,
                              };
                            })
                          );
                        }}
                        className="count_price_weight_input"
                        type="text"
                        placeholder={language == "ar" ? "الكميه" : "Count"}
                      />
                      {weightItem.in_cart == 1 ? (
                        weightItem.loading ? (
                          <Spinner />
                        ) : (
                          <IoMdClose
                            style={{
                              color: "red",
                              fontSize: "32px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleDelWeight(weightItem.cart_prod_id);
                            }}
                          />
                        )
                      ) : null}
                      <div
                      // onClick={() => {
                      //   setWeight(weightItem);
                      //   setTotalPrice(weightItem.price);
                      //   setWeights2(
                      //     weights2.map((item) => {
                      //       return {
                      //         ...item,
                      //         checked:
                      //           item.id == weightItem.id ? true : false,
                      //           product_count:0,
                      //       };
                      //     })
                      //   );
                      // }}
                      >
                        <div className="parent">
                          <div
                            // onClick={() => {
                            //   setWeight(weightItem);
                            //   // 
                            //   setTotalPrice(weightItem.price);
                            //   setWeights2(
                            //     weights2.map((item) => {
                            //       return {
                            //         ...item,
                            //         checked:
                            //           item.id == weightItem.id ? true : false,
                            //       };
                            //     })
                            //   );
                            // }}
                            className={
                              weightItem.product_count > 0
                                ? "children active"
                                : "children"
                            }
                          ></div>
                        </div>
                        <p>{weightItem.weight} KG</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}
            <div className="modalBtnAddToCart">
              {editLoading ? (
                <Spinner />
              ) : (
                <button
                  style={{ backgroundColor: "#553a30", margin: "auto" }}
                  className="btn btn-success"
                  onClick={() => {
                    editWeights();
                    // handleInCart();
                  }}
                >
                  {language == "ar" ? "تعديل" : "Add To Cart"}
                </button>
              )}
            </div>
          </>
        }
      />
    </div>
  );
};

export default Favourites;

// Breadcrumb.jsx

import React from "react";
import { useNavigate } from "react-router-dom";
import "./style.css";

const Breadcrumb = ({ links, makedark, style, color }) => {
  const navigate = useNavigate();

  const handleButtonClick = (path, state, link) => {
    !link.not_pass && navigate(path, { state });
  };



  return (
    <div className="breadcrumb rowDiv" style={style}>
      {links?.map((link, index) => (
        <span
          style={{
            color: "rgba(157, 7, 26, 1)",
          }}
          className={link?.active ? "activeLink" : ""}
          key={index}
        >
          {index < links.length - 1 ? (
            <button
              style={{
                color: "rgba(157, 7, 26, 1)",
              }}
              onClick={() => handleButtonClick(link?.path, link?.state, link)}
            >
              {link?.name}
            </button>
          ) : (
            <span style={{color:color && color ? color : "black"}}>{link?.name}</span>
          )}
          {index < links.length - 1 && <span> &gt; </span>}
        </span>
      ))}
      {/* <span>&gt;</span>
      <span
        style={{
          color: color && color ? color : "rgb(172 122 106)",
        }}
      >
        {selectedSubCategory && selectedSubCategory?.title_ar}
      </span> */}
    </div>
  );
};

export default Breadcrumb;

import React, { useEffect, useState } from "react";
import img from "../../assets/images/image.png";
import "./message.css";
import { useLocation, useNavigate } from "react-router-dom";
import UseGeneral from "../../hooks/useGeneral";
import { Axios } from "../../Axios";
import { BASE_URL } from "../../Axios/base_url";
import toast from "react-hot-toast";
const Message = () => {
  const { language } = UseGeneral();
  const location = useLocation();

  const [email, setEmail] = useState("");
  const [id, setId] = useState("");
  const navigate = useNavigate();
  const handleFollowOrderButton = () => {
    navigate("/order-following", { state: { id: location?.state?.order.id } });
  };
  const handleContinueShoping = () => {
    navigate("/");
  };
  const getMeData = () => {
    Axios({
      url: BASE_URL + "user/me",
      method: "POST",
    })
      .then((res) => {
        if (res.status == "success") {
          setEmail(res.result.email);
          setId(res.result.id);
          // setCartTotalPrice(res.result.cart_total_price*1-res.result.money_minus*1);
        }
      })
      .finally(() => {
        // setPageLoading(false)
      })
      .catch((e) => {});
  };

  useEffect(() => {
    getMeData();
  }, []);

  return (
    <div className="message-container">
      {/* <div className="img-container">
        <img src={img} alt="" />
      </div> */}
      <div className="content">
        <h1>
          {language == "ar" ? "شكرا علي طلبك" : "Thank You For Your Order"}
        </h1>
        <div className="content-text">
          {language == "ar" ? (
            <>
              تم بنجاح إنشاء طلبك بالرقم المرجعي للعميل( <strong>{location?.state?.order?.id}</strong> ) . و
              سيتم إرسال نسخة من الفاتورة إالي ( <strong>{email}</strong> )
            </>
          ) : (
            <>
              <>
                Your order has been successfully created with the reference
                customer ( <strong>{location?.state?.order?.id}</strong> ) . And will be sent A copy of
                the invoice to ( <strong>{email}</strong> )
              </>
            </>
          )}
        </div>
        <div className="buttons-container">
          <button onClick={handleFollowOrderButton}>
            {language == "ar" ? "استكمال التسوق" : "Complete your shopping"}
          </button>
          {/* <button
            onClick={() => {
              navigate("/", { replace: true });
            }}
          >
            {language == "ar" ? "استكمال التسوق" : "Complete your shopping"}
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default Message;

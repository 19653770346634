import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Product from "../../../components/product";
// import { productsData } from "../../../data/homeProducts";
import { Loader } from "rsuite";
import UseGeneral from "../../../hooks/useGeneral";
import Banners from "../banner/banners";
import "./style.css";
import PopulerProducts from "../populerProducts";
import ContentLoader from "react-content-loader";
const HomeProducts = ({
  lasAdded,
  topNedded,
  getData,
  changequantype,
  banners,
  leftlastAddedBanners,
  rightLastAddedBanners,
  sections,
}) => {
  const { language } = UseGeneral();
  const [tabId, setTabId] = useState(0);
  const [Products, setProducts] = useState([]);
  useEffect(() => {
    if (tabId == 1) {
      setProducts(lasAdded);
    } else if (tabId == 0) {
      setProducts(topNedded);
    } else {
      setProducts(topNedded);
    }
    // setProducts(tabId == 1 ? lasAdded : tabId == 0 ? topNedded : tabId == 2 ? topNedded : topNedded);
  }, [tabId, getData]);
  return (
    <>
      {" "}
      {!sections && !sections?.length ? (
        <span
        style={{
          margin: "auto",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <iframe
                    width={350}
                    height={400}
                    style={{ margin: "auto" }}
                    src="https://lottie.host/embed/0f5cbd90-b98b-4ef9-a1fe-6853af66f359/mzKPtDndKi.json"
                  ></iframe>
      </span>
      ) : (
        <>
          {/* <Tabs setTabId={setTabId} /> */}
          {sections
            ?.filter((item) => item?.section_type == "box1")
            ?.map((item) => {
              if (item?.products && item?.products?.length)
                return (
                  <div>
                    <div
                      className="rowDiv tapTest"
                      style={{ alignItems: "center" }}
                    >
                      <span>
                        {language == "ar" ? item?.name_ar : item?.name_en}
                      </span>
                      <Link
                        to={"/Product?q=" + item?.id}
                        className="rowDiv linkTitle centerTitle"
                        style={{ fontWeight: "bold" }}
                      >
                        {language == "ar" ? "عرض الكل" : "View All"}
                      </Link>
                    </div>
                    <div className="rowDiv HomeProducts">
                      {item?.products?.map((item, index) => {
                        if (index < 4)
                          return (
                            <Product
                              homestyle={true}
                              handleChangeQuan={(new_quan, id) => {
                                if (tabId == 1) {
                                  changequantype(new_quan, "last_added", id);
                                } else if (tabId == 0) {
                                  changequantype(new_quan, "top_needed", id);
                                }
                              }}
                              getData={getData}
                              item={item}
                              id={item?.id}
                              image={
                                item?.images &&
                                item?.images.length > 0 &&
                                item?.images[0]?.url
                              }
                              title={
                                language == "ar"
                                  ? item?.title_ar
                                  : item.title_en
                              }
                              price={item?.price}
                              discount={item?.discount}
                              discountRatio={item?.discount_ratio}
                            />
                          );
                      })}
                    </div>
                  </div>
                );
            })}

          <div className="rowDiv lastAddedBanners">
            <Banners
              banners={banners?.filter(
                (item) => item?.banner_type == "right_box"
              )}
            />
            <div className="topBottomBanners">
              <Banners
                banners={banners?.filter(
                  (item) => item?.banner_type == "top_box"
                )}
              />
              <Banners
                banners={banners?.filter(
                  (item) => item?.banner_type == "bottom_box"
                )}
              />
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default HomeProducts;

import React from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import {
  Autoplay,
  FreeMode,
  Keyboard,
  Mousewheel,
  Navigation,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { productsData } from "../../data/homeProducts";
import UseGeneral from "../../hooks/useGeneral";
import Product from "../product";
import "./style.css";
import CategoriesProductsSlider from "../categoriesProductsSlider";
const RelatedProducts = ({ products, getData }) => {
  // 
  const studiesswiper = {
    slidesPerView: 2,
    spaceBetween: 10,
    loop: true,
    autoplay: {
      delay: 3000,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    // pagination: {
    //   el: ".swiper-pagination",
    //   clickable: true,
    // },
    breakPoints: {
      0: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      430: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 40,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 50,
      },
      1200: {
        slidesPerView: 4,
        spaceBetween: 50,
      },
    },
  };
  // 
  const { language } = UseGeneral();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  return (
    <div className="relatedProductsSlider">
      <h3
        style={{
          textAlign: "center",
          fontSize: "35px",
          fontWeight: 700,
          margin: "auto",
          marginTop: "56px",
        }}
      >
        {language == "ar" ? "ربما يعجبك أيضا" : "You may also like"}
      </h3>
      <div className="rowDiv row_prods bannerDiv" style={{ marginBottom: "65px",rowGap:'14px' }}>
        {/* <CategoriesProductsSlider
          getData={getData}
          productsData={products}
          language={language}
        /> */}
        {
          products&&products.length>0&&products.map((item)=>{
            return (
              <Product
                homestyle={true}
                getData={getData}
                item={item}
                id={item?.id}
                image={
                  item?.images &&
                  item?.images.length > 0 &&
                  item?.images[0]?.url
                }
                title={language == "ar" ? item?.title_ar : item.title_en}
                price={item?.price}
                discount={item?.discount}
                discountRatio={item?.discount_ratio}
              />
            )
          })
        }
      </div>
    </div>
  );
};

export default RelatedProducts;

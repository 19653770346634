import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Axios } from "../../Axios";
import { emptyCart } from "../../store/offlineCartReducer/OfflineCartReducer";

import { BASE_URL } from "../../Axios/base_url";
import Breadcrumb from "../../components/BreadCumbsLinks";
import UseGeneral from "../../hooks/useGeneral";
import Left from "./Left";
import { Right } from "./Right";
import "./style.css";
import moment from "moment";
import { useDispatch } from "react-redux";
const PayDetils = () => {
  const dispatch = useDispatch();
  let localCart = localStorage.getItem('local_els_cart');
  let cartArr = localCart && JSON.parse(localCart);
  const { language } = UseGeneral();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const breadCrumbsLinks = [
    {
      name: language != "ar" ? "Cart" : "  العوده الي العربه",
      path: "/Cart",
      // active: true,
    },
  ];

  const [meData, setMeData] = useState({});
  const [pageLoading, setPageLoading] = useState(false);
  const [cardInfo, setCardInfo] = useState({
    cvv: "",
    end_date: "",
    card_number: "",
    user_card_name: "",
  });
  const [order_time, setorder_time] = useState(
    localStorage.getItem("order_time")
  );
  const [userOrderInfo, setUserOrderInfo] = useState({
    name: "",
    phone: "",
    location: "",
  });
  const [addLoading, setAddLoading] = useState(false);
  const [pay_type, setpay_type] = useState("card");
  const [choose_subs, setchoose_subs] = useState("");
  const [locations, setLocations] = useState([]);
  const [activeLocation, setActiveLocation] = useState(
    localStorage.getItem("activeLocation")
  );
  const [delivary_type, setdelivary_type] = useState(
    localStorage.getItem("delivary_type")
      ? localStorage.getItem("delivary_type")
      : "quickly"
  );
  const [showCancOrder, setShowCancOrder] = useState(true);
  const handleConfirm = (
    type,
    paymentId,
    paymentStatus,
    invoiceId,
    typePay = "card",
    handlePayment,
    proccessType
  ) => {
    const now = new Date();

    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0');
    const day = String(now.getDate()).padStart(2, '0');
    let order_value = 0;
    setAddLoading(true);

    const formattedDate = `${year}-${month}-${day}`;
    // return formattedDate;
    let prodsText = '';
    let cartList = [...cartArr];
    let productsoptions = '';
    for (let i = 0; i < cartList.length; i++) {
      if (cartList[i].product_options != '') {
        // let optionList=[...]
        //
        if (productsoptions == '') {
          productsoptions += cartList[i].product_options;
        } else {
          productsoptions += "***green***" + cartList[i].product_options;
        }
        // setAddLoading(false)
        // return
      }
      if (i == 0) {
        prodsText +=
          cartList[i].id +
          '*green*' +
          cartList[i].quantity +
          '*green*' +
          cartList[i].weight;
      } else {
        prodsText +=
          '**green**' +
          cartList[i].id +
          '*green*' +
          cartList[i].quantity +
          '*green*' +
          cartList[i].weight;
      }
      order_value += cartList[i].quantity * 1 * cartList[i].price;
    }

    let data_send = {
      phone: userOrderInfo?.phone,
      delivary_type: order_time != "" ? "time" : "quickly",
      order_time: delivary_type == 'quickly' ? formattedDate : order_time,
      location: activeLocation,
      pay_type: "card",
      choose_subs,

      cvv: cardInfo.cvv,
      end_date: cardInfo.end_date,
      card_number: cardInfo.card_number,
      user_card_name: cardInfo.user_card_name,
      has_options: productsoptions != '' ? 1 : 0,
      prods: prodsText,
      paymentType: type ? type : "cash_on_delivery",
      paymentId,
      invoiceId,
      product_options: productsoptions,
      paymentStatus,
      order_value: order_value > 10 ? order_value + 0 : order_value + 1.5,
    };
    if (typePay == "card") {
      if (!params?.get("paymentId")) {
        localStorage.setItem("data_send", JSON.stringify(data_send));
        setPageLoading(false);
        setAddLoading(false)
      }
      if (proccessType == "new process") {
        handlePayment(data_send);
        setPageLoading(false);
        setAddLoading(false)
      } else {
        if (params?.get("paymentId")) {
          return Axios({
            url: BASE_URL + "orders/change_order_data/"+JSON.parse(localStorage.getItem("data_send"))?.new_id,
            method: "POST",
            data: {
              ...JSON.parse(localStorage.getItem("data_send")),
              invoiceId: invoiceId,
              paymentId: paymentId,
              paymentStatus: paymentStatus,
              order_status: "pending"
            },
          })
            .then((res) => {
              if (res.status == "success") {
                if (paymentStatus != "Failed") {
                  setShowCancOrder(true);
                  getMeData();
                  localStorage.removeItem("delivery_time");
                  localStorage.removeItem("order_time");
                  localStorage.removeItem("activeLocation");
                  dispatch(emptyCart());
                  localStorage.removeItem("data_send");
                  navigate("/success", {
                    replace: true,
                    state: { order: res.result },
                  });
                  toast.success(res.message);
                } else {
                  let errorMessage;
                  switch (paymentStatus) {
                    case "InProgress":
                      errorMessage =
                        language === "ar"
                          ? "الدفع قيد التنفيذ"
                          : "Payment is in progress";
                      break;
                    case "Success":
                      errorMessage =
                        language === "ar"
                          ? "تم الدفع بنجاح"
                          : "The payment was successful";
                      break;
                    case "Failed":
                      errorMessage =
                        language === "ar"
                          ? "فشل الدفع"
                          : "The payment was not successful";
                      break;
                    case "Canceled":
                      errorMessage =
                        language === "ar"
                          ? "تم إلغاء الدفع"
                          : "The payment was cancelled";
                      break;
                    case "Authorize":
                      errorMessage =
                        language === "ar"
                          ? "الدفع تحت التفويض"
                          : "The payment is under authorization";
                      break;
                    default:
                      errorMessage =
                        language === "ar"
                          ? "حالة الدفع غير معروفة"
                          : "Unknown payment status";
                  }
                  toast.error(
                    language === "ar" ? `${errorMessage}` : `"${errorMessage}"`
                  );

                  setAddLoading(false);
                }
              }
            })
            .catch((err) => err)
            .finally(() => {
              setAddLoading(false);
            });
        }
      }
    } else if ((paymentId, paymentStatus)) {
      if (delivary_type != "quickly" && !order_time) {
        toast.error(
          language == "ar"
            ? "إختر وقت إذا لم تريد التوصيل سريعا"
            : "Select Date If You If You Do not Need Quickly delivary"
        );
        setPageLoading(false);
        setAddLoading(false);
        return;
      }
      // alert(payType)

      if (!activeLocation) {
        toast.error(language == "ar" ? "أدخل مكان" : "Enter Location");
        setPageLoading(false);
        setAddLoading(false);
        return;
      }
      if (!userOrderInfo?.phone) {
        toast.error(language == "ar" ? "أدخل رقم الهاتف" : "Enter Phone");
        setPageLoading(false);
        setAddLoading(false);
        return;
      }
      // setAddLoading(true);

      Axios({
        url: BASE_URL + "orders/make_order_by_local",
        method: "POST",
        data: data_send,
      })
        .then((res) => {
          if (res.status == "success") {
            setShowCancOrder(true);
            getMeData();
            localStorage.removeItem("delivery_time");
            localStorage.removeItem("order_time");
            localStorage.removeItem("activeLocation");
            dispatch(emptyCart());
            navigate("/success", {
              replace: true,
              state: { order: res.result },
            });
            toast.success(res.message);
          }
        })
        .finally(() => {
          setAddLoading(false);
        });
    }
  };
  const getMeData = () => {
    Axios({
      url: BASE_URL + "user/me",
      method: "POST",
    })
      .then((res) => {
        if (res.message == "Sission Ended Login Again") {
          // toast.error(res.message);
        }
        if (res.status == "success") {
          setMeData(res.result);
          setUserOrderInfo({
            allData: res?.result,
            name: res?.result?.name,
            phone: res?.result?.phone,
            email: res?.result?.email,
            location: res?.result?.location?.name,
          });
          if (res.result.location == null) {
            toast.error(
              language == "ar"
                ? "من فضلك إختر عنوان أولا"
                : "Please Select Location First"
            );
            setOpenAddLocation(true);
            // navigate("/profile", { replace: true });
          }

          // setCartTotalPrice(res.result.cart_total_price*1-res.result.money_minus*1);
        }
      })
      .finally(() => {
        setPageLoading(false);
        setAddLoading(false);
      })
      .catch((e) => {});
  };
  const [openAddLocation, setOpenAddLocation] = useState(false);
  const getUserLocations = () => {
    Axios({
      url: BASE_URL + "userlocation/get_user_locations",
      method: "GET",
    })
      .then((res) => {
        if (res.status == "success") {
          if (Array.isArray(res.result)) {
            setLocations(res.result);
            let userLocations = [...res.result];
            setActiveLocation(
              userLocations?.filter((item) => (item.status = 1)) &&
                userLocations?.filter((item) => (item.status = 1))[0]?.id
            );
          }
          // setLocations();
        }
      })
      .finally(() => {
        setAddLoading(false);
      })
      .catch((e) => {});
  };
  useEffect(() => {
    getMeData();
    getUserLocations();
  }, []);
  useEffect(() => {
    localStorage.setItem("delivary_type", delivary_type);
    localStorage.setItem("order_time", order_time);
    localStorage.setItem("activeLocation", activeLocation);
  }, [order_time, delivary_type, activeLocation]);
  const [payType, setPayType] = useState("card");
  return (
    <div className="pay-details-container">
      {/* <Breadcrumb links={breadCrumbsLinks} /> */}
      <div className="main-content">
        <Right
          getUserLocations={getUserLocations}
          locations={locations}
          setpay_type={setpay_type}
          setorder_time={setorder_time}
          setdelivary_type={setdelivary_type}
          delivary_type={delivary_type}
          setActiveLocation={setActiveLocation}
          activeLocation={activeLocation}
          choose_subs={choose_subs}
          setchoose_subs={setchoose_subs}
          cardInfo={cardInfo}
          setCardInfo={setCardInfo}
          userOrderInfo={userOrderInfo}
          setUserOrderInfo={setUserOrderInfo}
          payType={"card"}
          setPayType={setPayType}
          setOpenAddLocation={setOpenAddLocation}
          openAddLocation={openAddLocation}
        />
        <Left
          addLoading={addLoading}
          handleConfirm={handleConfirm}
          userOrderInfo={userOrderInfo}
          payType={payType}
          setAddLoading={setAddLoading}
        />
      </div>
    </div>
  );
};

export default PayDetils;

import React, { useEffect, useState } from "react";
import "./login.css";
import { IoIosArrowRoundForward } from "react-icons/io";
import UseGeneral from "../../hooks/useGeneral";
import { useNavigate } from "react-router";
import { Axios } from "../../Axios";
import { BASE_URL } from "../../Axios/base_url";
import toast from "react-hot-toast";
import { ThreeDots } from "react-loader-spinner";
import { IoEyeOffOutline, IoEyeOutline } from "react-icons/io5";
import FacebookLoginSocial from "./facebooklogin";
import GoogleLoginSocial from "./googlelogin";
const Login = () => {
  const { language } = UseGeneral();
  const navigate = useNavigate();
  const [provider, setProvider] = useState();
  const [hidePass,setHidePass]=useState(false)
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState();
  const [type, setType] = useState("custom");
  const [userData, setUserData] = useState({
    name: "",
    email: "",
    password: "",
    phone: "",
  });
  useEffect(() => {
    if (localStorage?.getItem("GreenTreesToken")) {
      window.location.href = "/profile";
    }
  }, []);
  const logIn = (e) => {
    try {
      if (e && e.target) e?.preventDefault();
    } catch (err) {
      
    }
    setLoading(true);
    Axios({
      url: BASE_URL + "user/login",
      method: "POST",
      data:
        type == "social"
          ? {
              ...userData,
              type: type,
              provider: provider,
              name: response?.name,
              id: response?.id,
              email: response?.email,
            }
          : {
              ...userData,
            },
      language: language,
    })
      .then((res) => {
        if (res?.status == "success") {
          toast.success(res?.message);
          localStorage.setItem("green_trees", JSON.stringify(res.result.id));
          localStorage.setItem("GreenTreesToken", res?.result.access_token);
          window.location.href = "/";
        } else {
          toast.error(res?.message);
        }
      })
      .catch((err) => err)
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    if (
      (provider == "Google" || provider == "Facebook") &&
      response &&
      type == "social"
    ) {
      logIn();
    } else {
      setType("custom");
    }
    // alert("f");
  }, [provider, response, type]);
  useEffect(() => {
    document.title =
      language == "ar" ? "فن النحت | تسجيل الدخول   " : "Sculpture ART | Login";
  }, []);
  return (
    <div className="login_page">
      <div className="login_content">

        <div className="left">
          <h5>{language == "ar" ? "مرحبا" : "Welcome"}</h5>
          <form
            action=""
            onSubmit={(e) => {
              setType((prevType) => "custom");
              return !loading ? logIn(e) : e.preventDefault();
            }}
          >

            <h4>{language=='ar'?'تسجيل الدخول':'Login'}</h4>
            <div className="inputs-group">
            <div>
              <label htmlFor="phone">
                {language == "ar" ? "رقم الهاتف" : "Phone Number"}
              </label>
              <div className="pass_div">
              <input
                placeholder={
                  language == "ar" ? "أدخل رقم الهاتف" : "Enter Your Phone"
                }
                type="text"
                className="form-control"
                name="phone"
                id="phone"
                onChange={(e) =>
                  setUserData({
                    ...userData,
                    phone: e.target.value,
                    email: e.target.value,
                  })
                }
              />

              </div>
            </div>
            <div>
              <label htmlFor="password">
                {language == "ar" ? "كلمة المرور" : "password"}
              </label>
              <div className="pass_div">
              <input
                placeholder={
                  language == "ar" ? "أدخل كلمة المرور" : "Enter Password"
                }
                type={!hidePass?"password":'text'}
                className="form-control"
                name="password"
                id="password"
                onChange={(e) =>
                  setUserData({
                    ...userData,
                    password: e.target.value,
                  })
                }
              />
                {
                hidePass?
                <IoEyeOutline
                className={language=='en'?'right':''}
                onClick={() => {
                  setHidePass(!hidePass);
                }}
              />
              :
              <IoEyeOffOutline
                className={language=='en'?'right':''}
                  onClick={() => {
                    setHidePass(!hidePass);
                  }}
                />
              }
              </div>
            </div>
            <div>
              {language == "ar" ? (
                <p
                  style={{ cursor: "pointer" , color: '#3D4C5E', fontSize:'15px', fontWeight:'700' }}
                  onClick={() => {
                    navigate("/forget");
                  }}
                >
                  هل نسيت كلمة المرور؟
                </p>
              ) : (
                <p
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/forget");
                  }}
                >
                  Did You Forget Your Password?
                </p>
              )}
            </div>
            </div>
            {loading ? (
              <ThreeDots
                visible={true}
                height="80"
                width="80"
                color="red"
                radius="9"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperclassName=""
              />
            ) : (
              <button>{language == "ar" ? "تسجيل الدخول" : "Log in"}</button>
            )}
            {/* {language == "ar" ? (
              <div className="create_new_text">
                <p>ليس لديك حساب ؟</p>
                {
                  <p
                    onClick={() => {
                      navigate("/sign");
                    }}
                  >
                    تسجيل حساب جديد
                  </p>
                }
              </div>
            ) : (
              <div className="create_new_text">
                <p>Don not Have An Account ?</p>
                <p
                  onClick={() => {
                    navigate("/sign");
                  }}
                >
                  Create An Account
                </p>
              </div>
            )} */}

            <div className="socialsLogin">
              <FacebookLoginSocial
                language={language}
                setProvider={setProvider}
                setType={setType}
                setResponse={setResponse}
              />
              <GoogleLoginSocial
                language={language}
                setProvider={setProvider}
                setResponse={setResponse}
                setType={setType}
              />
            </div>
{language == "ar" ? (
            <div
              onClick={() => {
                navigate("/sign");
              }}
              className="create_new_text create_new_text_n-c"
            >
              <p>ليس لديك حساب؟</p>
              <p>إنشاء حساب</p>
            </div>
          ) : (
            <div
              onClick={() => {
                navigate("/sign");
              }}
              className="create_new_text create_new_text_n-c"
            >
              <p>Don not Have An Account ?</p>
              <p>Create An Account</p>
            </div>
          )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;

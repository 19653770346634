import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { Axios } from "../../Axios";
import { BASE_URL } from "../../Axios/base_url";
import CategoriesProductsSlider from "../../components/categoriesProductsSlider";
import Filters from "../../components/categoryProducts/filters";
import UseGeneral from "../../hooks/useGeneral";
import CategoryBread from "./CategoryBread";
import "./style.css";
const CategoriesProducts = () => {
  const userId = localStorage.getItem("green_trees");
  const user_id = userId && JSON.parse(userId);
  const { language } = UseGeneral();
  const [categoryId, setCategoryId] = useSearchParams();
  const [categories, setCatgories] = useState([]);
  const [subCategories, setSubCategoires] = useState([]);
  const [category, setCategory] = useState("");
  const [pageLoading, setPageLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [items, setItems] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [filter, setFilter] = useState();
  const [productsLoading, setProductsLoading] = useState(false);
  const [ordersBy, setOrdersBy] = useState(null);
  const [moneyOrder, setMoneyOrder] = useState(null);
  // const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [subCategoryLoading, setSubCategoryLoading] = useState(false);
  const [categoryData, setCategoryData] = useState({});
  const [noSubs, setNoSubs] = useState(false);
  const setSelectedSubCategory = (id) => {
    setCategoryId((prev) => {
      prev.set("sub_q", id);
      return prev;
    });
  };
  const getProductBySelectedSubCategory = () => {
    Axios({
      url:
        BASE_URL +
        `categories/get_product_by_category_id/${categoryId.get("sub_q")}`,
      method: "POST",
      data: {
        user_id,
        money_order: moneyOrder,
        order_by: ordersBy,
      },
    })
      .then((res) => {
        if (Array.isArray(res.result)) {
          setCatgories((prev) => res?.result);
          setProducts((prev) => res.result);
          setItems((prev) => res.result);
        }
      })
      .finally(() => {
        setProductsLoading(false);
        setPageLoading(false);
      })
      .catch((e) => e);
  };
  const getSubcategories = () => {
    setPageLoading((prev) => true);
    setSubCategoryLoading(true);
    Axios({
      url:
        BASE_URL + `categories/get_sub_by_cat_to_user/${categoryId.get("q")}`,
      method: "GET",
    })
      .then((res) => {
        if (Array.isArray(res.result)) {
          if (res?.result?.length > 0) {
            if (!categoryId.get("s"))
              setSelectedSubCategory(res?.result[0]?.id);
            else setSelectedSubCategory(categoryId.get("s"));
            // setCategory(res.result[0]?.category || "");
          } else {
            setNoSubs(true);
          }
          setSubCategoires(res.result || []);
        }
      })
      .finally(() => {
        setSubCategoryLoading(false);
      })
      .catch((e) => e);
  };

  const getCategoryData = () => {
    setPageLoading((prev) => true);
    Axios({
      url: BASE_URL + `categories/get_cate_data/${categoryId.get("q")}`,
      method: "GET",
    })
      .then((res) => {
        if (res.status == "success") {
          setCategoryData((prev) => res.result);
        }
      })
      .finally(() => {})
      .catch((e) => e);
  };

  useEffect(() => {
    setProductsLoading(true);
    setProducts((prev) => []);
    setItems((prev) => []);
    getProductBySelectedSubCategory();
  }, [moneyOrder, ordersBy, categoryId.get("sub_q")]);

  useEffect(() => {
    setCategoryData((prev) => {});
    getSubcategories();
    getCategoryData();
  }, [categoryId.get("q")]);

  const navigate = useNavigate();
  return (
    <>
      {pageLoading ? (
        <iframe
          width={350}
          height={400}
          style={{ margin: "auto" }}
          src="https://lottie.host/embed/0f5cbd90-b98b-4ef9-a1fe-6853af66f359/mzKPtDndKi.json"
        ></iframe>
      ) : (
        <div style={{ padding: " 0px 0px 20px 0" }}>
          {categoryData && Object.keys(categoryData).length > 0 && (
            <CategoryBread
              language={language}
              selectedSubCategory={
                subCategories?.filter(
                  (item) => item.id == categoryId.get("sub_q")
                )[0]
              }
              newCategory={categoryData}
            />
          )}

          {noSubs ? (
            <div className="no_subs">
              <img src={require("../../assets/images/no_image.png")} alt="" />
              <h5>
                {language == "ar"
                  ? "ما من فئات فرعيه هنا"
                  : "There Are Not Any Subcategory Here"}
              </h5>
            </div>
          ) : (
            <>
              <div className="categoy_subcats">
                <div className="cates_subs">
                  <button
                    className={
                      false
                        ? "btn filterBtn category filtered"
                        : " filterBtn btn"
                    }
                    style={{ color: "red", fontWeight: "900" }}
                  >
                    {/* {language == "ar"
                        ? categoryData?.title_ar
                        : categoryData?.title_en} */}
                  </button>

                  <Filters
                    filtersData={subCategories}
                    filterChoosed={
                      subCategories.filter(
                        (item) => item.id == categoryId.get("sub_q")
                      )[0]
                    }
                    setFilter={setFilter}
                    language={language}
                    selectedCategory={selectedCategory}
                    setSelectedSubCategory={setSelectedSubCategory}
                    setSelectedCategory={setSelectedCategory}
                  />
                </div>
                {false ? (
                  <iframe
                    width={350}
                    height={400}
                    style={{ margin: "auto" }}
                    src="https://lottie.host/embed/0f5cbd90-b98b-4ef9-a1fe-6853af66f359/mzKPtDndKi.json"
                  ></iframe>
                ) : (
                  <>
                    {/* <button
                      className={
                        false
                          ? "btn filterBtn category filtered"
                          : " filterBtn btn"
                      }
                      style={{ color: "red", fontWeight: "900" }}
                    >
                      {language == "ar"
                        ? categoryData?.title_ar
                        : categoryData?.title_en}
                    </button>

                    <Filters
                      filtersData={subCategories}
                      filterChoosed={
                        subCategories.filter(
                          (item) => item.id == categoryId.get("sub_q")
                        )[0]
                      }
                      setFilter={setFilter}
                      language={language}
                      selectedCategory={selectedCategory}
                      setSelectedSubCategory={setSelectedSubCategory}
                      setSelectedCategory={setSelectedCategory}
                    /> */}
                  </>
                )}
              </div>
              {pageLoading ? null : (
                <div className="product_filters">
                  <Dropdown
                    style={{ width: "fit-content" }}
                    className="order_by_product"
                  >
                    <Dropdown.Toggle
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                        cursor: "pointer",
                        border: "none",
                      }}
                      variant=""
                      id="dropdown-basic"
                    >
                      <img src={require("../../assets/Layer 15.png")} alt="" />
                      <span>
                        {language == "ar" ? "ترتيب حسب:" : "Order By"}
                      </span>
                      <span>
                        {ordersBy == "best_seller"
                          ? language == "ar"
                            ? "الأكثر مبيعا"
                            : "Best Seller"
                          : language == "ar"
                          ? "وصل حديثا"
                          : "recently arrived"}
                      </span>
                      <img src={require("../../assets/Vector(1).png")} alt="" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      style={{
                        // height: "50vh",
                        overflowY: "auto",
                        width: "400px",
                      }}
                    >
                      <div
                        onClick={() => {
                          setOrdersBy("best_seller");
                        }}
                        className="sel_one"
                      >
                        <div
                          className={
                            ordersBy == "best_seller"
                              ? "parent_div active"
                              : "parent_div"
                          }
                        >
                          <div className="child_div"></div>
                        </div>
                        <span>
                          {language == "ar" ? "الأكثر مبيعا" : "Best Seller"}
                        </span>
                      </div>
                      <div
                        onClick={() => {
                          setOrdersBy("recently_arived");
                        }}
                        className="sel_one"
                      >
                        <div
                          className={
                            ordersBy == "recently_arived"
                              ? "parent_div active"
                              : "parent_div"
                          }
                        >
                          <div className="child_div"></div>
                        </div>
                        <span>
                          {language == "ar" ? "وصل حديثا" : "recently arrived"}
                        </span>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>

                  <Dropdown
                    style={{ width: "fit-content" }}
                    className="order_by_product"
                  >
                    <Dropdown.Toggle
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "4px",
                        cursor: "pointer",
                        border: "none",
                      }}
                      variant=""
                      id="dropdown-basic"
                    >
                      <img
                        src={require("../../assets/images/Money Coin.png")}
                        alt=""
                      />
                      <span>{language == "ar" ? "السعر:" : "Price"}</span>
                      <span>
                        {moneyOrder == "top_price"
                          ? language == "ar"
                            ? "الأكثر سعرا"
                            : "Top Price"
                          : language == "ar"
                          ? "الأقل سعر"
                          : "Few Price"}
                      </span>
                      <img src={require("../../assets/Vector(1).png")} alt="" />
                    </Dropdown.Toggle>

                    <Dropdown.Menu
                      style={{
                        // height: "50vh",
                        overflowY: "auto",
                        width: "400px",
                      }}
                    >
                      <div
                        onClick={() => {
                          setMoneyOrder("top_price");
                        }}
                        className="sel_one"
                      >
                        <div
                          className={
                            moneyOrder == "top_price"
                              ? "parent_div active"
                              : "parent_div"
                          }
                        >
                          <div className="child_div"></div>
                        </div>
                        <span>
                          {language == "ar" ? "الأكثر سعر" : "Best Seller"}
                        </span>
                      </div>
                      <div
                        onClick={() => {
                          setMoneyOrder("few_price");
                        }}
                        className="sel_one"
                      >
                        <div
                          className={
                            moneyOrder == "few_price"
                              ? "parent_div active"
                              : "parent_div"
                          }
                        >
                          <div className="child_div"></div>
                        </div>
                        <span>
                          {language == "ar" ? "الأقل سعر" : "Few Price"}
                        </span>
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              )}
              {pageLoading ? (
                <div
                  width={350}
                  style={{
                    margin: "auto",
                    textAlign: "center",
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <iframe
                    width={350}
                    height={400}
                    style={{ margin: "auto" }}
                    src="https://lottie.host/embed/0f5cbd90-b98b-4ef9-a1fe-6853af66f359/mzKPtDndKi.json"
                  ></iframe>
                </div>
              ) : (
                <>
                  {" "}
                  {productsLoading ? (
                    <div
                      width={350}
                      style={{
                        margin: "auto",
                        textAlign: "center",
                        display: "flex",
                        width: "100%",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <iframe
                        width={350}
                        height={400}
                        style={{ margin: "auto" }}
                        src="https://lottie.host/embed/0f5cbd90-b98b-4ef9-a1fe-6853af66f359/mzKPtDndKi.json"
                      ></iframe>
                    </div>
                  ) : (
                    <div className="cate_sub_prod">
                      <div className="cate_prods">
                        {products &&
                        products?.length > 0 &&
                        categoryId.get("q") != 999 ? (
                          <CategoriesProductsSlider
                            getData={getProductBySelectedSubCategory}
                            productsData={products}
                            language={language}
                          />
                        ) : products &&
                          products?.length > 0 &&
                          categoryId.get("q") == 999 ? (
                          <div className="recipes_products rowDiv">
                            {products?.map((item, index) => (
                              <div
                                onClick={() => {
                                  navigate({
                                    pathname: "/recipe",
                                    search: createSearchParams({
                                      q: item?.id,
                                    }).toString(),
                                  });
                                }}
                                key={index}
                                className="rec_div"
                              >
                                <div className="img">
                                  <img
                                    src={
                                      item?.images &&
                                      item?.images[0] &&
                                      item?.images[0].url
                                    }
                                    alt=""
                                  />
                                </div>
                                <h5>
                                  {language == 'ar'
                                    ? item?.title_ar
                                    : item.title_en}
                                </h5>
                                <button>
                                  {language == 'ar' ? 'الوصفه' : 'The Recipe'}
                                </button>
                              </div>
                            ))}
                          </div>
                        ) : (
                          <div
                            style={{
                              padding: "50px 20px",
                              display: "flex",
                              alignItems: "center",
                              flexDirection: "column",
                              justifyContent: "center",
                              gap: "10px",
                            }}
                          >
                            {/* <img
                        src={require("../../assets/images/no_image.png")}
                        style={{ maxWidth: "250px" }}
                        alt=""
                      /> */}
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </>
              )}
            </>
          )}
          {/* <Pagination
            language={language}
            items={items && items?.length ? items : []}
            itemsPerPage={8}
            setProducts={setProducts}
            // onPageChange={(v) => alert(v)}
          /> */}
        </div>
      )}
    </>
  );
};

export default CategoriesProducts;

import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Product from "../product";
import "./style.css";
import { Spinner } from "react-bootstrap";
import Options from "../options/options";
import PopUp from "../popup";
import { Axios } from "../../Axios";
import { BASE_URL } from "../../Axios/base_url";
import toast from "react-hot-toast";
import { ThreeDots } from "react-loader-spinner";
import { IoMdClose } from "react-icons/io";
import UseCartFavourite from "../../hooks/useCartFavourite";
import { fetchCartData } from "../../store/cartReducer";
import { useDispatch } from "react-redux";
import {
  addItem,
  addWithWeight,
} from "../../store/offlineCartReducer/OfflineCartReducer";
import Skeleton from "react-loading-skeleton";
import InfiniteScroll from "react-infinite-scroll-component";
import ContentLoader from "react-content-loader";
const CategoriesProductsSlider = ({ productsData, language, getData }) => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [options, setOptions] = useState([]);
  const [item, setItem] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [editLoading, setEditLoading] = useState(false);
  const [weights2, setWeights2] = useState([]);
  const [cartId, setCartId] = useState("");
  // const { getUserData } = UseCartFavourite();

  const [changeWeights2, setShowChangeWeight2] = useState(false);
  const [weight, setWeight] = useState({});
  const [weightsLoading, setWeightsLoading] = useState(false);
  const [openQuanModel2, setOpenQuanModel2] = useState(false);
  const [openWeight2, setOpenWeight2] = useState(false);
  const [cartLoading, setCartLoading] = useState(false);
  const [choosedOption, setChoosedOptions] = useState([]);
  const [totalPrice, setTotalPrice] = useState("");
  const handleChangeQuan = (quan, id, type) => {
    let newOptions = [...options];
    let originalOpts = [...options];
    for (let i = 0; i < newOptions.length; i++) {
      if (newOptions[i].id == id) {
        newOptions[i]["quantity"] = quan;
      }
      setOptions(newOptions);
    }
    for (let i = 0; i < originalOpts.length; i++) {
      if (originalOpts[i].id == id) {
        if (type == "plus") {
          setTotalPrice(totalPrice * 1 + originalOpts[i].price * 1);
        } else {
          setTotalPrice(totalPrice * 1 - originalOpts[i].price * 1);
        }
        // if(quan>originalOpts[i].quantity){
        //   setTotalPrice(totalPrice*1-originalOpts[i].price*1);
        // }
        // else {
        //   setTotalPrice(totalPrice*1+originalOpts[i].price*1);
        // }
      }
    }
    // setOptions
  };
  const addToCartWithOptions = () => {
    let optionList = [...options];
    let pushedOptions = [];
    setCartLoading(true);
    let product_options = "";
    for (let i = 0; i < optionList.length; i++) {
      if (optionList[i].quantity != 0) {
        pushedOptions.push(optionList[i]);
        if (product_options.length == 0) {
          product_options +=
            optionList[i].id + "*green*" + optionList[i]["quantity"];
        } else {
          product_options +=
            "**green**" +
            optionList[i].id +
            "*green*" +
            optionList[i]["quantity"];
        }
      }
    }
    let weightsArr = [...weights2];
    let localCart = localStorage.getItem('local_els_cart');
    let CartData = localCart && JSON.parse(localCart);
    let pushedCart = [...CartData];
    if (weightsArr.length > 0) {
      for (let i = 0; i < weightsArr.length; i++) {
        if (weightsArr[i].product_count * 1 > 0) {
          let obj = {
            ...item,
            id: item.id,
            quantity: weightsArr[i].product_count,
            title_ar: item.title_ar,
            title_en: item.title_en,
            image: item?.images && item?.images[0] && item?.images[0]?.url,
            weight: weightsArr[i].weight,
            discount: item.discount,
            price: weightsArr[i].price,
            product_optionsarr: pushedOptions,
            product_options,
            has_option: pushedOptions.length > 0 ? 1 : 0,
            price_for: item.price_for,
            total_price: weightsArr[i].price * 1 * weightsArr[i].quantity * 1,
          };
          //
          //
          // dispatch(addItem(obj))
          pushedCart.push(obj);
        }
      }
      //
      // setCartLoading(false)
      // return
      dispatch(addWithWeight({ pushedCart }));
    } else {
      setCartLoading(true);
      let pushedItem = {
        id: item.id,
        quantity: 1,
        title_ar: item.title_ar,
        title_en: item.title_en,
        product_optionsarr: pushedOptions,
        product_options,
        has_option: pushedOptions.length > 0 ? 1 : 0,
        image: item?.images && item?.images[0] && item?.images[0]?.url,
        discount: item.discount,
        price: item.price,
        price_for: item.price_for,
      };
      // return
      dispatch(addItem(pushedItem));
    }
    setCartLoading(false);
    toast.success(
      language == 'ar' ? 'تمت الإضافه بنجاح' : 'Success To Add To Cart'
    );
    // getData();
    setOpen(false);
    dispatch(fetchCartData());
    setOpen(false);
    // getData();
    setOpenWeight2(false);
    setOpenQuanModel2(false);
    setOpen(false);
    setWeights2([]);
    setCartLoading(false);
    return;

    let weightstxt = "";
    for (let i = 0; i < weightsArr.length; i++) {
      if (weightsArr[i].product_count > 0) {
        if (weightstxt == "") {
          weightstxt +=
            weightsArr[i].weight +
            "*green*" +
            weightsArr[i].price +
            "*green*" +
            weightsArr[i].product_count;
        } else {
          weightstxt +=
            "**green**" +
            weightsArr[i].weight +
            "*green*" +
            weightsArr[i].price +
            "*green*" +
            weightsArr[i].product_count;
        }
      }
    }

    const data_send = {
      // product_options,
      has_option: 0,
      product_id: item.id,
      product_count: quantity,
      // weight: weight.weight,
      weights: weightstxt,
      has_weights: weights2.length > 0 ? 1 : 0,
    };
    if (product_options.length > 0) {
      data_send["has_option"] = 1;
      data_send["product_options"] = product_options;
    } else {
      data_send["has_option"] = 0;
    }

    Axios({
      url: BASE_URL + `cart/add_to_cart`,
      method: "post",
      data: data_send,
    })
      .then((res) => {
        if (res.status == "success") {
          toast.success(res.message);
          getData();
          setOpen(false);
          setWeights2([]);
          dispatch(fetchCartData());
          // getItmData()
          // getUserData();
        } else {
          toast.error(language == "ar" ? "سجل الدخول أولا" : "Log in firsly");
        }
      })
      .finally(() => {
        setCartLoading(false);
      });
  };

  const handleGetWeights = (id) => {
    setWeightsLoading(true);
    setShowChangeWeight2(true);
    Axios({
      url: BASE_URL + `cart/get_cart_weights/${id}`,
      method: "get",
    })
      .then((res) => {
        if (res.status == "success") {
          let myResult = [...res.result];
          setWeights2(
            myResult.map((item) => {
              return { ...item, loading: false };
            })
          );
          setCartId(id);
        } else {
          toast.error(language == "ar" ? "سجل الدخول أولا" : "Log in firsly");
        }
      })
      .finally(() => {
        setWeightsLoading(false);
      });
  };

  const editWeights = () => {
    setEditLoading(true);
    let weightsArr = [...weights2];

    let weightstxt = "";
    for (let i = 0; i < weightsArr.length; i++) {
      if (weightsArr[i].product_count > 0) {
        if (weightstxt == "") {
          weightstxt +=
            weightsArr[i].weight +
            "*green*" +
            (weightsArr[i].product_price || weightsArr[i].price) +
            "*green*" +
            weightsArr[i].product_count +
            "*green*" +
            weightsArr[i].cart_prod_id;
        } else {
          weightstxt +=
            "**green**" +
            weightsArr[i].weight +
            "*green*" +
            (weightsArr[i].product_price || weightsArr[i].price) +
            "*green*" +
            weightsArr[i].product_count +
            "*green*" +
            weightsArr[i].cart_prod_id;
        }
      }
    }
    const data_send = {
      cart_id: cartId,
      weights: weightstxt,
    };
    Axios({
      url: BASE_URL + `cart/edit_weights`,
      method: "POST",
      data: data_send,
    })
      .then((res) => {
        if (res.status == "success") {
          setWeights2([]);
          dispatch(fetchCartData());
          setShowChangeWeight2(false);
        } else {
          toast.error(language == "ar" ? "سجل الدخول أولا" : "Log in firsly");
        }
      })
      .finally(() => {
        setEditLoading(false);
      });
  };

  const handleDelWeight = (id) => {
    setWeights2(
      weights2.map((item) => {
        return { ...item, loading: true };
      })
    );
    Axios({
      url: BASE_URL + `cart/del_weight/${id}`,
      method: "GET",
    })
      .then((res) => {
        if (res.status == "success") {
          setWeights2([]);
          handleGetWeights(cartId);
          dispatch(fetchCartData());
          setShowChangeWeight2(false);
        } else {
          toast.error(language == "ar" ? "سجل الدخول أولا" : "Log in firsly");
        }
      })
      .finally(() => {
        setWeights2(
          weights2.map((item) => {
            return { ...item, loading: true };
          })
        );
      });
  };
  const [infinitProducts, setinfinitProducts] = useState([]);
  let [index, setIndex] = useState(0);
  const MAX_NUMBER_OF_PRODUCTS = 8;
  const [infiniteScrollProducts, setInfiniteScrollProducts] = useState([]);
  const [numberOfPages, setNumberOfPages] = useState(0);
  const getMore = () => {
    if ((index) <= numberOfPages) {
      setIndex((prev) => prev + 1);
    }
  };

  useEffect(() => {
    setNumberOfPages(Math.floor(productsData.length / MAX_NUMBER_OF_PRODUCTS));
  }, [productsData]);

  useEffect(() => {
    // alert(index + " - " + numberOfPages)
    const start = index * MAX_NUMBER_OF_PRODUCTS;
    const end = Math.min(
      (index + 1) * MAX_NUMBER_OF_PRODUCTS,
      productsData.length
    );
    setInfiniteScrollProducts([
      ...infiniteScrollProducts,
      ...productsData.slice(start, end),
    ]);
  }, [index, productsData]);

  return (
    <InfiniteScroll
      dataLength={infiniteScrollProducts?.length}
      hasMore={index < numberOfPages}
      next={() => getMore()}
     
      className="rowDiv bannerDiv CategoriesProductsSlider productsTable"
      style={{ marginBottom: "65px", overflow: "visible" }}
    >
      <div
        className="gridSystem"
        spaceBetween={20}
        slidesPerView={4}
        // navigation={true}
        // pagination={{ clickable: true }}
        autoplay={{ delay: 5000 }}
        loop={true}
        dir={language != "en" ? "rtl" : "ltr"}
        breakPoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 1,
            spaceBetween: 30,
          },

          // when window width is >= 640px
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1200: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
        }}
      >
        {infiniteScrollProducts?.map((item, index) => {
          return (
            <SwiperSlide className="product_slider_one" key={index}>
              <Product
                makeset={true}
                handleGetWeights={handleGetWeights}
                setItem={setItem}
                setOpenQuanModel2={setOpenQuanModel2}
                setOpenWeight2={setOpenWeight2}
                setOpen2={setOpen}
                setShowChangeWeight2={setShowChangeWeight2}
                getData={getData}
                setWeights2={setWeights2}
                setOptions2={setOptions}
                item={item}
                id={item?.id}
                image={item?.images && item?.images[0] && item?.images[0]?.url}
                isDetailed={true}
                images={Array(3)
                  .fill(item?.images)
                  .map((item) => item)}
                title={language == "ar" ? item.title_ar : item.title_en}
                price={item?.price}
              />
            </SwiperSlide>
          );
        })}
      </div>
      <PopUp
        open={open || openWeight2 || openQuanModel2}
        title={language == "ar" ? "الإضافات" : "Options"}
        setOpen={() => {
          setOpen(false);
          setOpenWeight2(false);
          setOpenQuanModel2(false);
        }}
        children={
          <>
            {" "}
            {options && options?.length ? (
              <div className="options_div">
                <h4>{language == "ar" ? "الاضافات" : "Additions"}</h4>
                <Options
                  item={item}
                  handleChangeQuan={handleChangeQuan}
                  options={options}
                  language={language}
                  setChoosedOptions={setChoosedOptions}
                />
              </div>
            ) : null}
            {weights2 && weights2?.length ? (
              <div className="weights">
                <h4>{language == "ar" ? "الأوزان" : "Weights"}</h4>
                {weights2.map((weightItem, weightIndex) => {
                  return (
                    <div className="price_wei_input_parent">
                      <p>
                        {weightItem.price}
                        {language == 'ar' ? 'د.ك' : 'KWD'}
                      </p>
                      <input
                        onChange={(e) => {
                          setWeights2(
                            weights2.map((it) => {
                              return {
                                ...it,
                                product_count:
                                  it.id == weightItem.id
                                    ? e.target.value
                                    : it.product_count,
                                checked:
                                  it.id == weightItem.id && e.target.value == ""
                                    ? false
                                    : it.checked,
                              };
                            })
                          );
                        }}
                        className="count_price_weight_input"
                        type="text"
                        placeholder={language == "ar" ? "الكميه" : "Count"}
                      />
                      <div
                      // onClick={() => {
                      //   setWeight(weightItem);
                      //   setTotalPrice(weightItem.price);
                      //   setWeights2(
                      //     weights2.map((item) => {
                      //       return {
                      //         ...item,
                      //         checked:
                      //           item.id == weightItem.id ? true : false,
                      //           product_count:0,
                      //       };
                      //     })
                      //   );
                      // }}
                      >
                        <div className="parent">
                          <div
                            // onClick={() => {
                            //   setWeight(weightItem);
                            //   //
                            //   setTotalPrice(weightItem.price);
                            //   setWeights2(
                            //     weights2.map((item) => {
                            //       return {
                            //         ...item,
                            //         checked:
                            //           item.id == weightItem.id ? true : false,
                            //       };
                            //     })
                            //   );
                            // }}
                            className={
                              weightItem.product_count > 0
                                ? "children active"
                                : "children"
                            }
                          ></div>
                        </div>
                        <p>{weightItem.weight} KG</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}
            {/* {weights2 && weights2.length == 0 && (
              <>
                <h4>{language == "ar" ? "الكمية" : "َQuantity"}</h4>
                <div className="by_quan">
                  <input
                    type="number"
                    onWheel={(e) => e.target.blur()}
                    name={"count"}
                    value={quantity}
                    onChange={(e) => {
                      setQuantity(e.target.value);
                    }}
                  />
                </div>
              </>
            )} */}
            <div className="modalBtnAddToCart">
              <span className="new-price">
                {language == "ar" ? "السعر الكلي" : "Total Price"} :{" "}
                {language == "ar" ? "د.ك" : "KWD"} {item.price}
              </span>
              {cartLoading ? (
                <Spinner />
              ) : (
                <button
                  style={{ backgroundColor: "#553a30" }}
                  className="btn btn-success"
                  onClick={() => {
                    if (item?.avilabel_stock > 0) {
                      addToCartWithOptions()

                    } else {
                      toast.error(
                        language == "ar"
                          ? "لا يمكن شراء المنتج - لقد نفذ من المخزن مؤقتا"
                          : "Product Temporarily Out Of Stock."
                      );
                    }
                    // handleInCart();
                  }}
                >
                  {language == "ar" ? "إضافة إلى العربة" : "Add To Cart"}
                </button>
              )}
            </div>
          </>
        }
      />
      <PopUp
        open={changeWeights2}
        title={language == "ar" ? "الإضافات" : "Options"}
        setOpen={() => {
          setOpen(false);
          setOpenWeight2(false);
          setOpenQuanModel2(false);
          setShowChangeWeight2(false);
        }}
        children={
          <>
            {" "}
            {options && options?.length ? (
              <div className="options_div">
                <h4>{language == "ar" ? "الاضافات" : "Additions"}</h4>
                <Options
                  item={item}
                  handleChangeQuan={handleChangeQuan}
                  options={options}
                  language={language}
                  setChoosedOptions={setChoosedOptions}
                />
              </div>
            ) : null}
            {weightsLoading ? (
              <Skeleton count={6} />
            ) : weights2 && weights2?.length ? (
              <div className="weights">
                <h4>{language == "ar" ? "الأوزان" : "Weights"}</h4>
                {weights2.map((weightItem, weightIndex) => {
                  return (
                    <div className="price_wei_input_parent">
                      <p>
                        {weightItem.price}
                        {language == 'ar' ? 'د.ك' : 'KWD'}
                      </p>
                      <input
                        value={weightItem.product_count}
                        onChange={(e) => {
                          setWeights2(
                            weights2.map((it) => {
                              return {
                                ...it,
                                product_count:
                                  it.id == weightItem.id
                                    ? e.target.value
                                    : it.product_count,
                                checked:
                                  it.id == weightItem.id && e.target.value == ""
                                    ? false
                                    : it.checked,
                              };
                            })
                          );
                        }}
                        className="count_price_weight_input"
                        type="text"
                        placeholder={language == "ar" ? "الكميه" : "Count"}
                      />
                      {weightItem.in_cart == 1 ? (
                        weightItem.loading ? (
                          <Spinner />
                        ) : (
                          <IoMdClose
                            style={{
                              color: "red",
                              fontSize: "32px",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleDelWeight(weightItem.cart_prod_id);
                            }}
                          />
                        )
                      ) : null}
                      <div
                      // onClick={() => {
                      //   setWeight(weightItem);
                      //   setTotalPrice(weightItem.price);
                      //   setWeights2(
                      //     weights2.map((item) => {
                      //       return {
                      //         ...item,
                      //         checked:
                      //           item.id == weightItem.id ? true : false,
                      //           product_count:0,
                      //       };
                      //     })
                      //   );
                      // }}
                      >
                        <div className="parent">
                          <div
                            // onClick={() => {
                            //   setWeight(weightItem);
                            //   //
                            //   setTotalPrice(weightItem.price);
                            //   setWeights2(
                            //     weights2.map((item) => {
                            //       return {
                            //         ...item,
                            //         checked:
                            //           item.id == weightItem.id ? true : false,
                            //       };
                            //     })
                            //   );
                            // }}
                            className={
                              weightItem.product_count > 0
                                ? "children active"
                                : "children"
                            }
                          ></div>
                        </div>
                        <p>{weightItem.weight} KG</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : null}
            <div className="modalBtnAddToCart">
              {editLoading ? (
                <Spinner />
              ) : (
                <button
                  style={{ backgroundColor: "#553a30", margin: "auto" }}
                  className="btn btn-success"
                  onClick={() => {
                    editWeights();
                    // handleInCart();
                  }}
                >
                  {language == "ar" ? "تعديل" : "Add To Cart"}
                </button>
              )}
            </div>
          </>
        }
      />
      {/* <PopUp
        open={openWeight2}
        title={language == "ar" ? "الأوزان المتاحه" : "Weights"}
        setOpen={setOpenWeight2}
        children={
          <>
            {" "}
            <div className="weights">
              {weights2.map((weightItem, weightIndex) => {
                return (
                  <div>
                    <p>{weightItem.price}EGP</p>
                    <div
                      onClick={() => {
                        setWeight(weightItem);
                        // 
                        // 
                        setTotalPrice(weightItem.price);
                        setWeights2(
                          weights2.map((item) => {
                            return {
                              ...item,
                              checked: item.id == weightItem.id ? true : false,
                            };
                          })
                        );
                      }}
                    >
                      <div className="parent">
                        <div
                          onClick={() => {
                            setWeight(weightItem);
                            // 
                            setTotalPrice(weightItem.price);
                            setWeights2(
                              weights2.map((item) => {
                                return {
                                  ...item,
                                  checked:
                                    item.id == weightItem.id ? true : false,
                                };
                              })
                            );
                          }}
                          className={
                            weightItem.checked ? "children active" : "children"
                          }
                        ></div>
                      </div>
                      <p>{weightItem.weight} KG</p>
                    </div>
                  </div>
                );
              })}
            </div>
            <div>
              {cartLoading ? (
                <Spinner />
              ) : (
                <button
                  onClick={() => {
                    if (open) {
                      setOpenWeight2(false);
                    } else {
                      handleInCart();
                      addToCartWithOptions();
                    }
                  }}
                  className="conf_btn"
                >
                  {language == "ar" ? "تأكيد" : "Confirm"}
                </button>
              )}
            </div>
          </>
        }
      /> */}
      {/* <PopUp
        open={openQuanModel2}
        title={language == "ar" ? "الكميه" : "Quantity"}
        setOpen={setOpenQuanModel2}
        children={
          <>
            <div className="by_quan">
              <input
                type="number"
                onWheel={(e) => e.target.blur()}
                name={"count"}
                onChange={(e) => {
                  setQuantity(e.target.value);
                }}
              />
            </div>
            <div>
              {cartLoading ? (
                <Spinner />
              ) : (
                <button
                  onClick={() => {
                    if (open) {
                      setOpenQuanModel2(false);
                    } else {
                      handleInCart();
                    }
                  }}
                  className="conf_btn btn btn-success"
                >
                  {language == "ar" ? "تأكيد" : "Confirm"}
                </button>
              )}
            </div>
          </>
        }
      /> */}
    </InfiniteScroll>
  );
};

export default CategoriesProductsSlider;

import React, { useEffect, useState } from "react";
import "./orders.css";
import OrderCard from "./OrderCard";
import { Axios } from "../../../Axios";
import { BASE_URL } from "../../../Axios/base_url";
import { Spinner } from "react-bootstrap";
import UseGeneral from "../../../hooks/useGeneral";
import moment from "moment";
import { useNavigate } from "react-router-dom";
const Orders = () => {
  const navigate = useNavigate();
  const { language } = UseGeneral();
  const [pageLoading, setPageLoading] = useState(false);
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [orderData, setOrderData] = useState({});
  const [activeTab, setActiveTab] = useState({
    title: language == "ar" ? "الحاليه" : "Current",
    id: 0,
    status: "onProgress",
  });

  const [orders, setOrders] = useState([]);
  const [filterdOrders, setFilterdOrders] = useState([]);
  const tabs = [
    {
      title: language == "ar" ? "الحاليه" : "Current",
      id: 0,
      status: "onProgress",
    },
    {
      title: language == "ar" ? "المكتمله" : "Completed",
      id: 1,
      status: "completed",
    },
    {
      title: language == "ar" ? "الملغيه" : "Canceled",
      id: 2,
      status: "canceled",
    },
  ];

  const getUserOrders = () => {
    setPageLoading(true);
    Axios({
      url: BASE_URL + `orders/get_order_by_status/${activeTab.id}`,
      method: "GET",
    })
      .then((res) => {
        if (res.status == "success") {
          if (Array.isArray(res.result)) {
            // const homeUpdateNumber = localStorage.getItem("home_update")
            // if(!homeUpdateNumber){
            //   localStorage.setItem('home_update' ,'0')
            //   localStorage.setItem('orders' , JSON.stringify(res?.result))
            //   setOrders(res?.result);
            // }else{
            //   localStorage.setItem('orders' , JSON.stringify(res?.result))
            //     // setCategories( prev => JSON.parse(localStorage.getItem('headerCats')))
            //     setOrders( prev => prev = JSON.parse( localStorage.getItem('orders')));
            // }
            setOrders(res.result);
          }
        }
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  useEffect(() => {
    const filterdData = orders.filter(
      (order) => order.status === activeTab.status
    );

    setFilterdOrders(filterdData);
  }, []);

  useEffect(() => {
    const filterdData = orders.filter(
      (order) => order.status === activeTab.status
    );
    setFilterdOrders(filterdData);
    getUserOrders();
  }, [activeTab]);

  const handleActiveTab = (e, tab) => {
    setActiveTab(tab);
  };
  // useEffect(()=>{
  //   getUserOrders()
  // },[])
  return (
    <div className="orders-container">
      <h2 className="title">{language == "ar" ? "طلباتي" : "My orders"}</h2>
      <div className="tabs mb-2">
        {tabs.map((tab, index) => (
          <div
            className={`tab ${activeTab?.title === tab.title && "active"}`}
            onClick={(e) => handleActiveTab(e, tab)}
          >
            {tab.title}
          </div>
        ))}
      </div>
      {pageLoading ? (
        <Spinner />
      ) : (
        <div className="orders">
          {showOrderDetails ? (
            <div className="orderCard-container orderCard-container-show">
              <div className="right">
                <h6 className="ord_numb">
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      navigate("/order-following", {
                        state: { id: orderData?.id },
                      });
                    }}
                  >
                    {language=='ar'?"مرجع العميل":'Customer Reference'} :{" "}
                    {orderData?.id}
                  </span>
                  <button
                    onClick={() => {
                      setShowOrderDetails(false);
                    }}
                    className="btn btn-danger"
                  >
                    {language == 'ar' ? 'رجوع' : 'Back'}
                  </button>
                </h6>
                <div className="status-title">
                  {orderData?.order_status == "pending"
                    ? language == "ar"
                      ? "قيد المراجعه"
                      : "Pending"
                    : orderData?.order_status == "canceled"
                    ? language == "ar"
                      ? "ملغى"
                      : "canceled"
                    : orderData?.order_status == "rejected"
                    ? language == "ar"
                      ? "ملغى"
                      : "canceled"
                    : orderData?.order_status == "completed"
                    ? language == "ar"
                      ? "مكتمل"
                      : "completed"
                    : language == "ar"
                    ? "فى التوصيل"
                    : "In Delivery"}
                </div>
                <div className="date">
                  <span>{language == 'ar' ? 'يوم' : 'Day'} </span>
                  <span>{moment(orderData?.created_at).format('L')}</span>
                </div>
                <div className="re_make_order">
                  <img src={require('../../../assets/reload.png')} alt="" />
                  <h5>{language == 'ar' ? 'إعاده الطلب' : 'Reorder'}</h5>
                </div>
                <div className="other_details">
                  <div className="top">
                    <div>
                      <img src={require('../../../assets/loc.png')} alt="" />
                      <h5>
                        {language == 'ar'
                          ? 'تفاصيل التوصيل'
                          : 'Delivery Details'}
                      </h5>
                    </div>
                    <div>
                      <img src={require('../../../assets/box.png')} alt="" />
                      <h5>
                        {language == 'ar' ? 'تفاصيل الطلب' : 'Order Details'}
                      </h5>
                    </div>
                  </div>
                  <div className="bottom">
                    <div className="bottom_right">
                      <div className="del_loc">
                        <h5>
                          {language == 'ar'
                            ? 'عنوان الشحن'
                            : 'Delivery Location'}
                        </h5>
                        <p>
                          <span>{language == 'ar' ? 'الإسم' : 'Name'}: </span>
                          {}
                          <span>{orderData?.user?.name}</span>
                        </p>
                        <p>
                          <span>
                            {language == 'ar' ? 'العنوان' : 'Location'}:{" "}
                          </span>
                          <span>{orderData?.location?.region} - {orderData?.location?.district} - {orderData?.location?.city}</span>
                        </p>
                      </div>
                      <div className="pay_det">
                        <h5>{language == 'ar' ? 'الدفع' : 'Payment'}</h5>
                        <div>
                        <h5>
                            {orderData?.pay_type != 'card'
                              ? language == 'ar'
                                ? 'الدفع عند الإستلام'
                                : 'Paiement when recieving'
                              : language == 'ar'
                              ? 'الدفع عن طريق البطاقة الائتمانية'
                              : 'Pay By Credit Card'}
                          </h5>
                        <p>
                            <span>
                              {language == 'ar'
                                ? "رقم الفاتورة"
                                : "Invoice Number"}
                              :{" "}
                            </span>
                            <span>
                              <span>{orderData?.invoiceId}</span>
                            </span>
                          </p>
                          <p>
                            <span>
                              {language == 'ar'
                                ? "مرجع الفاتورة"
                                : "Invoice Reference"}
                              :{" "}
                            </span>
                            <span>
                              <span>{orderData?.paymentId}</span>
                            </span>
                          </p>
                          
                          <p>
                            <span>
                              {language == 'ar'
                                ? 'إجمالى الطلبات'
                                : "Total Orders"}
                              :{" "}
                            </span>
                            <span>
                              <span>{orderData?.order_value?.toFixed(3)}</span>
                              <span>{language == 'ar' ? 'د.ك' : 'KWD'}</span>
                            </span>
                          </p>
                          <p>
                            <span>
                              {language == 'ar' ? 'التوصيل' : 'Delivery'}:
                            </span>
                            <span>
                              {" "}
                              {(orderData?.order_value * 1 > 10
                                ? 0
                                : 1.5
                              )?.toFixed(3)}{" "}
                              {language == "ar" ? "د.ك" : "KWD"} KWD
                            </span>
                          </p>
                          <p>
                            <span>
                              {language == 'ar' ? 'الإجمالى' : 'Total'}:{" "}
                            </span>
                            <span>
                              {(
                                orderData?.order_value +
                                (orderData?.order_value * 1 > 10 ? 0 : 1.5)
                              )?.toFixed(3)}{" "}
                              KWD
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="bottom_left">
                      {orderData &&
                        orderData?.orderproducts &&
                        orderData?.orderproducts.length &&
                        orderData?.orderproducts?.map((ordProd, ordInd) => {
                          return (
                            <p>
                              <span>
                                {language == 'ar'
                                  ? ordProd?.product?.title_ar
                                  : ordProd?.product?.title_en}
                              </span>
                              <span>
                                {language == 'ar' ? (
                                  <span>
                                    {parseFloat(
                                      ordProd?.product?.price
                                    ).toFixed(3)}{" "}
                                    KWD
                                  </span>
                                ) : (
                                  <span>
                                    {parseFloat(
                                      ordProd?.product?.price
                                    ).toFixed(3)}{" "}
                                    د.ك
                                  </span>
                                )}
                              </span>
                            </p>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            orders.map((order) => (
              <OrderCard
                setOrderData={setOrderData}
                showOrderDetails={showOrderDetails}
                setShowOrderDetails={setShowOrderDetails}
                data={order}
              />
            ))
          )}
        </div>
      )}
    </div>
  );
};

export default Orders;

import React, { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { Axios } from "../../Axios";
import { BASE_URL } from "../../Axios/base_url";
import Breadcrumb from "../../components/BreadCumbsLinks";
import PopUp from "../../components/popup";
import UseGeneral from "../../hooks/useGeneral";
import AddLocation from "../Profile/location/AddLocation";
import "./right.css";
import toast from "react-hot-toast";

export const Right = ({
  locations,
  setpay_type,
  setorder_time,
  setdelivary_type,
  delivary_type,
  setActiveLocation,
  activeLocation,
  getUserLocations,
  cardInfo,
  setCardInfo,
  userOrderInfo,
  setUserOrderInfo,
  setchoose_subs,
  choose_subs,
  setPayType,
  openAddLocation,
  setOpenAddLocation
}) => {
  const { language } = UseGeneral();
  const navigate = useNavigate();
  const [cartData, setCartsData] = useState([]);

  const [cartTotalPrice, setCartTotalPrice] = useState(0);
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [payCheckbox, setPayCheckbox] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [date, setDate] = useState("");
  const [diableInputs, setDisableInputs] = useState(true);
  // const [locations, setLocations] = useState([]);
  const breadCrumbsLinks = [
    {
      name: language != "ar" ? "Cart" : "  العوده الي العربه",
      path: "/Cart",
      // active: true,
    },
  ];

  const getUserData = () => {
    setPageLoading(true);
    Axios({
      url: BASE_URL + "cart/all_carts_for_user",
      method: "GET",
    })
      .then((res) => {

        if (res.message == "Sission Ended Login Again") {
          // toast.error(res.message);
        }
        if (Array.isArray(res.result)) {
          setCartsData(res.result);
        }
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  useEffect(() => {
    getUserData();
  }, []);
  const [editData, setEditData] = useState(false)
  useEffect(() => {
    console.log(userOrderInfo)
    if (userOrderInfo?.allData)
      if (!userOrderInfo?.phone || !userOrderInfo?.phone?.length || !userOrderInfo?.name || !userOrderInfo?.name?.length || !userOrderInfo?.email || !userOrderInfo?.email?.length) {
        setEditData(true)
      }
  }, [userOrderInfo])


  const handleEditElement = (e) => {
    const { id } = e.target;
    setEditElement(id);
  };
  const [editElement, setEditElement] = useState({});
  const [accountEditValues, setAccountEditValues] = useState();
  const [EditAccountLoading, setEditAccountLoading] = useState(false);
  const [editPasswordLoading, setEditPasswordLoading] = useState(false);
  const handleSubmitEditAccount = () => {
    setEditAccountLoading(true);
    delete userOrderInfo?.allData;
    Axios({
      url: BASE_URL + "user/edit_user",
      method: "POST",
      data: userOrderInfo,
    })
      .then((res) => {
        if (res.message == "Sission Ended Login Again") {
          toast.error(res.message);
          setEditAccountLoading(false);
        }
        if (res.status == "success") {
          toast.success(res.message);
          setEditAccountLoading(false);
          getUserData();
        } else {
          toast.error(res.message);

        }
      })
      .catch((e) => {

      })
      .finally(() => {
        setEditAccountLoading(false);
      });
  };
  const handleAccountEditChange = (e) => {
    const { value, name } = e.target;

    setUserOrderInfo((prev) => {
      return { ...prev, [name]: value };
    });
  };
  return (
    <div className="right-container">
      <Breadcrumb links={breadCrumbsLinks} />
      <div className="pay-map-container">
        <div className={language == "ar" ? "row" : "row rev_row"}>
          <div className="title">
            <h1>
              {language == "ar" ? "البيانات الرئيسيه" : "Main Information"}
            </h1>
            <div className="icon-container">
              <FiEdit
                style={{
                  cursor: "pointer",
                }}
                onClick={() => {
                  setDisableInputs(!diableInputs);
                }}
              />
            </div>
          </div>
          <div className="content">
            <div className="d-flex inputs">
              <strong>{language == "ar" ? "الاسم" : "Name"}: </strong>
              <input
                style={{
                  // border: diableInputs ? "none" : "1px solid #ccc",
                  borderRadius: "4px",
                  flex: "1",
                }}
                disabled

                onChange={(e) => {
                  setUserOrderInfo({ ...userOrderInfo, name: e.target.value });
                }}
                type="text"
                value={userOrderInfo?.name}
              />
            </div>
            { }
            <div className="d-flex inputs">
              <strong>{language == "ar" ? "الرقم" : "Phone"}: </strong>
              { }
              <input
                onChange={(e) => {
                  setUserOrderInfo({ ...userOrderInfo, phone: e.target.value });
                }}
                style={{
                  // border: diableInputs ? "none" : "1px solid #ccc",
                  borderRadius: "4px",
                  flex: "1",
                }}
                disabled
                type="text"
                value={userOrderInfo?.phone}
              />
            </div>
            <div className="d-flex inputs align-items-start">
              <strong>{language == "ar" ? "العنوان" : "location"}: </strong>
              <div className="flex-column align-items-start d-flex">
                <select
                  onChange={(e) => {
                    setActiveLocation(e.target.value);
                    setUserOrderInfo({
                      ...userOrderInfo,
                      allAddress: locations?.filter(
                        (item) => item?.id == e.target.value
                      )[0],
                    });
                  }}
                  disabled={diableInputs}
                  style={{
                    border: diableInputs ? "none" : "1px solid #ccc",
                    borderRadius: "4px",
                    flex: "1",
                    backgroundColor: "transparent",
                  }}
                  value={activeLocation}
                  name=""
                  id=""
                >
                  
                  {locations?.map((item, index) => {
                    // alert(index)
                    return (
                      <option value={item.id}>
                        {item?.region} - {item?.city} - {item?.streat} -{" "}
                        {item?.district} -{item?.special_marque}
                      </option>
                    );
                  })}
                </select>
                {diableInputs ? null : (
                  <button
                    className="btn btn-danger"
                    onClick={() => setOpenAddLocation(true)}
                  >
                    {language == "ar" ? "أضف عنوان" : "Add Address"}
                  </button>
                )}
                <PopUp
                  open={openAddLocation}
                  title={language == "ar" ? "إضافة عنوان " : "Add Address"}
                  setOpen={setOpenAddLocation}
                >
                  <AddLocation getAgainData={getUserLocations} first={true} />
                </PopUp>
              </div>
              {/* <input style={{
                border:diableInputs?'none':'1px solid #ccc',
                borderRadius:'4px',
                flex:'1'
              }} disabled={diableInputs} type="text" value={userOrderInfo?.location} /> */}
            </div>
          </div>
        </div>
        <PopUp
          open={editData}
          title={language == "ar" ? "تعديل بيانات الحساب" : "Edit Account"}
          setOpen={setEditData}
        >
          <div className="account_page">
            <div className="account_info">
              <div className="edit_container">
                <div className="info">
                  <div>
                    <h5>
                      <span>{language == "ar" ? "الحساب" : "Account"}</span>
                      {editElement !== "acount" && (
                        <FiEdit id="acount" onClick={handleEditElement} />
                      )}
                    </h5>
                    <div className="edit_container">
                      <div className="info">
                        <div>
                          <h5>{language == "ar" ? "الإسم" : "Name"}</h5>
                          <input
                            type="text"
                            className="input_edit"
                            placeholder="الاسم"
                            value={userOrderInfo?.name}
                            name="name"
                            onChange={handleAccountEditChange}
                          />
                        </div>
                        <div>
                          <h5>{language == "ar" ? "البريد الإلكترونى" : "Email"}</h5>
                          <input
                            type="text"
                            className="input_edit"
                            placeholder="البريد الالكتروني"
                            value={userOrderInfo?.email}
                            name="email"
                            onChange={handleAccountEditChange}
                          />
                        </div>
                        <div>
                          <h5>{language == "ar" ? "الرقم" : "Number"}</h5>
                          <input
                            type="text"
                            className="input_edit"
                            placeholder="رقم الهاتف"
                            value={userOrderInfo?.phone}
                            name="phone"
                            onChange={handleAccountEditChange}
                          />
                        </div>
                      </div>
                      <button className="edit_button" onClick={handleSubmitEditAccount}>
                        تعديل
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </PopUp>
        <div className={language == "ar" ? "row" : "row rev_row"}>
          <div className="title">
            <h1>{language == "ar" ? "توصيل" : "Delivery"}</h1>
            {/* <div className="icon-container">
              <FiEdit />
            </div> */}
          </div>
          <div className="content">
            <div className="checkBox-container">
              {/* <label
                onClick={() => {
                  setdelivary_type("quickly");
                }}
                className="checkBox"
              >
               
              </label>{" "} */}
              <div>
                <h6>
                  {language == "ar" ? "في خلال 24 ساعة" : "within 24 hours"}
                </h6>
                {/* <span>
                  {language == "ar"
                    ? "اليوم 12:00 م - 01:00 م"
                    : "Today 12:00 م - 01:00 م"}
                </span> */}
              </div>
            </div>
            {/* <label
              htmlFor="date"
              style={{ cursor: "pointer" }}
              onClick={() => {
                setdelivary_type("time");
              }}
              className="d-flex"
            >
              <div className="icon">{calendarTime}</div>{" "}
              <strong>
                {language == "ar" ? "جدولة موعد" : "Schedule an appointment"}
              </strong>
            </label>
            {delivary_type == "time" ? (
              <input
                onChange={(e) => {
                  setorder_time(e.target.value);
                  
                }}
                style={{
                  borderRadius: "4px",
                  border: "1px solid #ccc",
                  padding: "10px",
                  outline: "none",
                }}
                id="date"
                name="date"
                type="date"
              />
            ) : null} */}
          </div>
        </div>
        <div className={language == "ar" ? "row" : "row rev_row"}>
          <div className="title">
            <h1>
              {language == "ar" ? "معلومات الدفع" : "payment information"}
            </h1>
          </div>
          <div className="content">
            {/* <div className="checkBox-container">
              <label
                onClick={() => {
                  setpay_type(payCheckbox ? "cash" : "cash_on_delivery");
                }}
                className="checkBox"
              >
                <input
                  id="ch1"
                  value={"cash_on_delivery"}
                  type="checkbox"
                  checked={payCheckbox == "cash_on_delivery"}
                  onChange={handlePayCheckboxChange}
                />
                <div className="transition"></div>
              </label>
              <div>
                <h6>
                  {language == "ar"
                    ? "الدفع نقدا عند الإستلام"
                    : "Payment in cash upon delivery"}
                </h6>
              </div>
            </div> */}
            <div className="checkBox-container">
              {/* <label
                onClick={() => {
                  setpay_type(payCheckbox ? "cash_on_delivery" : "card");
                }}
                className="checkBox"
              >
                <input
                  id="ch1"
                  value={"card"}
                  type="checkbox"
                  checked={payCheckbox == "card"}
                  onChange={handlePayCheckboxChange}
                />
                <div className="transition"></div>
              </label> */}
              <div>
                <h6>
                  {language == "ar"
                    ? "الدفع عن طريق البطاقة الائتمانية"
                    : "Payment With Credit Card"}
                </h6>
              </div>
            </div>
            {/* <div
              className="d-flex"
              onClick={handleOpenPayment}
              style={{ cursor: "pointer" }}
            >
              <div className="icon">{calendarTime}</div>{" "}
              <strong>
                {language == "ar"
                  ? "الدفع عن طريق البطاقة الأئتمانية"
                  : "Payment via credit card"}
              </strong>
            </div>
            <div className={`payment_method ${paymentOpen ? "active" : false}`}>
              <div className="input-form">
                <label htmlFor="">
                  {language == "ar" ? "حامل البطاقة" : "Card holder"}
                </label>
                <input
                  onChange={(e) => {
                    setCardInfo({
                      ...cardInfo,
                      user_card_name: e.target.value,
                    });
                  }}
                  type="text"
                  placeholder={
                    language == "ar" ? "حامل البطاقة" : "Card holder"
                  }
                />
              </div>
              <div className="input-form">
                <label htmlFor="">
                  {language == "ar" ? "رقم البطاقة" : "Card Number"}
                </label>
                <input
                  onChange={(e) => {
                    setCardInfo({
                      ...cardInfo,
                      card_number: e.target.value,
                    });
                  }}
                  type="text"
                  placeholder="000 000 000 000"
                />
              </div>
              <div className="d-flex">
                <div className="input-form">
                  <label htmlFor="">
                    {language == "ar" ? "تاريخ الانتهاء" : "End Date"}
                  </label>
                  <input
                    onChange={(e) => {
                      setCardInfo({
                        ...cardInfo,
                        end_date: e.target.value,
                      });
                    }}
                    type="text"
                    placeholder="00/00"
                  />
                </div>
                <div className="input-form">
                  <label htmlFor="">cvv</label>
                  <input
                    onChange={(e) => {
                      setCardInfo({
                        ...cardInfo,
                        cvv: e.target.value,
                      });
                    }}
                    type="text"
                    placeholder="000"
                  />
                </div>
              </div>
              <div className="checkBox-container">
                <label className="checkBox">
                  <input id="ch1" type="checkbox" />
                  <div className="transition"></div>
                </label>{" "}
                <div>
                  {language == "ar"
                    ? "حفظ البطاقه و استخدامها مرة اخري"
                    : "Save the card and use it again"}
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      {/* <div className="bottom">
        <div className="title">
          <h3>
            {language == "ar"
              ? "اختيارات المنتجات البديلة"
              : "Alternative product choices"}
          </h3>
          <p style={{width:'90%'}}>
            {language == "ar"
              ? "في حال عدم توافر بعض المنتجات, كيف تفضل اختيار البدائل للمنتجات غير المتوفرة؟"
              : "If some products are not available, how do you prefer to choose alternatives for unavailable products?"}
          </p>
          <div className="content">
            <div>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setchoose_subs("info");
                }}
                className="checkBox-container"
              >
                <label className="checkBox">
                  <input
                    type="checkbox"
                    checked={choose_subs === "info"}
                    onChange={() => handleCheckboxChange("option1")}
                  />
                  <div className="transition"></div>
                </label>{" "}
                <div>
                  <h6>{language == "ar" ? "ابلغني" : "Inform me"}</h6>
                </div>
              </div>
              <p>
                {language == "ar"
                  ? "سسيم إرسال لينك بالمنتجات البديلة عن طريق الواتساب."
                  : "Send a link to alternative products via WhatsApp."}
              </p>
            </div>
            <div>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  setchoose_subs("call");
                }}
                className="checkBox-container"
              >
                <label className="checkBox">
                  <input
                    type="checkbox"
                    checked={choose_subs === "call"}
                    onChange={() => handleCheckboxChange("option2")}
                  />
                  <div className="transition"></div>
                </label>{" "}
                <div>
                  <h6>{language == "ar" ? "اتصل بي" : "Call me"}</h6>
                </div>
              </div>
              <p>
                {language == "ar"
                  ? "سيتصل بك أحد ممثلي خدمة العملاء لإبلاغك و اختيار البديل."
                  : "A customer service representative will contact you to inform you and choose a replacement."}
              </p>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

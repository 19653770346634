import React from "react";
import "./homerecipes.css";
import UseGeneral from "../../../hooks/useGeneral";
import Recipe from "./Recipe/Recipe";
import { Link } from "react-router-dom";
const HomeRecipes = ({ recipes, recipe_category }) => {
  
  const { language } = UseGeneral();
  return (
    <>
      <div className="rowDiv tapTest" style={{ alignItems: "center" }}>
        <span>
          {language == "ar"
            ? recipe_category?.title_ar
            : recipe_category?.title_en}
        </span>
        <Link
          to={"/Product?q=999"}
          className="linkTitle centerTitle"
          style={{ fontWeight: "bold" }}
        >
          {language == "ar" ? "عرض الكل" : "View All"}
        </Link>
      </div>
      <div className="home_recipes rowDiv">
        <div className="recipre_home_content">
          <div className="recipes_cont">
            {recipes &&
              recipes.length > 0 &&
              recipes.map((item, index) => {
                if (index < 3) return <Recipe recipe={item} key={index} />;
              })}
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeRecipes;

import React, { useState } from 'react';
import UseGeneral from '../../../../hooks/useGeneral';
import './recipre.css';
import { createSearchParams, useNavigate } from 'react-router-dom';
const Recipe = ({ recipe }) => {
  const navigate = useNavigate();
  const { language } = UseGeneral();
  const [image, setImage] = useState(
    recipe?.images[0] && recipe?.images[0].url
  );
  return (
    <div
      onClick={() => {
        navigate({
          pathname: "/recipe",
          search: createSearchParams({ q: recipe?.id }).toString(),
        });
        // navigate('/Product',{state:{id}});
      }}
      key={recipe?.id}
      style={{
        background: `linear-gradient(rgba(7, 0, 0, 0.83), rgba(0, 0, 0, 0.3)),url(${image})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        margin: "auto",
      }}
      className="recipe"
      // onMouseOver={() => setImage(recipe?.images[1] && recipe?.images[1].url)}
      // onMouseLeave={() => setImage(recipe?.images[0] && recipe?.images[0].url)}
    >
      <h4 className="recipe_title">
        {language == 'ar' ? recipe.title_ar : recipe.title_en}
      </h4>
      <div className="description recipe_div recipes">
        <div className="allDescribe">
          <h4 className="">
            {language == 'ar' ? recipe.title_ar : recipe.title_en}
          </h4>

          {recipe?.description_ar || recipe?.description_en ? (
            <div className="rec_ingre">
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    language == 'ar'
                      ? recipe?.description_ar
                      : recipe?.description_en,
                }}
              ></p>
            </div>
          ) : null}
          {/* {recipe?.ingrediants_ar || recipe?.ingrediants_en ? (
            <div className="rec_ingre">
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    language == 'ar'
                      ? recipe?.ingrediants_ar
                      : recipe?.ingrediants_en,
                }}
              ></p>
            </div>
          ) : null} */}

          <div className="prepra_time">
            <p>
              {language == 'ar' ? "وقت التحضير" : "Prepare Time"} :
              {recipe?.time}  {language == 'ar' ? "دقيقة" : "Minite"}
            </p>

            <p>
              {language == 'ar' ? (
                <>تكفى {recipe?.number_for} أشخاص</>
              ) : (
                <>Enough {recipe?.number_for} persons</>
              )}
            </p>
          </div>
        </div>
        <button>{language == 'ar' ? 'الوصفه' : 'The Recipe'}</button>
      </div>
    </div>
  );
};

export default Recipe;

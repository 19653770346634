import React, { useState } from 'react';
import { addToCart, decreaseBTN } from '../../assets/svgIcons';
import "./style.css";
import { Axios } from '../../Axios';
import { BASE_URL } from '../../Axios/base_url';
import toast from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { fetchCartData } from '../../store/cartReducer';

const Options = ({ getItmData, item, options, language, setChoosedOptions, handleChangeQuan }) => {
  
  const dispatch=useDispatch();
  const [optionQuantities, setOptionQuantities] = useState({});

  const handleIncrease = (optionId) => {
    const updatedQuantities = { ...optionQuantities };
    updatedQuantities[optionId] = (updatedQuantities[optionId] || 0) + 1;
    setOptionQuantities(updatedQuantities);
    updateOptions(updatedQuantities);
  };

  const handleDecrease = (optionId) => {
    const updatedQuantities = { ...optionQuantities };
    if (updatedQuantities[optionId] > 0) {
      updatedQuantities[optionId] -= 1;
      setOptionQuantities(updatedQuantities);
      updateOptions(updatedQuantities);
    }
  };

  const updateOptions = (quantities) => {
    const updatedOptions = options.map((option) => ({
      ...option,
      quantity: quantities[option.id] || 0,
      totalPrice: (quantities[option.id] || 0) * option.price,
    }));
    setChoosedOptions(updatedOptions);
  };

  const handleChangeOptionQuan = (new_count, id) => {

    Axios({
      url: BASE_URL + `cart/change_option_count/${id}?new_count=${new_count}&cart_id=${item.cart_id}`,
      method: "POST",
    })
      .then((res) => {
        if (res.status == "success") {
          toast.success(res.message);
          getItmData()
          dispatch(fetchCartData());
        }
        else {
          toast.error(res.message)
        }
      })
      .finally(() => {
      });
  }

  return (
    <>
      {options && options?.map((optItem) => (
        <div className="container" key={optItem.id}>
          <div className="product-image">
            <img style={{ maxWidth: '100%' }} src={optItem?.product?.images && optItem.product?.images[0]?.url.includes('.') ? optItem?.product?.images[0]?.url : require("../../assets/images/no_image.png")} alt="Product" />
          </div>
          <div className="product-details">
            <h2>{language == 'ar' ? optItem.product && optItem.product.title_ar : optItem.product.title_en}</h2>
            <div className="price-section">
              <span className="new-price"> {optItem.price}{language=='ar'?'د.ك':'KWD'}</span>
            </div>
            <div className="buttons">
              <button onClick={() => {
                if (item.in_cart == 1) {
                  handleChangeOptionQuan(optItem.quantity * 1 + 1, optItem.id)
                }
                else {
                  handleChangeQuan(optItem.quantity + 1, optItem.id, 'plus')
                }
              }}>
                {addToCart}
              </button>
              <span>{optItem?.quantity || 0}</span>
              <button onClick={() => {
                if (item.in_cart == 1) {
                  if (optItem?.quantity != 0)
                    handleChangeOptionQuan(optItem.quantity * 1 - 1, optItem.id)
                }
                else {
                  if (optItem?.quantity != 0) {
                    handleChangeQuan(optItem.quantity - 1, optItem.id, 'minus')
                  }
                }
              }}>
                {decreaseBTN}
              </button>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default Options;

import React, { useEffect, useState } from "react";
import PayProducts from "./PayProducts";
import "./cancelOrder.css";
import "./style.css";
import { Phone, whatsApp } from "../../assets/svgIcons/index";

import { useLocation, useNavigate } from "react-router-dom";
import UseGeneral from "../../hooks/useGeneral";
import { Axios } from "../../Axios";
import { BASE_URL } from "../../Axios/base_url";
import ContentLoader from "react-content-loader";
import { ThreeDots } from "react-loader-spinner";
import CancelPayProducts from "./CancelPayProducts";
import PopUp from "../../components/popup";
import IconWithText from "../../components/iconWithText";
import toast from "react-hot-toast";
const CancelOrder = () => {
  const { language } = UseGeneral();
  const location = useLocation();
  const [contactOpen, setContactOpen] = useState(false);
  const [orderAgLoading, setOrderAgLoading] = useState(false);
  const [orderData, setOrderData] = useState({});
  const [pageLoading, setPageLoading] = useState(false);

  const navigate = useNavigate();
  if (!location?.state) {
    navigate("/profile");
  }
  const getOrderData = () => {
    setPageLoading(true);
    Axios({
      url:
        BASE_URL + `orders/get_order_by_id/${location?.state?.orderData?.id}`,
      method: "GET",
    })
      .then((res) => {
        if (res.status == 'success') {
          setOrderData(res.result);
        }
      })
      .finally(() => {
        setPageLoading(false);
      });
  };
  const reOrder = () => {
    toast.error(language == 'ar' ? 'متوقفه الأن' : 'Stoped Now');
    setOrderAgLoading(true);
    Axios({
      url: BASE_URL + `orders/re_order/${location?.state?.orderData?.id}`,
      method: "post",
    })
      .then((res) => {
        if (res.status == 'success') {
          // setOrderData(res.result)
          navigate("/order-following", {
            state: { id: location?.state?.order.id },
          });
        }
      })
      .finally(() => {
        setOrderAgLoading(false);
      })
      .catch((e) => e);
  };

  useEffect(() => {
    getOrderData();
  }, []);
  return pageLoading ? (
    <iframe
      width={350}
      height={400}
      style={{ margin: "auto" }}
      src="https://lottie.host/embed/0f5cbd90-b98b-4ef9-a1fe-6853af66f359/mzKPtDndKi.json"
    ></iframe>
  ) : (
    <div className="cancel-container">
      {" "}
      <div className="right">
        <h1>
          {language == 'ar'
            ? 'تم الغاء طلبك بنجاح'
            : 'Your order has been successfully cancelled'}
        </h1>
        <>
          <div className="info">
            <div className="d-column">
              <p>{language == 'ar' ? 'حالة الطلب' : 'Order Status'}</p>
              <strong>{language == 'ar' ? 'تم الالغاء' : 'Canceled'}</strong>
            </div>
            <div className="d-column">
              <p>{language == 'ar' ? 'الاجمالي' : 'Total'}</p>
              <strong>
                {orderData?.order_value} {language == 'ar' ? 'د.ك' : 'KWD'}
              </strong>
            </div>
            <div className="d-column">
              <p>{language == 'ar' ? 'عدد المشتريات' : 'Number Of Products'}</p>
              <strong>{orderData?.orderproducts?.length}</strong>
            </div>
            <div className="d-column">
              <p>{language == 'ar' ? 'رقم الهاتف' : 'Phone'}</p>
              <strong>{orderData?.phone}</strong>
            </div>
            <div className="d-column">
              <p>{language == 'ar' ? "مرجع العميل" : 'Customer Reference'}</p>
              <strong>{orderData?.id}</strong>
            </div>
            <div className="d-column">
              <p>{language == 'ar' ? "رقم الفاتورة" : "Invoice Number"}: </p>
              <strong>
                <span>{orderData?.invoiceId}</span>
              </strong>
            </div>
           
          </div>
          <div className="re_ord_cont">
            <p
              onClick={() => {
                setContactOpen(true);
              }}
            >
              {language == 'ar'
                ? 'التواصل مع خدمة العملاء'
                : 'Contact customer service'}
            </p>
            {orderAgLoading ? (
              <ThreeDots color="red" />
            ) : (
              <span
                className="order_ag"
                onClick={() => {
                  reOrder();
                }}
              >
                {language == 'ar' ? 'إعادة الطلب' : 'Order It Again'}
              </span>
            )}
          </div>
        </>
        {/* <button oncClick={()=>{
          navigate('/profile',{replace:true})
        }} style={{padding:'10px',borderRadius:'4px',backgroundColor:'#553a30',color:'white'}}>{language=='ar'?'رجوع':"Go Back"}</button> */}
      </div>
      <div className="calcel-products ">
        <div className="left-container">
          {/* <PayProducts
            orderData={orderData}
            dividerStyle={{ background: "white" }}
          /> */}
          <CancelPayProducts products={orderData?.orderproducts} />
        </div>
      </div>
      <PopUp title="الغاء الطلب" open={contactOpen} setOpen={setContactOpen}>
        <h3></h3>
        <div className="rowDiv">
          <IconWithText
            icon={Phone}
            text={language == "ar" ? "الخط الساخن" : "hot line"}
            className={"contact"}
          />
        </div>
        <div className="rowDiv">
          <IconWithText
            icon={whatsApp}
            text={language == "ar" ? "واتساب" : "WhatsApp"}
            className={"contact"}
          />
        </div>
      </PopUp>
    </div>
  );
};

export default CancelOrder;

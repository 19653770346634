import React, { useState } from "react";
import Collapse from "react-collapse";
import "./question.css";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";

const QnADropdown = ({ question, answer, id }) => {
  const [isOpen, setIsOpen] = useState(0);
  return (
    <div
      className="question_container"
      onClick={() => setIsOpen(id == isOpen ? "false" : id)}
    >
      <div style={{}}>
        <div className="question">
          <div className="icon" onClick={() => setIsOpen(!isOpen)}>
            {/* {id == isOpen ? <IoIosArrowUp /> : <IoIosArrowDown />} */}
            <p className={id != isOpen ? "plus" : "plus minus"}></p>
          </div>
          <strong>{question}</strong>{" "}
        </div>

        <div className={isOpen != id ? "answer" : "answer answerOpen"}>
          {answer}
        </div>
      </div>{" "}
    </div>
  );
};

export default QnADropdown;

import { useDispatch, useSelector } from "react-redux";
import { change } from "../store/languageReducer";
import { useEffect, useState } from "react";

const UseGeneral = () => {
  const language = useSelector((state) => state?.language?.language);
  const dispatch = useDispatch();
  const [openMenuList, setOpenMenuList] = useState(false)
  const changeLanguage = (payload) => {
    dispatch(change(payload));
  };
  const handleChangeit= ()=>{
    alert("kgf")
    setOpenMenuList(!openMenuList)
  }
  useEffect(() => {
    if (language.toLowerCase() == "ar") {
      document.body.classList.add("arVersion");
      document.body.classList.remove("enVersion");
    } else {
      document.body.classList.add("enVersion");
      document.body.classList.remove("arVersion");
    }
  }, [language]);
  return {
    language: language?.toLowerCase(),
    changeLanguage: changeLanguage,
    openMenuList, 
    setOpenMenuList,
    handleChangeit
  };
};

export default UseGeneral;

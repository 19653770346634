import React, { useEffect, useState } from "react";
import "./style.css";
import UseGeneral from "../../hooks/useGeneral";
import toast from "react-hot-toast";
import PopUp from "../../components/popup";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "../../components/BreadCumbsLinks";
import { uploadImage } from "./uploadImage";
import axios from "axios";
import { BASE_URL } from "../../Axios/base_url";
import { Loader } from "rsuite";

const Custom = () => {
  const { language } = UseGeneral();
  const navigate = useNavigate();
  const [showUploadImage, setShowUploadImage] = useState(null);
  const [loading, setLoading] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const breadCrumbsLinks = [
    { name: language !== "ar" ? "Home" : "الصفحة الرئيسية", path: "/" },
    {
      name: language !== "ar" ? "Custom" : "مخصص",
      path: "/custom",
      active: true,
    },
  ];

  const handleUpload = async (e) => {
    e.preventDefault();
    setLoading(true);
    const imageFile = e.target.imageUpload.files[0];
    const priceComment = e.target.comment.value;
    let image = null;
    if (imageFile) {
      image = await uploadImage(imageFile);
    }

    if (!localStorage.getItem("profileData")) {
      toast.error(
        language === "ar" ? "برجاء تسجيل الدخول أولا" : "Sign In First"
      );
    }
    try {
      const profileData = JSON.parse(localStorage.getItem("profileData"));
      axios
        .post(BASE_URL + "cusome_order/add_new", {
          user_id: profileData?.id,
          image: image,
          comment: priceComment,
        })
        .then((res) => {
          if (res?.data?.status == "success") {
            toast.success(
              language === "ar"
                ? "تم إرسال البيانات بنجاح"
                : "Data Sent Successfully"
            );
            getData();
          } else {
            toast.error(res?.data?.message);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (err) {
      toast.error(language === "ar" ? "حدث خطأ ما" : "Something Went Wrong");
    }
    // toast.success(
    //   language === "ar" ? "تم إرسال البيانات بنجاح" : "Data Sent Successfully"
    // );
  };

  const [testImages, setTestImages] = useState(null);

  const [images, setImages] = useState();
  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if (testImages && testImages?.length) {
      setImages(testImages?.map((item) => item?.image));
    }
  }, [testImages]);
  const getData = async () => {
    if (!localStorage.getItem("profileData")) {
      toast.error(
        language === "ar" ? "برجاء تسجيل الدخول أولا" : "Sign In First"
      );
    }
    try {
      const profileData = JSON.parse(localStorage.getItem("profileData"));
      const data = await axios.post(BASE_URL + "cusome_order/user_orders", {
        user_id: profileData?.id,
      });
      console.log(data);
      setTestImages(data?.data?.result);
    } catch (err) {
      setTestImages([]);
      toast.error(language === "ar" ? "حدث خطأ ما" : "Something Went Wrong");
    }
  };
  return (
    <div
      className="categoriesLPage customRowDiv"
      style={{ margin: "auto", flexDirection: "column", width: "80%" }}
    >
      <Breadcrumb
        links={breadCrumbsLinks}
        style={{
          margin: "10px 0 !important",
        }}
      />
      <div
        className="custom"
        style={{
          marginLeft: "auto",
        }}
      >
        <span
          onClick={() => {
            setShowUploadImage(true);
          }}
        >
          <div
            className="customorderpage"
            style={{
              display: "flex",
              marginLeft: "auto",
              alignItems: "center",
              gap: "6px",
              padding: "10px 15px ",
              cursor: "pointer",
            }}
          >
            <img
              src="https://res.cloudinary.com/duovxefh6/image/upload/v1725295252/image-_3_yluqlh.png"
              alt=""
            />
            <span>{language === "ar" ? "رفع صورة" : "Upload Image"}</span>
          </div>
        </span>
      </div>

      <PopUp
        title={language === "ar" ? "رفع صورة" : "Upload Image"}
        open={showUploadImage}
        setOpen={setShowUploadImage}
      >
        <form
          className="upload-form"
          onSubmit={!loading ? handleUpload : (e) => e.preventDefault()}
        >
          <div className="form-group">
            <label htmlFor="imageUpload" className="form-label">
              {language === "ar" ? "صورة" : "Image"}
            </label>
            <input type="file" id="imageUpload" className="form-control" />
          </div>
          <div className="form-group">
            <label htmlFor="comment" className="form-label">
              {language === "ar" ? "التعليق" : "Comment"}
            </label>
            <textarea id="comment" className="form-control" rows="4"></textarea>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <button type="submit" className="btn btn-primary">
              {language === "ar" ? "إرسال" : "Submit"}
            </button>
          )}
        </form>
      </PopUp>

      {/* Display images with animated cards */}
      <div className="image-gallery" style={{ width: "100%" }}>
        {!testImages ? (
          <Loader size="md" />
        ) : testImages?.length ? (
          testImages?.map((item, index) => (
            <div
              key={index}
              className="animated-card"
              onClick={() => {
                setIsOpen(true);
                setPhotoIndex(index);
              }}
            >
              <img src={item?.image} alt={`Uploaded ${index + 1}`} />
              <div className="card-overlay">
                <p>
                  {language == "ar" ? "السعر" : "Price"} :{" "}
                  {item.price
                    ? item.price
                    : language == "ar"
                    ? "مازالت تحت المراجعة"
                    : "Still In Pending"}
                </p>
                <p>
                  {language == "ar" ? "تعليقك" : "Your Comment"} :{" "}
                  {item.comment ? item.comment : null}
                </p>
                <p>
                  {language == "ar" ? "ملاحظة المسؤول" : "Admin Notes"} :{" "}
                  {item.note
                    ? item.note
                    : language == "ar"
                    ? "مازالت تحت المراجعة"
                    : "Still In Pending"}
                </p>
              </div>
            </div>
          ))
        ) : null}
      </div>
      {testImages && !testImages?.length ? (
        <h1>
          {language == "ar"
            ? "لم تقدم أي طلب حتى الآن"
            : "You do not Apply Any Order Until Now"}
        </h1>
      ) : null}
      {/* Custom Modal for viewing images */}
      {isOpen && (
        <div className="modal">
          <span className="close" onClick={() => setIsOpen(false)}>
            &times;
          </span>
          <div className="modal-content">
            <button
              className="prev"
              onClick={() =>
                setPhotoIndex((photoIndex + images.length - 1) % images.length)
              }
            >
              &#10094;
            </button>
            <img src={images[photoIndex]} alt="Displayed" />
            <button
              className="next"
              onClick={() => setPhotoIndex((photoIndex + 1) % images.length)}
            >
              &#10095;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Custom;

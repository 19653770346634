import React, { useEffect, useState } from "react";
import { FiEdit } from "react-icons/fi";
import AddLocation from "./AddLocation";
import "./location.css";
import { Axios } from "../../../Axios";

import { BASE_URL } from "../../../Axios/base_url";
import { ThreeCircles, ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import UseGeneral from "../../../hooks/useGeneral";
import { Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import ContentLoader from "react-content-loader";
const Location = ({ userData }) => {
  const [isAddingNewLocation, setIsAddingNewLocation] = useState(false);
  const [data, setData] = useState({});
  const [pageLoading, setPageLoading] = useState(false);
  const [mainLocation, setMainLocation] = useState({});
  const [activeLoc, setActiveLoc] = useState("");
  const navigate = useNavigate();
  const { language } = UseGeneral();

  useEffect(() => {
    setData(userData);
  }, [userData]);
  const [locations, setLocations] = useState(null);
  const handleAddComponent = () => {
    setIsAddingNewLocation(true);
  };

  const getAllUserLocations = () => {
    if (
      localStorage.getItem("greenTreesHomePage") &&
      localStorage.getItem("greenTreesHomePage") != null &&
      localStorage.getItem("greenTreesHomePage") != "undefined" &&
      localStorage.getItem("greenTreesHomePage") != undefined
    ) {
      try {
        const data = JSON.parse(
          localStorage.getItem("greenTreesAddressesData")
        );
        setLocations((prev) => data);
        const theMain =
          data && data?.length && data?.find((item) => item?.status == "1");
        setMainLocation((prev) => theMain);
        setActiveLoc((prev) => theMain?.id);
      } catch (e) {
        
      }
    }
    setPageLoading(true);

    Axios({
      url: BASE_URL + `userlocation/get_user_locations`,
      method: "GET",
    })
      .then((res) => {
        if (res?.status == "success") {
          setLocations((prev) => res?.result);
          const theMain =
            res?.result &&
            res?.result?.length &&
            res?.result?.find((item) => item?.status == "1");
          setMainLocation((prev) => theMain);
          setActiveLoc((prev) => theMain?.id);
          localStorage.setItem(
            "greenTreesAddressesData",
            JSON.stringify(res?.result)
          );
          setPageLoading(false);
        }
      })
      .finally(() => {
        setPageLoading(false);
      });
  };

  const openEditPage = (location) => {
    navigate("/EditLocation", { state: { locationnData: location } });
  };

  const handleMakeLoActive = (id) => {
    setLocations(
      locations.map((item) => {
        return { ...item, loading: item.id == id ? true : false };
      })
    );
    Axios({
      url: BASE_URL + `userlocation/update_location_status/${id}`,
      method: "GET",
    })
      .then((res) => {
        if (res?.status == "success") {
          getAllUserLocations();
          toast.success(res.message);
        }
      })
      .finally(() => {
        setLocations(
          locations.map((item) => {
            return { ...item, loading: false };
          })
        );
      });
  };

  useEffect(() => {
    getAllUserLocations();
  }, []);

  return (
    <div className="location-container">
      {isAddingNewLocation ? (
        <div>
          <AddLocation />
        </div>
      ) : (
        <div className="location-list">
          <div className="locations">
            {!locations ? (
              <iframe
              width={350}
              height={400}
              style={{ margin: "auto" }}
              src="https://lottie.host/embed/0f5cbd90-b98b-4ef9-a1fe-6853af66f359/mzKPtDndKi.json"
            ></iframe>
            ) : Array.isArray(locations) && locations?.length && locations ? (
              <>
                {" "}
                <div className="mainLocation">
                  <div className="row1">
                    <h1>
                      {language == "ar" ? "العنوان الرئيسي" : "Main address"}
                    </h1>
                    <div className="icon-container">
                      <FiEdit onClick={() => openEditPage(mainLocation)} />
                    </div>
                  </div>
                  <div className="details">
                    <h3>
                      {mainLocation?.special_marque
                        ? userData?.location?.location +
                          "-" +
                          userData?.location?.streat
                        : language == "ar"
                        ? "لا يوجد عنوان"
                        : "No location"}
                    </h3>
                  </div>
                </div>
                {locations.map((loc, index) => (
                  <div className="location" key={index}>
                    <div className="row1">
                      {loc.loading ? (
                        <Spinner />
                      ) : (
                        <div
                          onClick={() => {
                            handleMakeLoActive(loc?.id);
                          }}
                          className="loc_par"
                        >
                          <div
                            className={
                              activeLoc == loc?.id
                                ? "loc_chil active"
                                : "loc_chil"
                            }
                          ></div>
                        </div>
                      )}
                      <h3>{loc?.location}</h3>
                      <div className="icon-container">
                        <FiEdit onClick={() => openEditPage(loc)} />
                      </div>
                    </div>
                    <div className="details">
                      <h5>{loc?.special_marque}</h5>
                    </div>
                  </div>
                ))}
              </>
            ) : null}
          </div>
          <button className="add-location-button" onClick={handleAddComponent}>
            {language == "ar" ? "اضافة عنوان جديد" : "add location"}
          </button>
        </div>
      )}
    </div>
  );
};

export default Location;
import React, { useState } from "react";
import { Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import { Axios } from "../../Axios";
import { BASE_URL } from "../../Axios/base_url";
import { addToCart, removeFromCart } from "../../assets/svgIcons";
import UseCartFavourite from "../../hooks/useCartFavourite";
import UseGeneral from "../../hooks/useGeneral";
import PopUp from "../popup";
import ProductInCartMangeButton from "../productInCartMangeButton/productInCartMangeButton";
import "./style.css";
import { BsTrash } from "react-icons/bs";
import { fetchCartData } from "../../store/cartReducer";
import { useDispatch } from "react-redux";
import { ThreeDots } from "react-loader-spinner";
import { removeItem } from "../../store/offlineCartReducer/OfflineCartReducer";
const ProductInCart = ({
  item,
  id,
  color,
  image,
  title,
  price,
  quantity,
  updateData,
}) => {
  const [weight, setWeight] = useState({});
  const [showEditWeight, setShowEditWeight] = useState(false);
  const [weights, setWeights] = useState([]);
  const [weightLoading, setWeightLoading] = useState(false);
  const { isItemInCart, addItemToCart, removeItemFromCart, update } =
    UseCartFavourite();
    
    const dispatch=useDispatch();
  const { language } = UseGeneral();
  const [delLoading, setDelLoading] = useState(false);
  const handleUpdate2 = () => {
    setWeightLoading(true);
    const data_send = {
      cart_id: item.cart_id,
      id: item.product_id,
      weight: weight.weight,
      price: weight.price,
    };
    Axios({
      url: BASE_URL + "cart/update_weight",
      method: "POST",
      data: data_send,
    })
      .then((res) => {
        if (res.status == "success") {
          updateData();
          dispatch(fetchCartData());
        }
      })
      .finally(() => {
        setWeightLoading(false);
      });
  };
  const handleDelFromCart = (id) => {
    // 
    // return ;
    setDelLoading(true);
    const data_send = {
      product_id: id,
      product_count: 1,
      has_option: 0,
    };
    Axios({
      url: BASE_URL + `cart/del_cart/${item.id}`,
      method: "GET",
    })
      .then((res) => {
        
        if (res.status == "success") {
          toast.success(res.message);
          dispatch(fetchCartData());
          updateData();
        }
      })
      .finally(() => {
        setDelLoading(false);
      });
  };
  const updateData2 = () => {
    updateData();
  };
  return (
    <div
      className="category productInCart columnDiv"

    >
      <div className="cart_pro_img">
        <img
          src={
            image?.includes(".")
              ? image
              : require("../../assets/images/no_image.png")
          }
          alt=""
        />
      </div>
      <div className="productDetails">
        <h4>
          {language == "ar" ? item?.title_ar : item?.title_en}
        </h4>
        {/* <p>
          {language == "ar"
            ? item?.product?.description_ar
            : item?.product?.description_en}
        </p> */}
        <div className="priceWithDiscount">
          {item?.cart_product && item?.cart_product.discount != "0.00" ? (
            <del className="priceWithoutDiscount">
              <span>{price?.toFixed(3)}</span> {language=='ar'?'د.ك':'KWD'}
            </del>
          ) : null}
          {/* <del className="priceWithoutDiscount">{price} AED</del> */}
          <h6>
            <span>{price?.toFixed(3)}</span> <sub>{language=='ar'?'د.ك':'KWD'}</sub>
          </h6>
        </div>
          <div   onClick={() => {
            if(delLoading){
              toast.error(language=='ar'?'قيد التنفيذ':'Underway')
            }
            dispatch(removeItem({itemIdToRemove:item.uiid}));
          // updateData()
          return
          // handleDelFromCart(item?.id);
        }} className="del_cart">
            {
              delLoading?
              <ThreeDots color="red"/>
              :
              <>
                <BsTrash />
            <span>{language=='ar'?'حذف':'Delete'}</span>
              </>
            }
          </div>
        {item?.weight&&item.weight!=null ? (
          <div className="weight_cart">
            {item?.product?.productweights &&
              item?.product?.productweights.length > 0 &&
              language == "ar" ? (
              <p style={{ fontSize: "30px", margin: "0px" }}>
                الوزن:{item.weight}KG
              </p>
            ) : (
              <p style={{ fontSize: "30px", margin: "0px" }}>
                {language=='ar'?'الوزن':'Weight'}:{item.weight}KG
              </p>
            )}
            {/* <button
              onClick={() => {
                setShowEditWeight(true);
                setWeights(
                  item.product?.productweights &&
                  item.product?.productweights.map((weiitem) => {
                    return {
                      ...weiitem,
                      checked: weiitem.weight == item.weight,
                    };
                  })
                );
              }}
              className="btn btn-primary"
            >
              {language == "ar" ? "تغيير" : "Change"}
            </button> */}
          </div>
        ) : null}
      </div>


      {/* <div
        className={
          false
            ? "productBtn cartBtn"
            : "productBtn cartBtn in_cart_del removeBtn"
        }
        onClick={() => {
          handleDelFromCart(item?.product?.id);
        }}
      >
        {delLoading ?
          <Spinner />
          : <> {false ? addToCart : removeFromCart}</>}
      </div> */}


      <ProductInCartMangeButton
        updateCart={updateData2}
        delCartItem={handleDelFromCart}
        item={item}
        update={update}
        itemIdToIncrement={id}
        quantity={quantity}
      />

      <PopUp
        open={showEditWeight}
        setOpen={setShowEditWeight}
        title={language == "ar" ? "تغيير الوزن" : "Change Weight"}
      >
        <div className="weights">
          {weights &&
            weights.map((weightItem, weightIndex) => {
              return (
                <div>
                  <p>{weightItem.price}EGP</p>
                  <div
                    onClick={() => {
                      setWeight(weightItem);
                      setWeights(
                        weights.map((item) => {
                          return {
                            ...item,
                            checked: item.id == weightItem.id ? true : false,
                          };
                        })
                      );
                    }}
                  >
                    <div className="parent">
                      <div
                        onClick={() => {
                          setWeight(weightItem);
                          setWeights(
                            weights.map((item) => {
                              return {
                                ...item,
                                checked:
                                  item.id == weightItem.id ? true : false,
                              };
                            })
                          );
                        }}
                        className={
                          weightItem.checked ? "children active" : "children"
                        }
                      ></div>
                    </div>
                    <p>{weightItem.weight} KG</p>
                  </div>
                </div>
              );
            })}
        </div>
        {weightLoading ? (
          <Spinner />
        ) : (
          <button
            className="btn btn-success"
            onClick={() => {
              handleUpdate2();
            }}
          >
            {language == "ar" ? "تحديث" : "Update"}
          </button>
        )}
      </PopUp>
    </div>
  );
};

export default ProductInCart;

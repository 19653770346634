import React, { useEffect, useState } from "react";
import IconWithText from "../../iconWithText";
import "./style.css";
import {
  EnglishIcon,
  KSA,
  Phone,
  Phonew,
  Profile,
  whatsApp,
  whatsAppW,
} from "../../../assets/svgIcons";
import UseGeneral from "../../../hooks/useGeneral";
import PopUp from "../../popup";
import { useSelector } from "react-redux";
import { Axios } from "../../../Axios";
import { BASE_URL } from "../../../Axios/base_url";
import HeaderIcons from "../headerIcons";
import { Dropdown } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
const LeftMiddleHeader = ({
  setShowSearchModal,
  reverse,
  mobile,
  setMenuOpen,
  menuOpen,
}) => {
  const navigate = useNavigate();
  const localCartLength = useSelector((state) => state.offlineCart);
  const { language, changeLanguage } = UseGeneral();
  const [open, setOpen] = useState(false);
  const [siteInfoData, setSiteInfoData] = useState(false);
  const getSideInfoData = () => {
    Axios({
      url: BASE_URL + "site_info/get_all_for_user",
      method: "GET",
    })
      .then((res) => {
        if (res.status == "success") {
          setSiteInfoData(res?.result);
        }
      })
      .finally(() => {});
  };

  useEffect(() => {
    getSideInfoData();
  }, []);
  return (
    <div
      className="LeftMiddleHeader"
      style={
        language == "ar" ? { marginRight: "auto" } : { marginLeft: "auto" }
      }
    >
      <HeaderIcons
        setShowSearchModal={setShowSearchModal}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
      />

      {!mobile ? (
        <IconWithText icon={Phone} text={""} onClick={() => setOpen(true)} />
      ) : null}

      {!localStorage?.getItem("GreenTreesToken") ? (
        <Dropdown className="dropMenuProfile">
          <Dropdown.Toggle
            style={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              cursor: "pointer",
              border: "none",
              padding: 0,
            }}
            variant=""
            id="dropdown-basic"
          >
            <span
              onClick={() =>
                localStorage?.getItem("GreenTreesToken")
                  ? navigate("/profile")
                  : null
              }
            >
              <span>
                <div className={"profileIconLarge"}> {Profile}</div>
                <div className={"profileIconSmall"}>
                  {" "}
                  {
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="22"
                      viewBox="0 0 18 22"
                      fill="none"
                    >
                      <path
                        d="M8.57903 11.056C11.2178 11.056 13.357 8.91682 13.357 6.278C13.357 3.63918 11.2178 1.5 8.57903 1.5C5.94021 1.5 3.80103 3.63918 3.80103 6.278C3.80103 8.91682 5.94021 11.056 8.57903 11.056Z"
                        stroke="#9D071A"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M1.00002 17.7016C0.998748 17.3657 1.07387 17.0339 1.21971 16.7313C1.67738 15.816 2.968 15.3308 4.03893 15.1112C4.81129 14.9463 5.59432 14.8362 6.38218 14.7816C7.84085 14.6535 9.30795 14.6535 10.7666 14.7816C11.5544 14.8369 12.3374 14.947 13.1099 15.1112C14.1808 15.3308 15.4714 15.7702 15.9291 16.7313C16.2224 17.3481 16.2224 18.0642 15.9291 18.681C15.4714 19.6421 14.1808 20.0814 13.1099 20.2919C12.3384 20.4636 11.5551 20.5768 10.7666 20.6306C9.57938 20.7313 8.3866 20.7496 7.19683 20.6855C6.92223 20.6855 6.65678 20.6855 6.38218 20.6306C5.59665 20.5775 4.81633 20.4642 4.04809 20.2919C2.968 20.0814 1.68653 19.6421 1.21971 18.681C1.07461 18.3749 0.999568 18.0403 1.00002 17.7016Z"
                        stroke="#9D071A"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  }
                </div>
              </span>
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu
            style={{
              // height: "50vh",
              overflowY: "auto",
              width: "400px",
            }}
          >
            <Dropdown.Item
              style={{
                display: "flex",
                gap: "10px",
                fontSize: "16px",
                marginBottom: "15px",
                alignItems: "center",
                fontWeight: "bold",
                color: "red",
              }}
              onClick={() =>
                navigate(
                  localStorage?.getItem("GreenTreesToken")
                    ? "/profile"
                    : "/login"
                )
              }
            >
              <img
                src="https://res.cloudinary.com/duovxefh6/image/upload/v1713715032/Lock_k1cc98.png"
                width={30}
              />{" "}
              <span> {language == "ar" ? "تسجيل الدخول " : "Sign In"} </span>
            </Dropdown.Item>
            <Dropdown.Item
              style={{
                display: "flex",
                gap: "10px",
                fontSize: "16px",
                // marginBottom: "15px",
                alignItems: "center",
                fontWeight: "bold",
                color: "red",
              }}
              onClick={() =>
                navigate(
                  localStorage?.getItem("GreenTreesToken")
                    ? "/profile"
                    : "/sign"
                )
              }
            >
              <img
                src="https://res.cloudinary.com/duovxefh6/image/upload/v1713715032/profile_iylauu.png"
                width={30}
              />{" "}
              <span> {language == "ar" ? "حساب جديد " : "New Account"} </span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      ) : (
      
        <span
          onClick={() =>
            localStorage?.getItem("GreenTreesToken")
              ? navigate("/profile")
              : null
          }
        >
          <div className={"profileIconLarge"}> {Profile}</div>
          <div className={"profileIconSmall"}>
            {" "}
            {
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="22"
                viewBox="0 0 18 22"
                fill="none"
              >
                <path
                  d="M8.57903 11.056C11.2178 11.056 13.357 8.91682 13.357 6.278C13.357 3.63918 11.2178 1.5 8.57903 1.5C5.94021 1.5 3.80103 3.63918 3.80103 6.278C3.80103 8.91682 5.94021 11.056 8.57903 11.056Z"
                  stroke="#9D071A"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.00002 17.7016C0.998748 17.3657 1.07387 17.0339 1.21971 16.7313C1.67738 15.816 2.968 15.3308 4.03893 15.1112C4.81129 14.9463 5.59432 14.8362 6.38218 14.7816C7.84085 14.6535 9.30795 14.6535 10.7666 14.7816C11.5544 14.8369 12.3374 14.947 13.1099 15.1112C14.1808 15.3308 15.4714 15.7702 15.9291 16.7313C16.2224 17.3481 16.2224 18.0642 15.9291 18.681C15.4714 19.6421 14.1808 20.0814 13.1099 20.2919C12.3384 20.4636 11.5551 20.5768 10.7666 20.6306C9.57938 20.7313 8.3866 20.7496 7.19683 20.6855C6.92223 20.6855 6.65678 20.6855 6.38218 20.6306C5.59665 20.5775 4.81633 20.4642 4.04809 20.2919C2.968 20.0814 1.68653 19.6421 1.21971 18.681C1.07461 18.3749 0.999568 18.0403 1.00002 17.7016Z"
                  stroke="#9D071A"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            }
          </div>
        </span>
      )}

      <PopUp
        open={open}
        setOpen={setOpen}
        title={language == "ar" ? "تواصل معنا" : "Contact Us"}
      >
        <div className="rowDiv">
          <IconWithText
            onClick={() => window.open("tel:" + siteInfoData?.mobile)}
            icon={Phonew}
            text={language == "ar" ? "الخط الساخن" : "hot line"}
            className={"contact"}
          />
        </div>
        <div className="rowDiv">
          <IconWithText
            icon={whatsAppW}
            onClick={() =>
              window.open("https://wa.me/" + siteInfoData?.whats_number)
            }
            text={language == "ar" ? "واتساب" : "WhatsApp"}
            className={"contact"}
          />
        </div>
      </PopUp>
      {language?.toLowerCase() == "ar" ? (
        <IconWithText
          icon={EnglishIcon}
          reverse={true}
          pointer={true}
          text={mobile ? null : "EN"}
          onClick={() => changeLanguage("en")}
        />
      ) : (
        <IconWithText
          icon={KSA}
          reverse={true}
          pointer={true}
          text={mobile ? null : "العربية"}
          onClick={() => changeLanguage("ar")}
        />
      )}
    </div>
  );
};

export default LeftMiddleHeader;

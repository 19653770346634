import React from "react";
import { Autoplay, EffectCube, FreeMode, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import UseGeneral from "../../../hooks/useGeneral";
import "./style.css";
const Banners = ({ banners }) => {
  const { language } = UseGeneral();
  return (
    <div className="rowDiv bannerDiv">
      <Swiper
        spaceBetween={30}
        slidesPerView={1}
        // navigation={true}
        effect={"cube"}
        modules={[EffectCube, FreeMode, Pagination]}
        pagination={true}
        // pagination={{ clickable: true }}
        // autoplay={{ delay: 5000 }}
        loop={true}
        dir={language != "en" ? "rtl" : "ltr"}
        breakPoints ={{
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          // when window width is >= 768px
          768: {
            slidesPerView: 1,
            spaceBetween: 40,
          },
        }}
      >
        {banners?.map((item, index) => {
          return (
            <SwiperSlide key={index}>
              <div
                className="banner"
                style={{
                  background: `url(${item?.background_image})`,
                  cursor: "pointer",
                }}
                onClick={() => {
                  window.location.href = item.link;
                }}
              >
                {/* <button
                  className="customBannerButton"
                  onClick={()=>{
                    window.open(item.link)
                  }}
                  style={{
                    color: `${item?.text_color}`,
                    backgroundColor: `${item?.btn_bg_color}`,
                  }}
                >
                  {language == "ar" ? item?.button_name : item?.button_name_ar}
                </button> */}
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default Banners;

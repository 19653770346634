import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation, useSearchParams } from 'react-router-dom';
import { BASE_URL } from '../../Axios/base_url';
import UseGeneral from '../../hooks/useGeneral';
import './shippingdel.css'
import ContentLoader from 'react-content-loader';
import Skeleton from 'react-loading-skeleton';
import Brands from '../../layouts/home/brands';
const ShippingDel = () => {
  const { language } = UseGeneral();
  const location = useLocation();
  
  const [data,setData]=useState("");
  const [brands,setBrands]=useState([]);
  const [brandLoading,setBrandLoading]=useState(false)
  const [pageLoading,setPageLoading]=useState(true);
  useEffect(() => {
    document.title =
      language == "ar" ? "فن النحت | سياسة الخصوصية" : "Sculpture ART | Policy and Privacy";
  }, []);
  const getSiteInfo=()=>{
    axios.get(BASE_URL+'site_info/get_all_for_user')
    .then((res)=>{
      if(res.data.status=='success'){
        
        if(location.pathname=='/ship_del'){
          setData(language=='ar'?res?.data?.result?.ship_del_ar:res?.data?.result?.ship_del_en)
        }
        else if(location?.pathname=='/ret_poli'){
          setData(language=='ar'?res?.data?.result?.return_poli_ar:res?.data?.result?.return_poli_en)
        }
        else if(location?.pathname=='/privacy_policy'){
          setData(language=='ar'?res?.data?.result?.privacy_ar:res?.data?.result?.privacy_en)
        }
        else if(location?.pathname=='/conditions'){
          setData(language=='ar'?res?.data?.result?.conditions_ar:res?.data?.result?.conditions_en)
        }
      }
    }).catch(e=>e)
    .finally(()=>{
      setPageLoading(false)
    })
  }
  const getBrands=()=>{
    setBrandLoading(true)
    axios.get(BASE_URL+'brands/get_all')
    .then((res)=>{
      setBrands(res.data.result)
    }).catch(e=>e)
    .finally(()=>{
    setBrandLoading(false)
    })
  }
  useEffect(()=>{
    getSiteInfo()
  },[location.pathname,language])
  useEffect(()=>{
    getBrands()
  },[])
  return (
    <div className='shipping_del'>
      {
        pageLoading?
        <iframe
        width={350}
        height={400}
        style={{ margin: "auto" }}
        src="https://lottie.host/embed/0f5cbd90-b98b-4ef9-a1fe-6853af66f359/mzKPtDndKi.json"
      ></iframe>
        :
        <>
          <div dangerouslySetInnerHTML={{ __html:data }}></div>
        </>
      }
    </div>
  )
}

export default ShippingDel

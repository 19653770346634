import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import "./addLocation.css";
import { Axios } from "../../../Axios";
import { BASE_URL } from "../../../Axios/base_url";
import toast from "react-hot-toast";
import UseGeneral from "../../../hooks/useGeneral";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

const AddLocation = ({ getAgainData, first }) => {
  const { language } = UseGeneral();
  const [regions, setRegions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState("");
  const [addLoading, setAddLoading] = useState(false);
  const [selectedDis, setSelectedDis] = useState("");
  const [districts, setDistricts] = useState([]);
  const navigate = useNavigate();
  const [newLoc, setNewLoc] = useState({
    name: "",
    phone: "",
    city: "",
    streat: "",
    architecture: "",
    floor: "",
    apartment: "",
    special_marque: "",
    location: "",
    status: first ? 1 : 0,
  });
  const getRegions = () => {
    Axios({
      url: BASE_URL + "regions/get_all_for_user",
      method: "GET",
    })
      .then((res) => {
        if (res.status == "success") {
          setRegions(res.result);
          setSelectedRegion(res.result[0].id);
        }
      })
      .catch((e) => {})
      .finally(() => {
        setAddLoading(false);
      });
  };
  const addNewLocation = () => {
    // if (selectedRegion == "") {
    //   toast.error(language == "ar" ? "أدخل المنطقه" : "Enter Region");
    //   return
    // }
    // if (selectedDis == "") {
    //   toast.error(language == "ar" ? "أدخل الحى" : "Enter District");
    //   return
    // }
    setAddLoading(true);
    const data_send = {
      ...newLoc,
      region: selectedRegion,
      district: selectedDis,
    };
    Axios({
      url: BASE_URL + "userlocation/add_new_location",
      method: "POST",
      data: data_send,
    })
      .then((res) => {
        if (res.status == "success") {
          toast.success(res.message);
          if (getAgainData) {
            getAgainData();
          } else {
            navigate(-1);
          }
        }
      })
      .catch((e) => {})
      .finally(() => {
        setAddLoading(false);
      });
  };
  const getRegionDist = () => {
    const data_send = {
      region_id: selectedRegion,
    };

    Axios({
      url: BASE_URL + "districts/get_all_for_user",
      method: "POST",
      data: data_send,
    })
      .then((res) => {
        if (res.status == "success") {
          setDistricts(res.result);
          setSelectedDis(res.result[0].id);
        }
      })
      .catch((e) => {})
      .finally(() => {
        setAddLoading(false);
      });
  };

  useEffect(() => {
    getRegionDist();
  }, [selectedRegion]);
  useEffect(() => {
    getRegions();
  }, []);
  return (
    <div className="add-location-container">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          addNewLocation();
        }}
        className="add_loc_form"
      >
        <h3>{language == 'ar' ? 'إضافة عنوان جديد' : "Add New Location"}</h3>
        {!first ? (
          <>
            {" "}
            <div className="input-form">
              <label htmlFor="">
                {language == 'ar' ? 'الاسم ثنائي' : 'Name'}
                <span className="star">*</span>
              </label>
              <input
                type="text"
                placeholder={
                  language == 'ar' ? "ادخل اسمك الثنائي" : 'Enter You Name'
                }
                name="name"
                onChange={(e) => {
                  setNewLoc({ ...newLoc, name: e.target.value });
                }}
              />
            </div>
            <div className="input-form">
              <label htmlFor="">
                {language == 'ar' ? 'رقم الموبيل' : 'Phone Number'}
                <span className="star">*</span>
              </label>
              <input
                type="text"
                placeholder={
                  language == 'ar' ? "ادخل رقم الهاتف" : 'Enter Phone Number'
                }
                name="phone"
                onChange={(e) => {
                  setNewLoc({ ...newLoc, phone: e.target.value });
                }}
              />
            </div>
          </>
        ) : null}
        <div className="input-form">
          <label htmlFor="">
            {language == "ar" ? "المنطقة" : "Area"}

            <span className="star">*</span>
          </label>
          <select
            name="region"
            id="region"
            placeholder={language == 'ar' ? "اختر" : "Choose"}
            onChange={(e) => {
              setSelectedRegion(e.target.value);
            }}
            value={selectedRegion}
          >
            {regions.map((item, index) => {
              return (
                <option value={item.id}>
                  {language == "ar" ? item.title_ar : item.title_en}
                </option>
              );
            })}
          </select>
        </div>
        <div className="row4" style={{ flexWrap: "wrap" }}>
          <div className="input-form">
            <label htmlFor="region">
              {language == "ar" ? "القطعة" : "block"}

              <span className="star">*</span>
            </label>
            <input
              type="text"
              name="city"
              onChange={(e) => {
                setNewLoc({ ...newLoc, city: e.target.value });
              }}
            />
          </div>
          <div className="input-form">
            <label htmlFor="district">
              {" "}
              {language == "ar" ? "الجادة" : "Revenue"}
            </label>
            <input
              type="text"
              onChange={(e) => {
                setSelectedDis(e.target.value);
              }}
            />
          </div>
        </div>

        <div className="input-form">
          <label htmlFor="streat">
            {language == 'ar' ? "الشارع" : "Street"}
            <span className="star">*</span>
          </label>
          <input
            type="text"
            placeholder={
              language == 'ar' ? "ادخل اسم الشارع" : "Enter Your Street"
            }
            name="streat"
            onChange={(e) => {
              setNewLoc({ ...newLoc, streat: e.target.value });
            }}
          />
        </div>

        <div className="row6" style={{ flexWrap: "wrap" }}>
          <div className="input-form">
            <label htmlFor="architecture">
              {language == 'ar' ? "المنزل" : "House"}
              <span className="star">*</span>
            </label>
            <input
              type="text"
              placeholder=""
              name="architecture"
              onChange={(e) => {
                setNewLoc({ ...newLoc, architecture: e.target.value });
              }}
            />
          </div>
          <div className="input-form">
            <label htmlFor="floor">{language == 'ar' ? 'الدور' : 'Floor'}</label>
            <input
              type="text"
              placeholder=""
              name="floor"
              onChange={(e) => {
                setNewLoc({ ...newLoc, floor: e.target.value });
              }}
            />
          </div>
          <div className="input-form">
            <label htmlFor="apartment">
              {language == 'ar' ? 'شقه' : 'apartment'}
            </label>
            <input
              type="text"
              placeholder=""
              name="apartment"
              onChange={(e) => {
                setNewLoc({ ...newLoc, apartment: e.target.value });
              }}
            />
          </div>
        </div>

        <div className="input-form">
          <label htmlFor="special_marque">
            {language == 'ar' ? 'علامه مميزه' : 'Special Mark'}
          </label>
          <input
            type="text"
            placeholder={
              language == 'ar'
                ? "مثال: بجانب مكتن ما"
                : "Example: next to something"
            }
            name="special_marque"
            onChange={(e) => {
              setNewLoc({ ...newLoc, special_marque: e.target.value });
            }}
          />
        </div>

        <div className="input-form">
          <label htmlFor="location">
            {language == 'ar' ? "اسم العنوان" : "Title name"}
          </label>
          <input
            type="text"
            placeholder={
              language == 'ar' ? "مثال: منزل, عمل..." : "Example: home, work..."
            }
            name="location"
            onChange={(e) => {
              setNewLoc({ ...newLoc, location: e.target.value });
            }}
          />
        </div>

        {!first ? (
          <div className="input-form radio-button-container">
            <div className="content">
              <label className="checkBox">
                <input
                  checked={newLoc.status === 1}
                  id="ch1"
                  type="checkbox"
                  onClick={() => {
                    setNewLoc({
                      ...newLoc,
                      status: newLoc.status === 1 ? 0 : 1,
                    });
                  }}
                />
                <div className="transition"></div>
              </label>
            </div>

            <label
              htmlFor="status"
              onClick={() => {
                setNewLoc({ ...newLoc, status: newLoc.status === 1 ? 0 : 1 });
              }}
            >
              {language == 'ar'
                ? "حفظ كعنوان رئيسي"
                : "Save As A Main Location"}
            </label>
          </div>
        ) : null}
        {addLoading ? (
          <ThreeDots
            visible={true}
            height="80"
            width="80"
            color="red"
            radius="9"
            ariaLabel="three-dots-loading"
            wrapperStyle={{}}
            wrapperclassName=""
          />
        ) : (
          <button type="submit" className="submit-button">
            {language == 'ar' ? "حفظ العنوان" : "Save Location"}
          </button>
        )}
      </form>
    </div>
  );
};

export default AddLocation;

import "bootstrap/dist/css/bootstrap.min.css";
import "rsuite/Loader/styles/index.css";
import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

import "./App.css";
import { AppRoutes } from "./Routes/routes";
import DefaultLayout from "./layouts/defaultLayout";
import { fetchCartData } from "./store/cartReducer";
import usePageTracking from "./hooks/pageTracking";

function App() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isRefreshing, setIsRefreshing] = useState(false);
  const touchStartY = useRef(0);
  const touchEndY = useRef(0);
  const contentRef = useRef();

  usePageTracking();

  // Function to handle refresh
  const handleRefresh = () => {
    setIsRefreshing(true);
    setTimeout(() => {
      window.location.reload(); // Reload the page
    }, 1000); // Delay for demo purposes
  };

  // Handle touch start
  const handleTouchStart = (e) => {
    touchStartY.current = e.touches[0].clientY;
  };

  // Handle touch move
  const handleTouchMove = (e) => {
    touchEndY.current = e.touches[0].clientY;
    if (touchEndY.current > touchStartY.current + 20) {
      // Adjust the threshold for sensitivity
      handleRefresh();
    }
  };

  // Effect to scroll to the top when the pathname changes
  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [location?.pathname]);

  // Effect to fetch cart data on component mount
  useEffect(() => {
    dispatch(fetchCartData());
  }, [dispatch]);

  return (
    // <div
    //   ref={contentRef}
    //   onTouchStart={handleTouchStart}
    //   onTouchMove={handleTouchMove}
    //   style={{ textAlign: "center", overflow: "auto" }}
    //   className="your-own-class-if-you-want"
    // >
      <DefaultLayout>
        {isRefreshing && (
          <div style={{ fontSize: '24px', animation: 'spin 1s linear infinite' }}>
            &#x21bb; {/* Unicode character for a clockwise open circle arrow */}
          </div>
        )}
        <AppRoutes />
        <Toaster containerClassName="toastCon" />
      </DefaultLayout>
    // </div>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import Header from "../header";
import Footer from "../footer";
import SearchBox from "../../components/header/searchBox";
import { removeFromCart } from "../../assets/svgIcons";

const DefaultLayout = ({ classessName, children }) => {
  const [showSearchModal, setShowSearchModal] = useState(false);
  useEffect(() => {
    if (showSearchModal) {
      document.body.overflow = "hidden";
    } else {
      document.body.overflow = "initial";
    }
  });
  return (
    <div
      className={
        !classessName || !classessName?.length
          ? "defaultLayout"
          : "defaultLayout" + " " + classessName?.join()
      }
    >
      <Header setShowSearchModal={setShowSearchModal} />
      {children}
      {showSearchModal && (
        <>
          <span
            style={{
              zIndex: '121233432231',
              color: 'blue',
            }}
          >
            {removeFromCart}
          </span>
          <SearchBox setShowSearchModal={setShowSearchModal} />
        </>
      )}
      <Footer />
    </div>
  );
};

export default DefaultLayout;

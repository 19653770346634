import React, { useEffect } from "react";
import { exitModal } from "../../assets/svgIcons";
import "./style.css";

const PopUp = ({ title, open, setOpen, children }) => {
  useEffect(() => {
    if (open) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "initial";
    }
  }, [open]);

  if (!open) return null;

  const closeModal = () => {
    document.querySelector('.modalOverlay').classList.add('hide');
    document.querySelector('.modalOverlay').classList.remove('show');
    document.querySelector('.modalContainer').classList.add('hide');
    document.querySelector('.modalContainer').classList.remove('show');

    setTimeout(() => setOpen(false), 500);
  };

  return (
    <div>
      <div
        className={`modalOverlay ${open ? "show" : "hide"}`}
        onClick={closeModal}
      ></div>
      <div className={`modalContainer ${open ? "show" : "hide"}`}>
        <div className="modalHeader">
          <span className="modalTitle">{title}</span>
          <span className="exitModal" onClick={closeModal}>
            {exitModal}
          </span>
        </div>
        <div className="modalChildren">{children}</div>
      </div>
    </div>
  );
};

export default PopUp;
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  favoriteItems: localStorage.getItem("els_favoriteItems")
    ? JSON.parse(localStorage.getItem("els_favoriteItems"))
    : [],
};

const favoriteSlice = createSlice({
  name: "favorite",
  initialState,
  reducers: {
    addToFavorites(state, action) {
      const newItem = action.payload;
      state.favoriteItems.push(newItem);
      
      localStorage.setItem(
        "els_favoriteItems",
        JSON.stringify(
          state.favoriteItems && Array.isArray(state.favoriteItems)
            ? state.favoriteItems
            : []
        )
      );
    },
    removeFromFavorites(state, action) {
      const itemIdToRemove = action.payload;
      
      state.favoriteItems = state.favoriteItems.filter(
        (item) => item?.id !== itemIdToRemove
      );
      localStorage.setItem(
        "els_favoriteItems",
        JSON.stringify(
          state.favoriteItems && Array.isArray(state.favoriteItems)
            ? state.favoriteItems
            : []
        )
      );
    },
  },
});

export const { addToFavorites, removeFromFavorites } = favoriteSlice.actions;

export default favoriteSlice.reducer;

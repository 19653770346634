import React, { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import Checkoutcartdetails from "../../components/checkoutcartdetails/checkoutcartdetails";
import UseCartFavourite from "../../hooks/useCartFavourite";
import UseGeneral from "../../hooks/useGeneral";
import Options from "../options/options";
import ProductDetailsImage from "./ProductDetailsImage";
import ProductDetailsTexts from "./productDetailsTexts";
import "./style.css";
import { Axios } from "../../Axios";
import { BASE_URL } from "../../Axios/base_url";
import toast from "react-hot-toast";
import { fetchCartData } from "../../store/cartReducer";
import { useDispatch } from "react-redux";
import { FaStar } from "react-icons/fa";
import moment from "moment";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Keyboard, Mousewheel, Navigation } from "swiper/modules";
const ProductDetailsComponent = ({
  images,
  item,
  id,
  title,
  price,
  getItmData,
}) => {
  const { language } = UseGeneral();
  const { cart, totalQuantity, isItemInCart, getUserData } = UseCartFavourite();
  const dispatch = useDispatch();
  const location = useLocation();
  const [product_id] = useSearchParams();
  const [totalPrice, setTotalPrice] = useState([]);
  const [choosedOption, setChoosedOptions] = useState([]); //item?.options
  const [options, setOptions] = useState([]);
  const [totalCartPrice, setTotalCartPrice] = useState("");
  const [activeTap, setActiveTap] = useState('desc');
  const addToCartWithOptions = () => {
    let optionList = [...choosedOption];
    // setCartLoading(true);
    let product_options = "";
    for (let i = 0; i < optionList.length; i++) {
      if (optionList[i].quantity != 0) {
        if (product_options.length == 0) {
          product_options +=
            optionList[i].id + "*green*" + optionList[i]["quantity"];
        } else {
          product_options +=
            "**green**" +
            optionList[i].id +
            "*green*" +
            optionList[i]["quantity"];
        }
      }
    }
    const data_send = {
      // product_options,
      has_option: 0,
      product_id: item.id,
      product_count: 1,
    };
    if (product_options.length > 0) {
      data_send["has_option"] = 1;
      data_send["product_options"] = product_options;
    }
    Axios({
      url: BASE_URL + `cart/add_to_cart`,
      method: "post",
      data: data_send,
    })
      .then((res) => {
        if (res.status == "success") {
          toast.success(res.message);
          getItmData();
          dispatch(fetchCartData());
          getCartDetails();
          // getUserData();
          // setChoosedOptions([...choosedOption,...res.result.cart_options])
        }
      })
      .finally(() => {});
  };
  const handleChangeQuan = (quan, id, type) => {
    let newOptions = [...choosedOption];
    let originalOpts = [...options];
    for (let i = 0; i < newOptions.length; i++) {
      if (newOptions[i].id == id) {
        newOptions[i]["quantity"] = quan;
      }
      setChoosedOptions([...newOptions]);
    }
    for (let i = 0; i < originalOpts.length; i++) {
      if (originalOpts[i].id == id) {
        if (type == "plus") {
          setTotalPrice(totalPrice * 1 + originalOpts[i].price * 1);
        } else {
          setTotalPrice(totalPrice * 1 - originalOpts[i].price * 1);
        }
        // if(quan>originalOpts[i].quantity){
        //   setTotalPrice(totalPrice*1-originalOpts[i].price*1);
        // }
        // else {
        //   setTotalPrice(totalPrice*1+originalOpts[i].price*1);
        // }
      }
    }
    // setOptions
  };

  const getCartDetails = () => {
    Axios({
      url: BASE_URL + `cart/all_carts_for_user`,
      method: "get",
    }).then((res) => {
      if (res.status == "success") {
        setTotalCartPrice(res.result.total_price);
      }
    });
  };

  const eqData = () => {
    if (item.cart_options && item.cart_options.length > 0) {
      setChoosedOptions(
        item.cart_options.map((item) => {
          return { ...item, quantity: item.option_count };
        })
      );
    } else {
      const options = [...item?.options];
      setChoosedOptions(
        options.map((item) => {
          return { ...item, quantity: 0 };
        })
      );
    }
  };

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [location?.pathname, id, product_id?.get("id")]);

  useEffect(() => {
    const selectedProduct = cart?.filter((item) => item?.id == id)[0];
    if (
      selectedProduct &&
      isItemInCart(id) &&
      selectedProduct?.options &&
      selectedProduct?.options?.length
    ) {
      const newOptions = item?.options.map((item) => {
        const newItem = selectedProduct?.options?.filter(
          (opItem) => opItem?.id == item?.id
        )[0];
        return newItem && newItem?.id
          ? { ...newItem, quantity: 0 }
          : { ...item, quantity: 0 };
      });
      setChoosedOptions([...newOptions]);
    }
  }, [cart, item]);
  useEffect(() => {
    eqData();
    getCartDetails();
  }, []);

  return (
    <div className="rowDiv CartProducts proddet">
      <div className="rowSpaceBetween">
        <div className="prod_all_det">
          <div className="smallRowDiv">
            <ProductDetailsImage
              getItmData={getItmData}
              images={item.images && item.images}
              item={item}
              id={id}
              title={title}
              price={price}
            />
            <ProductDetailsTexts
              getCartDetails={getCartDetails}
              addToCartWithOptions={addToCartWithOptions}
              setChoosedOptions={setChoosedOptions}
              choosedOption={choosedOption}
              item={item}
              getItmData={getItmData}
              eqData={eqData}
            />
          </div>
        </div>
        {item?.cart_options && item?.cart_options?.length ? (
          <div className="productDetailsOptions">
            <Options
              getItmData={getItmData}
              item={item}
              handleChangeQuan={handleChangeQuan}
              options={choosedOption}
              language={language}
              setChoosedOptions={setChoosedOptions}
            />
            <img src={require("../../assets/images/Line 17.png")} alt="" />
            <Checkoutcartdetails
              cart={cart}
              language={language}
              totalPrice={totalPrice}
              total_check={totalCartPrice}
              totalQuantity={totalQuantity}
            />
          </div>
        ) : item.options && item.options.length > 0 ? (
          <div className="productDetailsOptions">
            <Options
              getItmData={getItmData}
              item={item}
              handleChangeQuan={handleChangeQuan}
              options={choosedOption}
              language={language}
              setChoosedOptions={setChoosedOptions}
            />
            <img src={require("../../assets/images/Line 17.png")} alt="" />
            <Checkoutcartdetails
              cart={cart}
              language={language}
              totalPrice={totalPrice}
              total_check={totalCartPrice}
              totalQuantity={totalQuantity}
            />
          </div>
        ) : null}
      </div>
      <div className="des_ops">
        <div className="top">
          <h5
            className={activeTap == 'desc' ? 'active' : ''}
            onClick={() => {
              setActiveTap("desc");
            }}
          >
            {language == 'ar' ? 'الوصف' : 'Description'}
          </h5>
          <h5
            className={activeTap == 'revs' ? 'active' : ''}
            onClick={() => {
              setActiveTap("revs");
            }}
          >
            {language == 'ar' ? 'التقيمات' : 'Reviews'} (+
            {item?.reviews && item?.reviews.length})
          </h5>
        </div>
        <div className="bottom">
          {activeTap == 'desc' ? (
            <div className="description_tap">
              {item.description_ar || item.description_en ? (
                <p
                  dangerouslySetInnerHTML={{
                    __html:
                      language == "ar"
                        ? item.description_ar
                        : item.description_en,
                  }}
                  style={{ color: "#3D3D3D", fontSize: "22px" }}
                ></p>
              ) : null}
              <h5>{language == 'ar' ? 'محتوى الخلطه' : 'Mixture content'}:</h5>
              {item.ingrediants && item.ingrediants.length > 0 ? (
                item.ingrediants.map((ing, ind) => {
                  return (
                    <div className="ingrediants">
                      <span>
                        {language == 'ar' ? ing.title_ar : ing.title_en}
                      </span>
                    </div>
                  );
                })
              ) : (
                <div>
                  <h6>
                    {language == 'ar' ? 'لا يوجد مكونات' : 'There Are Not Pro'}
                  </h6>
                </div>
              )}
            </div>
          ) : (


            
            <Swiper
            className="rev_sweip"
            spaceBetween={20}
            slidesPerView={3}
            key={99}
            // navigation={true}
            modules={[FreeMode, Navigation, Mousewheel, Keyboard]}
            // pagination={{ clickable: true }}
            autoplay={{ delay: 5000 }}
            loop={true}
            dir={language != "en" ? "rtl" : "ltr"}
            // {...studiesswiper}
          >
            {item?.reviews &&
              item?.reviews?.map((item, index) => (
                <SwiperSlide
                  className="rev_slider"
                  key={index}
                  onClick={() => window?.open(item?.link, "_blanck")}
                >
                  <div className="review">
                    <img src={require('../../assets/quotation.png')} alt="" />
                    <p>"{item?.comment}"</p>
                    <div className="name">
                      <h6>{item?.name}</h6>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
          </Swiper>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductDetailsComponent;

import React, { useState } from "react";
import AddBtn from "./addBtn";
import DecreaseBtn from "./decrease";
import "./style.css";
import TextInput from "./textInput";
import { Axios } from "../../Axios";
import { BASE_URL } from "../../Axios/base_url";
import { addToCart, decreaseBTN, decreaseBTN2 } from "../../assets/svgIcons";
import { Spinner } from "react-bootstrap";
import toast from "react-hot-toast";
import UseGeneral from "../../hooks/useGeneral";
import UseCartFavourite from "../../hooks/useCartFavourite";
import { fetchCartData } from "../../store/cartReducer";
import { useDispatch } from "react-redux";
import { decrement2, decrementQuantity, decrementWeightQuantity, incrementQuantity, incrementWeightQuantity, removeItem } from "../../store/offlineCartReducer/OfflineCartReducer";
// import UseGeneral from "../../hooks/useGeneral";
const ProductInCartMangeButton = ({
  itemIdToIncrement,
  checkNotInProd,
  update,
  quantity,
  item,
  updateCart,
  inPrdDet,
  delCartItem,
}) => {
  
  const dispatch=useDispatch()
  const { getUserData } = UseCartFavourite();
  const [incLoading, setIncLoading] = useState(false);
  const handleIncreae = (new_count, type) => {
    // return
    setIncLoading(true);

    if(type=='minus'){
      
      if(item.price_for=='weight'){
        dispatch(decrementWeightQuantity({itemIdToDecrement:item.id,weight:item.weight}))
      }
      else {
        
        dispatch(decrementQuantity({itemIdToDecrement:item.id}));
      }
      // dispatch(decrement2({id:item.id}));
      // updateCart()
    }
    else {
      if(item.price_for=='weight'){
        dispatch(incrementWeightQuantity({itemIdToIncrement:item.id,weight:item.weight,item}))
      }
      else{
        

        dispatch(incrementQuantity({itemIdToIncrement:item.id, item}))
      }
      // updateCart()
    }
    // window.location?.reload()
    setIncLoading(false)
      return
    Axios({
      url: inPrdDet
        ? BASE_URL +
          `cart/change_count/${item.cart_prod_id}?new_count=${new_count}`
        : BASE_URL + `cart/change_count/${item.id}?new_count=${new_count}`,
      method: "POST",
      data: {
        type: type,
      },
    })
      .then((res) => {
        if (res.status == "success") {
          updateCart();
          dispatch(fetchCartData());
          toast.success(res.message);
        } else {
          toast.error(res.message);
        }
      })
      .catch((e) => e)
      .finally(() => {
        setIncLoading(false);
      });
  };
  return checkNotInProd && checkNotInProd == true ? (
    item.productweights && item.productweights.length > 0 ? null : (
      <div className="ProductInCartMangeButton">
        {incLoading ? (
          <Spinner />
        ) : (
          <>
            {" "}
            <span
              className="addBTN"
              onClick={() => {
                
                handleIncreae(parseInt(quantity) + 1, "plus");
                // getUserData();
              }}
            >
              {incLoading ? <Spinner /> : <> {addToCart}</>}
            </span>
            <TextInput text={quantity} />
            <span
              className="addBTN"
              onClick={() => {
                
                if (quantity == 1) {
                  dispatch(decrement2({id:item.id}));
                  // dispatch(removeItem({itemIdToRemove:item.id}))
                  return
                  delCartItem(item.product_id || item.id);
                  // 
                  // getUserData();
                }
                if (quantity > 1) {
                  handleIncreae(parseInt(quantity) - 1, "minus");
                }
              }}
            >
              {incLoading ? <Spinner /> : <> {decreaseBTN2}</>}
            </span>
          </>
        )}
        {/* <DecreaseBtn update={update} itemIdToIncrement={itemIdToIncrement} /> */}
      </div>
    )
  ) : item?.product?.productweights &&
    item?.product?.productweights.length > 0 ? null : (
    <div className="ProductInCartMangeButton">
      {incLoading ? (
        <Spinner />
      ) : (
        <>
          {" "}
          <span
            className="addBTN"
            onClick={() => {
              handleIncreae(parseInt(quantity) + 1, "plus");
              // getUserData();
            }}
          >
            {incLoading ? <Spinner /> : <> {addToCart}</>}
          </span>
          <TextInput text={quantity} />
          <span
            className="addBTN"
            onClick={() => {
              if (quantity == 1) {
                dispatch(decrement2({id:item.id}));
                return
                delCartItem(item.product_id || item.id);
                // getUserData();
                // 
              }
              if (quantity > 1) {
                handleIncreae(parseInt(quantity) - 1, "minus");
                // getUserData();
              }
            }}
          >
            {incLoading ? <Spinner /> : <> {decreaseBTN2}</>}
          </span>
        </>
      )}
      {/* <DecreaseBtn update={update} itemIdToIncrement={itemIdToIncrement} /> */}
    </div>
  );
};

export default ProductInCartMangeButton;

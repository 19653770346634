import React, { useEffect, useState } from 'react';
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import './recipe.css';
import ContentLoader from "react-content-loader";
import { BASE_URL } from "../../Axios/base_url";
import { Axios } from "../../Axios";
import UseGeneral from '../../hooks/useGeneral';
import { ThreeDots } from 'react-loader-spinner';
import HomeReviews from '../HomeReviews/HomeReviews';
const Recipe = () => {
  const navigate = useNavigate();
  const { language } = UseGeneral();
  const [recipe_id] = useSearchParams();
  useEffect(() => {
    document.title =
      language == "ar" ? "فن النحت | الوصفة   " : "Sculpture ART | Reciepe";
  }, []);
  const [pageLoading, setPageLoading] = useState(false);
  const [recipeDetails, setRecipeDetails] = useState({});
  const [recipes, setRecipes] = useState([]);
  const [reviews, setReviews] = useState([]);
  const getSubcategories = () => {
    setPageLoading(true);
    Axios({
      url: BASE_URL + `recipes/get_recipe/${recipe_id.get("q")}`,
      method: "GET",
    })
      .then((res) => {
        if (res.status == 'success') {
          setRecipeDetails(res?.result);
          if (Array.isArray(res?.result?.recipes)) {
            setRecipes(res?.result?.recipes);
          }
          if (Array.isArray(res?.result?.reviews)) {
            setReviews(res?.result?.reviews);
          }
        }
      })
      .finally(() => {
        setPageLoading(false);
      })
      .catch((e) => e);
  };
  useEffect(() => {
    getSubcategories();
  }, [recipe_id.get("q")]);
  return (
    <div className="recipe_details_page">
      {pageLoading ? (
        <div style={{ textAlign: 'center' }} className="loading">
          <iframe
            width={350}
            height={400}
            style={{ margin: "auto" }}
            src="https://lottie.host/embed/0f5cbd90-b98b-4ef9-a1fe-6853af66f359/mzKPtDndKi.json"
          ></iframe>
        </div>
      ) : (
        <>
          <div className="bread_camb_det">
            <h6
              onClick={() => {
                navigate("/");
              }}
            >
              {language == 'ar' ? 'الرئيسيه' : 'Main Page'}
            </h6>
            <span>&gt;</span>
            <h6>{language == 'ar' ? 'عالم رهشيلا' : 'Rahshela World'}</h6>
            <span>&gt;</span>
            {/* {} */}
            <p>
              {language == 'ar'
                ? recipeDetails?.title_ar
                : recipeDetails?.title_en}
            </p>
          </div>
          <div className="recipe_img">
            <img
              src={
                recipeDetails?.images &&
                recipeDetails?.images[0] &&
                recipeDetails?.images[0].url
              }
              alt=""
            />
          </div>
          <div className="prepra_time">
            <h5>{language == 'ar' ? 'وقت التحضير' : 'Preparation time'} : </h5>
            <p>
              {recipeDetails?.time}
              {language == 'ar' ? "دقيقة" : "Minite"}
            </p>
            <p>
              {language == 'ar' ? (
                <>تكفى {recipeDetails?.number_for} أشخاص</>
              ) : (
                <>Enough {recipeDetails?.number_for} persons</>
              )}
            </p>
          </div>
          {recipeDetails?.ingrediants_ar != '' ||
          recipeDetails?.ingrediants_en != '' ? (
            <div className="rec_ingre">
              <h5>{language == 'ar' ? 'المقادير' : 'Ingrediants'} : </h5>{" "}
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    language == 'ar'
                      ? recipeDetails?.ingrediants_ar
                      : recipeDetails?.ingrediants_en,
                }}
              ></p>
            </div>
          ) : null}
          {recipeDetails?.steps_ar || recipeDetails?.steps_en ? (
            <div className="rec_ingre">
              <h5>
                {language == 'ar' ? 'طريقة التحضير' : 'How to prepare'} :{" "}
              </h5>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    language == 'ar'
                      ? recipeDetails?.steps_ar
                      : recipeDetails?.steps_en,
                }}
              ></p>
            </div>
          ) : null}
          {recipeDetails?.video_url && recipeDetails?.video_url?.length ? (
            <div className="rec_ingre" style={{ margin: "20px auto 50px" }}>
              <h5>
                {language == 'ar' ? 'فيديو التحضير' : 'How to prepare video'} :{" "}
              </h5>

              <iframe
                src={recipeDetails?.video_url}
                frameborder="0"
                style={{
                  width: "100%",
                  minHeight: "400px",
                  borderRadius: "16px",
                }}
              ></iframe>
            </div>
          ) : null}
          {reviews && reviews.length > 0 && (
            <HomeReviews
              title={
                language == 'ar'
                  ? 'أراء عملائنا حول هذه الوصفة الشهية'
                  : 'Our customers opinions about this delicious recipe'
              }
              reviews={reviews}
            />
          )}
          <div className="may_like_you">
            <h6>
              {language == 'ar' ? 'ربما يعجبك ايضا' : 'You may also like it'}
            </h6>
            <div className="recipes_products recipes_products_cont">
              {recipes &&
                recipes.length > 0 &&
                recipes.map((item, index) => {
                  return (
                    <div
                      onClick={() => {
                        navigate({
                          pathname: "/recipe",
                          search: createSearchParams({
                            q: item?.id,
                          }).toString(),
                        });
                      }}
                      key={index}
                      className="rec_div"
                    >
                      <div className="img">
                        <img
                          src={
                            item?.images &&
                            item?.images[0] &&
                            item?.images[0].url
                          }
                          alt=""
                        />
                      </div>
                      <h5>
                        {language == 'ar' ? item?.title_ar : item.title_en}
                      </h5>
                      <button>
                        {language == 'ar' ? 'الوصفه' : 'The Recipe'}
                      </button>
                    </div>
                  );
                })}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Recipe;

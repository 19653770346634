import React, { useEffect, useState } from "react";
import "./style.css";
import UseGeneral from "../../../hooks/useGeneral";
import { FaChevronDown } from "react-icons/fa6";
import axios from "axios";
import { Axios } from "../../../Axios";
import { BASE_URL } from "../../../Axios/base_url";
import { CloseButton, Dropdown } from "react-bootstrap";
import { useNavigate, createSearchParams } from "react-router-dom";
import HeaderIcons from "../headerIcons";
import { arrowDown, categoriesSvg } from "../../../assets/svgIcons";
import LeftMiddleHeader from "../middleHeader/leftHeader";
import MiddleHeader from "../middleHeader";
const BottomHeader = ({
  menuOpen,
  setMenuOpen,
  setShowSearchModal,
  isSticky,
}) => {
  const { language, setOpenMenuList, openMenuList } = UseGeneral();
  const navigate = useNavigate();
  const [headerLoading, setHeaderLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [clientHeight, setClicentHeight] = useState(0);
  // useEffect(() => {
  //   if (
  //     document.querySelectorAll("header") &&
  //     document.querySelectorAll("header")?.length
  //   ) {
  //     setClicentHeight();
  //   }
  // }, [window]);
  const getCategories = () => {
    setHeaderLoading(true);
    Axios({
      url: BASE_URL + "categories/get_all",
      method: "GET",
    })
      .then((res) => {
        if (res.status == "success") {
          if (Array.isArray(res.result)) {
            const homeUpdateNumber = localStorage.getItem("home_update");
            if (!homeUpdateNumber) {
              localStorage.setItem("home_update", res?.result?.home_update);
              localStorage.setItem("headerCats", JSON.stringify(res?.result));
              setCategories(res.result);
            } else {
              localStorage.setItem("headerCats", JSON.stringify(res?.result));
              setCategories((prev) =>
                JSON.parse(localStorage.getItem("headerCats"))
              );
            }

            // setCategories(res.result);
          }
        }
      })
      .finally(() => {
        setHeaderLoading(false);
      });
  };
  useEffect(() => {
    if (localStorage.getItem("home_update")) {
      setCategories(JSON.parse(localStorage.getItem("headerCats")));
    }

    getCategories();
  }, []);
  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "initial";
    }
  }, [menuOpen]);
  const [isScorllable, setIsScrollable] = useState(false);
  return (
    <div className="rowDiv new_head d-flex">
      <div style={{ cursor: "pointer" }} className="new_logo">
        <img
          onClick={() => {
            navigate("/");
          }}
          role="button"
          src={
            "https://res.cloudinary.com/duovxefh6/image/upload/v1724509802/Sculpture_Art_5_sjup7l.png"
          }
          alt=""
        />
        <div className="isMobile">
          <MiddleHeader
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            isSticky={isSticky}
            setShowSearchModal={setShowSearchModal}
          />
        </div>
      </div>

      <div className={menuOpen ? "categories opened" : "categories"}>
        <div className="rowDiv">
          <h3
            style={{ cursor: "pointer" }}
            className="colorRed"
            onClick={() => navigate("/Categories")}
          >
            {language == "ar" ? "الفئات الرئيسية" : "Main Categories"}
          </h3>

          <CloseButton
            onClick={() => setMenuOpen(false)}
            className="closeMenu"
          ></CloseButton>
        </div>
        <label htmlFor="ert" className="main_drop">
          <label
            style={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              cursor: "pointer",
              border: "none",
              padding: 0,
            }}
            variant=""
            id="dropdown-basic"
          >
            <div
              className="d-flex all_categories"
              style={{ cursor: "pointer" }}
              onClick={() => navigate("/Categories")}
            >
              {categoriesSvg}
              <span className="category cat">
                {language == "ar" ? "الفئات الرئيسية" : "Main Categories"}
              </span>
            </div>
          </label>
          <input
            className="checkDropMenu"
            style={{ display: "none" }}
            id="ert"
            name="ert"
            type="checkbox"
          />
          <div
            className="sub_category_drop"
            style={{
              height: `calc(100vh - ${
                document.querySelector("header")?.clientHeight
              }px)`,
            }}
          >
            <div>
              {categories && categories?.length
                ? categories?.map((itemCat, index) => {
                    if (true) {
                      return (
                        <div
                          className="shop_items_subs"
                          onMouseMove={() => setIsScrollable(true)}
                          onMouseLeave={() => setIsScrollable(false)}
                        >
                          <Dropdown.Item
                            onClick={() => {
                              navigate({
                                pathname: "/Product",
                                search: createSearchParams({
                                  q: itemCat.id,
                                }).toString(),
                              });
                            }}
                            style={{
                              display: "flex",
                              gap: "10px",
                              fontSize: "16px",
                              marginBottom: "15px",
                              alignItems: "center",
                            }}
                          >
                            <>
                              <img
                                style={{ width: "40px" }}
                                src={itemCat.image}
                                alt=""
                              />
                              <p style={{ margin: "0px", fontSize: "14px" }}>
                                {language?.toLowerCase() == "ar"
                                  ? itemCat?.title_ar
                                  : itemCat?.title_en}
                              </p>
                            </>
                          </Dropdown.Item>
                          {itemCat?.subcategories &&
                            itemCat?.subcategories.length > 1 &&
                            itemCat?.subcategories && (
                              <div className="all_cats_subs">
                                {Array.from(
                                  {
                                    length: Math.ceil(
                                      itemCat.subcategories.length / 4
                                    ),
                                  },
                                  (_, index) => (
                                    <div key={index} className="column">
                                      {itemCat.subcategories
                                        .slice(index * 4, (index + 1) * 4)
                                        .map((subItem, index) =>
                                          index > 0 ? (
                                            <div
                                              key={subItem.id}
                                              onClick={() => {
                                                navigate({
                                                  pathname: "/Product",
                                                  search: createSearchParams({
                                                    q: itemCat.id,
                                                    s: subItem.id,
                                                    sub_q: subItem.id,
                                                  }).toString(),
                                                });
                                              }}
                                              className="sub_item"
                                            >
                                              <h5 style={{ margin: "0px" }}>
                                                {language == "ar"
                                                  ? subItem.title_ar
                                                  : subItem.title_en}
                                              </h5>
                                            </div>
                                          ) : null
                                        )}
                                    </div>
                                  )
                                )}
                              </div>
                            )}
                        </div>
                      );
                    }
                    return null;
                  })
                : null}

              <div
                className="customorderpage"
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "6px",
                  padding: "10px 15px ",
                  cursor: "pointer",
                }}
                onClick={() => navigate("/custom")}
              >
                <img
                  src="https://res.cloudinary.com/duovxefh6/image/upload/v1725295252/image-_3_yluqlh.png"
                  alt=""
                />
                <span>{language == "ar" ? "مخصص" : "Custom"}</span>
              </div>
            </div>
          </div>
        </label>
        {categories && categories?.length
          ? categories?.map((item, index) => {
              if (true) {
                return (
                  <label htmlFor={index} className="cat_and_sub">
                    <span
                      onClick={() => {
                        navigate({
                          pathname: "/Product",
                          search: createSearchParams({ q: item.id }).toString(),
                        });
                      }}
                      className="category not_responsive cat_for_sub subCatSub"
                      style={{ display: "flex" }}
                    >
                      <img style={{ width: "25px" }} src={item.image} alt="" />
                      {/* <span className="dbot">.</span> */}
                      <span>
                        {" "}
                        {language?.toLowerCase() == "ar"
                          ? item?.title_ar
                          : item?.title_en}
                      </span>
                      <FaChevronDown style={{ fontSize: "15px" }} />
                    </span>
                    <span
                      onClick={() => {
                        if (
                          item?.subcategories &&
                          item?.subcategories.length > 1
                        )
                          return null;
                        else {
                          setMenuOpen(false);
                          return navigate({
                            pathname: "/Product",
                            search: createSearchParams({
                              q: item.id,
                            }).toString(),
                          });
                        }
                      }}
                      className="category responsive cat_for_sub subCatSub"
                      style={{ display: "flex" }}
                    >
                      <img style={{ width: "25px" }} src={item.image} alt="" />
                      {/* <span className="dbot">.</span> */}
                      <span>
                        {" "}
                        {language?.toLowerCase() == "ar"
                          ? item?.title_ar
                          : item?.title_en}
                      </span>
                      {item?.subcategories && item?.subcategories.length > 1 ? (
                        <> {arrowDown}</>
                      ) : null}
                    </span>
                    <input
                      className="checkSubDropMenu"
                      style={{ display: "none" }}
                      id={index}
                      name="ert"
                      type="checkbox"
                    />
                    <div className="dropmenus">
                      {item?.subcategories && item?.subcategories.length > 1 ? (
                        <div className="category_subcategories">
                          {item?.subcategories &&
                            item?.subcategories.length &&
                            item?.subcategories.map((subItem, index) => {
                              if (index > 0)
                                return (
                                  <div
                                    onClick={() => {
                                      setMenuOpen(false);
                                      navigate({
                                        pathname: "/Product",
                                        search: createSearchParams({
                                          q: item.id,
                                          s: subItem.id,
                                          sub_q: subItem.id,
                                        }).toString(),
                                      });
                                    }}
                                    className="category_sub_me"
                                  >
                                    <h5>
                                      {language == "ar"
                                        ? subItem.title_ar
                                        : subItem.title_en}
                                    </h5>
                                  </div>
                                );
                            })}
                        </div>
                      ) : null}
                    </div>
                  </label>
                );
              }
              return null;
            })
          : null}
        <div className="isMobile">
          <div
            className="customorderpage"
            style={{
              display: "flex",
              alignItems: "center",
              gap: "6px",
              padding: "10px 15px ",
              cursor: "pointer",
            }}
            onClick={() => {
              setMenuOpen(false);
              navigate("/custom");
            }}
          >
            <img
              src="https://res.cloudinary.com/duovxefh6/image/upload/v1725295252/image-_3_yluqlh.png"
              alt=""
            />
            <span>{language == "ar" ? "مخصص" : "Custom"}</span>
          </div>
        </div>
      </div>
      <div className="iconsHeader">
        <LeftMiddleHeader
          reverse={true}
          setShowSearchModal={setShowSearchModal}
          mobile={true}
        />
      </div>
    </div>
  );
};

export default BottomHeader;

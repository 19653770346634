import React, { useState } from "react";
import UseGeneral from "../../../hooks/useGeneral";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { CiLocationOn } from "react-icons/ci";
import { FaChevronLeft } from "react-icons/fa";
const OrderCard = ({
  data,
  setShowOrderDetails,
  showOrderDetails,
  setOrderData,
}) => {
  const navigate = useNavigate();
  const { language } = UseGeneral();
  // const [showOrderDetails,setShowOrderDetails]=useState(false);
  // const [orderData,setOrderData]=useState({});
  return (
    <>
      {data?.order_status ? (
        false ? null : (
          <div className="orderCard-container">
            <div className="right">
              <h6
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate("/order-following", { state: { id: data?.id } });
                }}
                className="ord_numb"
              >
                {" "}
                {language == 'ar' ? "مرجع العميل" : 'Customer Reference'} :{" "}
                {data?.id}
              </h6>

              <h6
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  navigate("/order-following", { state: { id: data?.id } });
                }}
                className="ord_numb"
              >
                {" "}
                {language == 'ar' ? "رقم الفاتورة  " : 'Invoice Number'} :{" "}
                {data?.invoiceId}
              </h6>
              <div className="status-title">
                {data?.order_status == "pending"
                  ? language == "ar"
                    ? "قيد المراجعه"
                    : "Pending"
                  : data?.order_status == "canceled"
                  ? language == "ar"
                    ? "ملغى"
                    : "canceled"
                  : data?.order_status == "rejected"
                  ? language == "ar"
                    ? "ملغى"
                    : "canceled"
                  : data?.order_status == "completed"
                  ? language == "ar"
                    ? "مكتمل"
                    : "completed"
                  : language == "ar"
                  ? "فى التوصيل"
                  : "In Delivery"}
              </div>
              <div className="date">
                <span>{language == 'ar' ? 'يوم' : 'Day'} </span>
                <span>{moment(data?.created_at).format('L')}</span>
              </div>
              <div className="re_make_order">
                <img src={require('../../../assets/reload.png')} alt="" />
                <h5>{language == 'ar' ? 'إعاده الطلب' : 'Reorder'}</h5>
              </div>
            </div>
            <div className="left">
              <FaChevronLeft
                onClick={() => {
                  setShowOrderDetails(true);
                  setOrderData(data);
                }}
              />
            </div>
            {/* <div className="info">
            <div className="detail">
              <p>{language == "ar" ? "رقم الطلب" : "Order Number"}</p>
              <strong>{data?.id}</strong>
            </div>
            <div className="detail">
              <p>{language == "ar" ? "تاريخ الطلب" : "Order Date"}</p>
              <strong>{moment(data?.order_time).format("L")}</strong>
            </div>
            <div className="detail">
              <p>{language == "ar" ? "اجمالي الطلب" : "Total Order"}</p>
              <strong>{data?.order_value} {language=='ar'?'د.ك':'KWD'}</strong>
            </div>
          </div>
          <button
            onClick={(e) => {
              navigate("/order-following", { state: { id: data.id } });
            }}
            className="follow-order-button"
          >
            {language == "ar" ? "تتبع الطلب" : "Follow Order"}
          </button> */}
          </div>
        )
      ) : null}
    </>
  );
};

export default OrderCard;

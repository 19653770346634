import React, { useRef, useState } from "react";
import "./style.css";
import { removeFromCart, searchIcon } from "../../../assets/svgIcons";
import UseGeneral from "../../../hooks/useGeneral";
import { Axios } from "../../../Axios";
import { BASE_URL } from "../../../Axios/base_url";
import { createSearchParams, useNavigate } from "react-router-dom";
import { Loader } from "rsuite";

const SearchBox = ({ setShowSearchModal }) => {
  const { language } = UseGeneral();
  const [searchResult, setSearchResult] = useState([]);
  const searchInput = useRef();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const search = (value) => {
    if (!value || !value?.length) {
      setSearchResult([]);
      return;
    }
    setLoading(true);
    Axios({
      url: BASE_URL + `homepage/search_products`,
      method: "post",
      data: {
        search_txt: value,
      },
    })
      .then((response) => {
        
        if (response?.result?.products && response?.result?.products?.length)
          setSearchResult(response?.result?.products);
        else {
          setSearchResult([]);
        }
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="over_lay">
      <span
        onClick={() => {
          setShowSearchModal(false);
        }}
        style={{ cursor: 'pointer', zIndex: '999999999999999999' }}
      >
        {removeFromCart}
      </span>
      <div className="searchBox">
        <input
          style={{ margin: "0px" }}
          type="search"
          name=""
          id=""
          ref={searchInput}
          onKeyUp={(e) => search(e.target.value)}
          placeholder={
            language?.toLowerCase() === "ar"
              ? "أبحث عن منتجات, أصناف, و أكثر"
              : "Search for products, items, and more"
          }
        />
        <button style={{ width: "100px" }} className="btn-search">
          {language == 'ar' ? 'بحث' : "Search"}
        </button>
        {searchResult && searchResult?.length && searchInput?.current?.value ? (
          <div className="searchResult">
            {searchResult?.map((product) => (
              <div
                key={product?.id}
                className="product"
                onClick={() => {
                  // if()
                  setShowSearchModal(false);
                  navigate({
                    pathname: "/ProductDetails",
                    search: createSearchParams({
                      id: product.id,
                    }).toString(),
                  });
                  // navigate(`/ProductDetails/${product.id}`)
                  setSearchResult([]);
                }}
              >
                <img src={product?.image} alt={product?.title_ar} />
                <p>
                  {language === "ar"
                    ? product?.title_ar
                      ? product.title_ar.substring(0, 30) + "..."
                      : ""
                    : product?.title_en
                    ? product.title_en.substring(0, 30) + "..."
                    : ""}
                </p>
              </div>
            ))}
          </div>
        ) : !loading ? null : (
          <div className="searchResult">
            {" "}
            <Loader size="md" />{" "}
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchBox;

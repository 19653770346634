import React, { useState, useEffect } from "react";
import BottomHeader from "../../components/header/bottomHeader";
import MiddleHeader from "../../components/header/middleHeader";
import TopHeader from "../../components/header/topHeader";
import { homeCategories } from "../../data/homeCategories";
import "./style.css";

const Header = ({ setShowSearchModal }) => {
  const [isSticky, setIsSticky] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 160) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [window]);

  return (
    <header className={isSticky ? "sticky-header" : ""}>
      {/* <div class="progress-circle">
    <div class="circle">
        <div class="circle-inner">
            <div class="circle-text">
                <span class="number">75%</span>
                <span class="label">JOINERS</span>
                <span class="sub-label">OUT OF 975</span>
            </div>
        </div>
    </div>
</div> */}

      <div className="notMobile" style={{width:"100%"}}>
        <div className="headerBackground">
          {/* <TopHeader /> */}
          <MiddleHeader
            menuOpen={menuOpen}
            setMenuOpen={setMenuOpen}
            isSticky={isSticky}
            setShowSearchModal={setShowSearchModal}
          />
        </div>
      </div>
      {/* ) : null} */}
      <BottomHeader
        isSticky={isSticky}
        menuOpen={menuOpen}
        setMenuOpen={setMenuOpen}
        setShowSearchModal={setShowSearchModal}
        homeCategories={homeCategories}
      />
    </header>
  );
};

export default Header;
